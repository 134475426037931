import React, { useEffect } from "react";
import "./Header.scss";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import CloseIcon from "@material-ui/icons/Close";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import headerLogoImage from "../../assets/images/logo-hub-pagos.svg";
import Box from "@material-ui/core/Box";
import { Link, useHistory, useLocation } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Drawer from "@material-ui/core/Drawer";
import {getFullOnBoardStats} from '../../api/';

type Anchor = "top" | "left" | "bottom" | "right";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // flexGrow: 1,
    },
    menuButton: {
      // marginRight: theme.spacing(2),
    },
    bgColor: {
      backgroundColor: "red",
      boxShadow: "none",
    },
    title: {
      flexGrow: 1,
    },
  })
);

const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [userData, setUserData] = React.useState({ alias: "", avatar_url: "" });
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [foStats,  setFoStats] = React.useState({progress: "", challengeActive: "", availableChallenges: "", totalChallenges: "", finished: "", inProgress: "", pending: ""});
  const [currenPage, setCurrentPage] = React.useState("");
  

  useEffect(() => {
    if (localStorage.getItem("alias") !== null) {
      console.log("alias", localStorage.getItem("alias"));
      const user = {
        alias: String(localStorage.getItem("alias")),
        avatar_url: String(localStorage.getItem("avatar")),
      };
      setUserData(user);
    }

    console.log('path name',location.pathname);

    setCurrentPage(location.pathname);



    const getStats = async () => {
      try {
        const response = await getFullOnBoardStats();
        console.log("response", response);
        console.log("response", response.data["Cupon 1"]);

        let finished = 0;
        let inProgress = 0;

        for (let i = 0; i < response.data.courses.length; i++) {

          if (response.data.courses[i] === "100%") {
            finished++;
          }

          const courseNumber = String(response.data.courses[i]).replace("%", "");

          if (Number(courseNumber) > 0 && Number(courseNumber) < 100) {
            inProgress++;
          }
        }

       
        const resFO = {
          progress: String(response.data.generalProgress).replace("%", ""),
          challengeActive: String(response.data.challenges.completed),
          totalChallenges: String(response.data.challenges.total),
          availableChallenges: String(Number(response.data.challenges.total) - Number(response.data.challenges.completed)),
          finished: String(finished),
          inProgress: String(inProgress),
          pending: String(response.data.courses.length - finished),
        }
        setFoStats(resFO);
        console.log("resFO", resFO);
      } catch (error) {
        console.log("error", error)  
      }
    }


    getStats()



  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("token_masterup");
    localStorage.removeItem("id_masterup");
    localStorage.removeItem("user");
    localStorage.removeItem("avatar");
    localStorage.removeItem("alias");
    // history.push("/login");
    history.push("/");

    setAnchorEl(null);
  };

  const goProfile = () => {
    history.push("/profile");
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const howWorks = () => { 
    history.push("/walkthru");
  }

  return (
    <div className="Header">
      <AppBar position="static" className={classes.bgColor}>
        <Toolbar className="container">
          <Box width={1} display="flex">
            <Box flexGrow={1} display="inline-flex">
              <Box p={0.8}>
                <Link to="/home-page">
                  <img src={headerLogoImage} alt="Hub Pagos" />
                </Link>
              </Box>
              <Box
                width={1}
                ml={4}
                mt={1.4}
                display={{ xs: "none", sm: "none", md: "block", lg: "block" }}
              >
                <Box className="colorNose" pl={4} display="inline">
                  <Link  className={currenPage === "/promotions" ? "menu-item-active" : "menu-item"} to="/promotions">
                    Promociones
                  </Link>
                </Box>
                <Box pl={4} display="inline">
                  <Link className={currenPage === "/faq" ? "menu-item-active" : "menu-item"}  to="/faq">
                    Preguntas frecuentes
                  </Link>
                </Box>
                <Box pl={4} display="inline">
                  <Link className={currenPage === "/full-on-board" ? "menu-item-active" : "menu-item"}   to="/full-on-board">
                    Full Onboard
                  </Link>
                  <ExpandMoreIcon
                    style={{ cursor: "pointer" }}
                    onClick={toggleDrawer("top", true)}
                  />
                </Box>
                <Box pl={4} display="inline">
                  <Link className={currenPage === "/calculator" ? "menu-item-active" : "menu-item"}  to="/calculator">
                    Calculadora
                  </Link>
                </Box>
              </Box>
            </Box>
            <Box>
              {localStorage.getItem("alias") === null ? 
              <div>
                {String(userData.alias).substring(0, 10)}

                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </div>
              :
              <div>
                <div className="row">
                  <div className="col-6  d-flex align-items-center justify-content-end" style={{padding: 0}}>
                    <div className="user-text">{String(userData.alias).substring(0, 20)}</div>
                  </div>
                  <div className="col-6 ">
                    <div
                      className="dot-avatar"
                      style={{
                        backgroundImage: `url(${userData.avatar_url})`,
                        backgroundSize: "auto 100%",
                        backgroundPosition: "center",
                      }}
                      onClick={handleMenu}
                    ></div>
                  </div>
                </div>
              </div> }

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={goProfile}>Mi perfil</MenuItem>
                <MenuItem onClick={logOut}>Cerrar sesión</MenuItem>
                <MenuItem onClick={howWorks}>¿Cómo funciona?</MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor={"top"}
        open={state["top"]}
        onClose={toggleDrawer("top", false)}
      >
        {/* {list('top')} */}
        <div className="drawer-container">
          <div className="container  p-4" style={{ height: "100%" }}>
            <div className="full-onboard">
              <div className="row p-3">
                <div className="col-8  ">
                  <span className="title-fullOnboar">Full Onboard </span>
                  <p className="des-fullOnboard">
                    ¡Sigue desarrollando tu conocimiento! Te invitamos a que
                    continúes con tu entrenamiento a través de los materiales de
                    e-learning. ¡Estás a un solo click!
                  </p>
                </div>
                <div className="col-4 d-flex justify-content-between p-2 pt-3">
                  <button
                    className="btn btn-primary col-md-6 col-12 btn-goFull"
                    onClick={() => { history.push("/full-on-board") }}
                  >
                    Ir a Full OnBoard
                  </button>
                  <CloseIcon onClick={toggleDrawer("top", false)} style={{ cursor: "pointer" }} />
                </div>
              </div>
              <div className="p-3">
                <div className="row">
                  <div className="col-4 full-item-one d-flex justify-content-center ">
                    <div className="p-1">
                      <span className="title-section-fo"> Mis retos</span>
                      <div className="text-item-one mt-2"> Tienes {foStats.availableChallenges} retos disponbiles para inscribirte</div>
                      <div className="stat-container pt-2">
                        <span className= "big-stat">
                          {foStats.challengeActive}
                        </span>
                        <span className= "regular-stat">
                         /{foStats.totalChallenges}
                        </span>
                        <span className= "state-stat ">
                            activo
                        </span> 
                      </div>
                    </div>
                  </div>
                  <div className="col-4 full-item-two d-flex justify-content-center">
                    <div className="p-1"> 
                      <span className="title-section-fo">Mi avance general </span>
                      <div className="stat-container" style={{marginTop: "2.9em"}}>
                        <span className= "big-stat">
                          %{foStats.progress}
                        </span>
                        <span className= "regular-stat" style={{marginLeft: '0.4em'}}>
                         /100
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 full-item-three d-flex justify-content-center">
                    <div className="p-1 col-9">
                      <span className="title-section-fo"> Mis certificaciones </span>
                        <div className="col-12 pt-2 item-3 d-flex justify-content-between">
                          <span>En progreso </span>
                          <span>{foStats.inProgress}</span>
                       </div>
                       <div className="col-12 pt-2 item-3 d-flex justify-content-between">
                          <span>Finalizados </span>
                          <span>{foStats.finished}</span>
                       </div> 
                       <div className="col-12 pt-2 item-3 d-flex justify-content-between">
                          <span>Pendientes </span>
                          <span>{foStats.pending}</span>
                       </div> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Header;
