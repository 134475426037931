import React from 'react';
import './Footer.scss';
import packageJson from '../../../package.json';


const Footer = () => (
  <div className="Footer" data-testid="Footer">
    <div className="container-fluid footer-container d-flex align-items-center">
      2021 ©Santander | v{packageJson.version}
    </div>
  </div>
);

export default Footer;
