import React, { useEffect } from "react";
import "./Faq.scss";
import Header from "../Header/Header";
import { useHistory } from "react-router-dom";
import {
  Theme,
  createStyles,
  makeStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import imageRow from "../../assets/images/row-result.svg";

import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { getFaqCategories, getFaqs, searchFAQ, setLikes, setDislikes } from "../../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import SubHeader from "../SubHeader/SubHeader";
import tarjetin from "../../assets/images/tarjetin-no-result.png";
import NeedHelp from "../NeedHelp/NeedHelp";
import Footer from "../Footer/Footer";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      // fontWeight: theme.typography.fontWeightRegular,
    },
    bgColor: {
      // backgroundColor: "#F7FAFC",
      // boxShadow: "none",
      // '& label.Mui-focused': {
      //   color: 'red',
      // },
      // '& .MuiInput-underline:after': {
      //   borderBottomColor: 'red',
      // },
      width: "70%",
    },
  })
);

interface State {
  search: string;
}

const Faq = () => {
  const classes = useStyles();
  const [categories, setCategories] = React.useState([]);
  const [faqs, setFaqs] = React.useState([]);
  const [title, setTitle] = React.useState("");
  const [values, setValues] = React.useState<State>({
    search: "",
  });
  const [showSearch, setShowSearch] = React.useState(false);
  const [searchResult, setSerchResult] = React.useState([]);
  const answerData = { id: 0, externalAssets: [], likes: 0, dislikes: 0, like: 0, dislike: 0 };
  const [answer, setAnswer] = React.useState({
    show: false,
    answer: "",
    question: "",
    data: answerData,
  });
  const [showLoading, setShowLoading] = React.useState(false);
  const [showLoadingSearch, setShowLoadingSearch] = React.useState(false);
  const [showArrowDow, setShowArrowDown] = React.useState(false);
  const history = useHistory();

  useEffect(() => {
    const getCategories = async () => {
      try {
        setShowLoading(true);
        const categories = await getFaqCategories();

        categories.data.forEach((item: any) => {
          item.active = false;
        });
        categories.data[0].active = true;
        setTitle(categories.data[0].category);
        setShowLoading(false);
        try {
          const getIniFaq = await getFaqs(categories.data[0].id);
          // console.log(getIniFaq.data);
          setFaqs(getIniFaq.data);
        } catch (error) {
          console.log(error);
        }

        // console.log(categories.data);
        setCategories(categories.data);
      } catch (error) {
        console.log(error);
        setShowLoading(false);
      }
    };

    getCategories();
  }, []);

  const showCategory = async (id: any) => {
    // console.log(id);
    const temp = categories.slice();

    temp.forEach((category: any) => {
      category.active = false;
      if (category.id === id) {
        category.active = true;
        setTitle(category.category);
      }
    });
    setCategories(temp);
    window.scrollTo(0, 0);

    try {
      setShowLoading(true);
      const newFaqs = await getFaqs(id);
      // console.log(newFaqs.data);
      setShowLoading(false);
      setFaqs(newFaqs.data);
    } catch (error) {
      console.log(error);
      setShowLoading(false);
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#407FA0",
      },
    },
  });

  const handleClickSearch = async () => {
    console.log("here");
    // setValues({ ...values, showPassword: !values.showPassword });
    console.log("value", values);
    setSerchResult([]);
    setShowLoadingSearch(true);
    setShowSearch(true);
    try {
      const result = await searchFAQ(values.search);
      // console.log('result data',result.data);
      let tempData = result.data.slice();
      tempData.forEach((element: any) => {
        element.question = replaceAll(
          element.question,
          values.search,
          "<b><big>" + values.search + "</big></b>"
        );
        element.answer = replaceAll(
          element.answer,
          values.search,
          "<b><big>" + values.search + "</big></b>"
        );
        element.question = replaceAll(
          element.question,
          capitalizeFirstLetter(values.search),
          "<b><big>" + capitalizeFirstLetter(values.search) + "</big></b>"
        );
        element.answer = replaceAll(
          element.answer,
          capitalizeFirstLetter(values.search),
          "<b><big>" + capitalizeFirstLetter(values.search) + "</big></b>"
        );
        element.question = replaceAll(
          element.question,
          values.search.toUpperCase(),
          "<b>" + values.search.toUpperCase() + "</b>"
        );
        element.answer = replaceAll(
          element.answer,
          values.search.toUpperCase(),
          "<b>" + values.search.toUpperCase() + "</b>"
        );
      });
      setSerchResult(tempData);

      if (result.data.length >= 5) {
        setShowArrowDown(true);
      } else {
        setShowArrowDown(false);
      }
      // setSerchResult(result.data);
      setShowLoadingSearch(false);
    } catch (error) {
      console.log(error);
      setShowLoadingSearch(false);
      setSerchResult([]);
    }

    // if (showSearch) {
    //   setShowSearch(false);
    // } else {
    //   setShowSearch(true);
    // }
  };

  function replaceAll(str: string, search: string, replace: string) {
    return str.split(search).join(replace);
  }

  function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  // var timeout  = setTimeout(function(){}, 0);

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
      console.log("search value", event.target.value);
      //hideResults();
      if (answer.show) {
        setAnswer({ show: false, answer: "", question: "", data: answerData });
      }
      // clearTimeout(timeout);
      // console.log ('escribiendo')
      // let test = false;
      // timeout = setTimeout(() => {
      //   // handleClickSearch(event.target.value.trim())
      //   if (!test) {
      //     console.log('no escribo' , event.target.value)
      //     test = true;
      //   }
      //   clearTimeout(timeout);
      //  }, 1000);
      event.preventDefault();
    };

  const hideResults = () => {
    if (answer.show) {
      setAnswer({ show: false, answer: "", question: "", data: answerData });

      if (searchResult.length >= 5) {
        setShowArrowDown(true);
      } else {
        setShowArrowDown(false);
      }
    } else {
      setShowSearch(false);
      setShowArrowDown(false);
    }
  };

  useEffect(() => {
    if (values.search !== "") {
      setSerchResult([]);
      setShowLoadingSearch(true);
      setShowSearch(true);
    }

    const delayDebounceFn = setTimeout(async () => {
      console.log("send axios", values.search);
      // Send Axios request here
      // handleClickSearch(values.search)

      try {
        const result = await searchFAQ(values.search);
        // let tempData = result.data.slice(0);
        // tempData.forEach( (element:any) => {
        //   element.question = replaceAll(element.question, values.search, "<b>"+values.search+"</b>");
        //   element.answer = replaceAll(element.answer, values.search, "<b>"+values.search+"</b>");
        //   element.question = replaceAll(element.question, capitalizeFirstLetter(values.search), "<b>"+capitalizeFirstLetter(values.search)+"</b>");
        //   element.answer = replaceAll(element.answer, capitalizeFirstLetter(values.search), "<b>"+capitalizeFirstLetter(values.search)+"</b>");
        //   element.question = replaceAll(element.question, values.search.toUpperCase(), "<b>"+values.search.toUpperCase()+"</b>");
        //   element.answer = replaceAll(element.answer, values.search.toUpperCase(), "<b>"+values.search.toUpperCase()+"</b>");
        // });
        // setSerchResult(tempData);

        setSerchResult(result.data);
        setShowLoadingSearch(false);
        if (result.data.length >= 5) {
          setShowArrowDown(true);
        } else {
          setShowArrowDown(false);
        }
      } catch (error) {
        setSerchResult([]);
        setShowLoadingSearch(false);
        setShowSearch(false);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [values.search]);

  const showSearchAnswer = (answer: any) => {
    setShowArrowDown(false);
    setAnswer({
      show: true,
      answer: answer.answer,
      question: answer.question,
      data: answer,
    });
    console.log(answer);
  };

  const onScrolled = () => {
    setShowArrowDown(false);
  };

  const setLike = async(id:any) => {
    try {

      const result = await setLikes(Number(id));
      console.log("result", result, id);
      if (result.status === 200) {
        const tempFaqs:any = [];
        faqs.forEach( (element:any) => {
          if (element.id === id) {
            console.log("result", result);
            // element.likes = element.likes + 1;
            element.likes = result.data.likes.length;
            element.dislikes = result.data.dislikes.length;
            element.like = true;
            element.dislike = false;

            setAnswer({
              show: true,
              answer: element.answer,
              question: element.question,
              data: {...element},
            });

          }
          tempFaqs.push(element);
        });
        setFaqs(tempFaqs);
       }
    } catch (error) {
      console.log(error);
    }
  }

  const setDislike = async(id:any) => {
    try {
      const result = await setDislikes(Number(id));
      if (result.status === 200) {
        console.log("result", result);
        const tempFaqs:any = []
        faqs.forEach( (element:any) => {
          if (element.id === id) {
            // element.dislikes = element.dislikes + 1;
            element.likes = result.data.likes.length;
            element.dislikes = result.data.dislikes.length;
            element.like = false;
            element.dislike = true;

            setAnswer({
              show: true,
              answer: element.answer,
              question: element.question,
              data: {...element},
            });
          }
          tempFaqs.push(element);
        });
        setFaqs(tempFaqs);
       }
    } catch (error) {
      console.log(error);
    }
  }

  const setFOPage = (item:object) => {
    history.push('/full-on-board?page='+item);
  }

  return (
    <div className="Faq" data-testid="Faq">
      <div className="static-container container-fluid">
        <Header />
        <SubHeader path="/faq" setFOMenu={setFOPage} />
      </div>
      <div className="container-fluid  main-container">
        <div className="container " style={{ height: "auto" }}>
          {" "}
          {/* here change to auto*/}
          <div className="row ">
          <div
              className=" col-md-3 col-12  no-fixed "
              style={{ zIndex: 2 }}
            > <div className=" pb-2" style={{/*backgroundColor: "#FFF",*/ borderRadius: '5px', width: '90%'}} >
              <div className="col-12">
                <div className="red-line" />
              </div>
              <h1 className="pt-4" style={{paddingLeft: '5px'}}>Preguntas frecuentes</h1>
              <p
                className="sub-title-section col-12 col-md-12"
                style={
                  {
                    paddingLeft: '5px'
                    /*height: '900px'*/
                  }
                }
              >
                Respuestas a las consultas más frecuentes de nuestros clientes
              </p>
              <form autoComplete="off">
                <div className="" style={{ position: "relative", paddingLeft: '5px' }}>
                  {/* <input
                    type="text"
                    className="col-md-10 col-10 search-faq"
                    id="searchFaq"
                    aria-describedby="emailHelp"
                    placeholder="Buscar"
                  /> */}
                  <ThemeProvider theme={theme}>
                    <TextField
                      id="standard-adornment-password"
                      type="text"
                      label="Buscar"
                      onKeyPress={(ev) => {
                        console.log(`Pressed keyCode ${ev.key}`);
                        if (ev.key === "Enter") {
                          // Do code here
                          ev.preventDefault();
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {" "}
                            <SearchIcon
                              style={{ cursor: "pointer" }}
                              onClick={handleClickSearch}
                            />
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleChange("search")}
                      className={classes.bgColor}
                    ></TextField>
                  </ThemeProvider>
                  {showSearch && (
                    <div
                      onScroll={onScrolled}
                      className={
                        answer.show
                          ? "search-results-detail  "
                          : "search-results "
                      }
                    >
                      <div className="col-12 item-close p-2">
                        <div className="row">
                          <div className="col-10 pt-2 ">
                            <p className="text-suggetions ">
                              {!answer.show
                                ? "Propuestas sugeridas"
                                : "Resultado de búsqueda"}
                            </p>
                          </div>
                          <div className="col-2 d-flex justify-content-end  ">
                            <CloseIcon
                              style={{ cursor: "pointer" }}
                              onClick={hideResults}
                            />
                          </div>
                        </div>
                      </div>

                      {answer.show ? (
                        <div className="col-12  p-1">
                          <ul className="custom-ul">
                            <li className="search-item ">
                              <span>
                                <img
                                  onClick={hideResults}
                                  style={{ transform: "rotate(90deg)" }}
                                  src={imageRow}
                                  alt="row"
                                />{" "}
                                &nbsp;
                              </span>
                              <span
                                className="text-result"
                                dangerouslySetInnerHTML={{
                                  __html: String(answer.question),
                                }}
                              >
                                {/* {answer.question} */}
                              </span>
                            </li>
                          </ul>
                          <div
                            className="pt-3"
                            style={{ height: "200px", overflowY: "scroll" }}
                          >
                            <div style={{ height: "10px" }}>
                              {/* {answer.answer} */}
                              <div
                                className="answer-search p-2"
                                dangerouslySetInnerHTML={{
                                  __html: String(answer.answer),
                                }}
                              ></div>

                              <div className="external-assets p-2">
                                {answer.data.externalAssets.length >= 1 && (
                                  <span className="external-assets-title">
                                    Recursos externos:
                                  </span>
                                )}
                                <div
                                  className="row"
                                  style={{ marginLeft: "1px" }}
                                >
                                  {answer.data?.externalAssets.map(
                                    (asset: any) => {
                                      return (
                                        <span className="external-item">
                                          <a href={asset.url} target="_blank">
                                            {asset.label}
                                          </a>
                                        </span>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                              <div className="p-2">
                                <span className="likes">
                                  ¿Te ha sido útil esta respuesta?{" "}
                                  <span
                                    className={
                                      answer.data.like ? "active-like" : ""
                                    }
                                    onClick={() => setLike(answer.data.id)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    &#128512; ({answer.data.likes})
                                  </span>{" "}
                                  <span
                                    className={
                                      answer.data.dislike ? "active-like" : ""
                                    }
                                    onClick={() => setDislike(answer.data.id)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    &#128577; ({answer.data.dislikes})
                                  </span>
                                  {/* <span>&#128512; ({answer.data.likes})</span>{" "}
                                  <span>
                                    &#128577; ({answer.data.dislikes})
                                  </span> */}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-12  p-2">
                          <ul className="custom-ul">
                            {showLoadingSearch && (
                              // <small style={{ color: "red" }}>
                              //   loading...
                              // </small>
                              <div className="col-12 d-flex justify-content-center">
                                <CircularProgress color="secondary" size={20} />
                              </div>
                            )}

                            {searchResult.length === 0 && !showLoadingSearch && (
                              <div className="col-12 p-1">
                                <div className="row">
                                  <div
                                    className="col-md-4 col-6 "
                                    style={{ padding: "0px" }}
                                  >
                                    <img
                                      src={tarjetin}
                                      className="col-12 img"
                                      alt="tarjetin"
                                    />
                                  </div>
                                  <div className="col-md-8 col-6 p-1">
                                    <div className="head-no-result pt-2">
                                      {" "}
                                      Lo sentimos,{" "}
                                    </div>
                                    <div
                                      className="text-no-result pt-2 pb-2"
                                      style={{
                                        borderBottom: "solid 1px #E4E4E4",
                                      }}
                                    >
                                      No se encontraron resultados que contengan
                                      todos los términos de tu búsqueda.
                                    </div>
                                    <div className="text-no-result pt-1">
                                      <div className="pb-2">
                                        <b>Sugerencias:</b>
                                      </div>
                                      Asegúrate de que todas las palabras estén
                                      escritas correctamente. Prueba con
                                      diferentes palabras clave o palabras más
                                      generales.
                                    </div>
                                  </div>
                                </div>
                                {/* <small style={{ color: "red" }}>
                                  Lo sentimos &#128533;
                                </small>{" "}
                                <br />
                                <small>
                                  No se encontraron resultados que contengan
                                  todos los términos de tu búsqueda.
                                </small>{" "}
                                <br /> <br />
                                <div>
                                  <small>
                                    <b>Sugerencias:</b> <br />
                                    Asegúrate de que todas las palabras estén
                                    escritas correctamente.
                                    <br />
                                    Prueba con diferentes palabras clave o
                                    palabras más generales.
                                  </small>
                                </div> */}
                              </div>
                            )}
                            {searchResult.map((result: any) => {
                              return (
                                <li
                                  onClick={() => showSearchAnswer(result)}
                                  key={result.id}
                                  className="search-item "
                                >
                                  <span>
                                    <img src={imageRow} alt="row" /> &nbsp;
                                  </span>
                                  <span
                                    className="text-result"
                                    dangerouslySetInnerHTML={{
                                      __html: String(result.question),
                                    }}
                                  >
                                    {/* ¿Para qué sirve la versión digital de mi
                                  Tarjeta de Crédito?{" "} */}
                                    {/* {result.question} */}
                                  </span>
                                </li>
                              );
                            })}
                            {/* <li className="search-item ">
                              <span>
                                <img src={imageRow} alt="row" /> &nbsp;
                              </span>
                              <span className="text-result">
                                ¿Para qué sirve la versión digital de mi Tarjeta
                                de Crédito?{" "}
                              </span>
                            </li> */}
                            {/* <li className="search-item ">
                              <span>
                                <img src={imageRow} alt="row" /> &nbsp;
                              </span>
                              <span className="text-result">
                                ¿Para qué sirve la versión digital de mi Tarjeta
                                de Crédito?{" "}
                              </span>
                            </li>
                            <li className="search-item ">
                              <span>
                                <img src={imageRow} alt="row" /> &nbsp;
                              </span>
                              <span className="text-result">
                                ¿Para qué sirve la versión digital de mi Tarjeta
                                de Crédito?{" "}
                              </span>
                            </li> */}
                          </ul>
                        </div>
                      )}
                      {showArrowDow && !showLoadingSearch && (
                        <div className="col-12 d-flex justify-content-center arrow">
                          <div className="dot-arrow"> </div>
                          <span style={{ color: "#F00F", zIndex: 3 }}>
                            &#711;
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </form>
              <div className="mt-2 pt-2 col-md-8 col-10 pb-2 ">
                <ul className="custom-ul">
                  {categories?.map((category: any, index: number) => {
                    return (
                      <li
                        onClick={() => showCategory(category.id)}
                        key={category.id}
                        className="col-12 category-item"
                      >
                        {" "}
                        {category.active && (
                          <span className="red-line-v"></span>
                        )}{" "}
                        <span
                          className={category.active ? "menu-text" : ""}
                          style={{ paddingLeft: "0.5em" }}
                        >
                          {category.category}
                        </span>
                      </li>
                    );
                  })}
                  {/* <li className="col-12 category-item">
                    <span className="red-line-v"></span>{" "}
                    <span className="menu-text">Tarjetas de crédito </span>
                  </li> */}
                  {/* <li className="col-12 category-item">Tarjetas de crédito</li>
                  <li className="col-12 category-item">Tarjetas de débito</li>
                  <li className="col-12 category-item">Puntos recompensas</li>
                  <li className="col-12 category-item">Meses sin intereses</li>
                  <li className="col-12 category-item">Promociones</li>
                  <li className="col-12 category-item">Cobro sin concacto</li> */}
                </ul>
              </div>
              </div>
            </div>
            <div className="col-12  ">
              <div className="row">
                <div className=" col-md-4 ">{/*Hola*/}</div>
                <div className=" col-md-8  p-4">
                  {/* Aver */}
                  {showLoading ? (
                    <div className="col-12 d-flex justify-content-center">
                      <CircularProgress color="secondary" size={30} />
                    </div>
                  ) : (
                    <div className=" col-12">
                      <p className="title-category col-12 pt-1">
                        {/* Tarjetas de crédito */}
                        {title}
                      </p>

                      <div className={classes.root}>
                        {faqs?.map((faq: any) => {
                          return (
                            // <div key={faq.id}>
                            <Accordion key={faq.id}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography className={classes.heading}>
                                  {/* ¿Lorem ipsum dolor sit amet? */}
                                  <span
                                    className="title-question"
                                    dangerouslySetInnerHTML={{
                                      __html: String(faq.question),
                                    }}
                                  >
                                    {/* {faq.question} */}
                                  </span>
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography className="answer-question">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: String(faq.answer),
                                    }}
                                  ></div>

                                  {/* Lorem ipsum dolor sit amet, consectetur adipiscing
                                  elit. Suspendisse malesuada lacus ex, sit amet
                                  blandit leo lobortis eget. */}
                                  <br />
                                  <div className="external-assets">
                                    {faq.externalAssets.length >= 1 && (
                                      <span className="external-assets-title">
                                        Recursos externos:
                                      </span>
                                    )}
                                    <div className="row">
                                      {faq?.externalAssets.map((asset: any) => {
                                        return (
                                          <span className="external-item">
                                            <a href={asset.url} target="_blank">
                                              {asset.label}
                                            </a>
                                          </span>
                                        );
                                      })}
                                    </div>
                                  </div>
                                  <div className="pt-2">
                                    <span className="likes">
                                      ¿Te ha sido útil esta respuesta?{" "}
                                      <span className={faq.like ? "active-like" : ""} onClick={()=>setLike(faq.id)} style={{cursor: 'pointer'}}>&#128512; ({faq.likes})</span>{" "}
                                      <span className={faq.dislike ? "active-like" : ""} onClick={()=>setDislike(faq.id)} style={{cursor: 'pointer'}}>&#128577; ({faq.dislikes})</span>
                                    </span>
                                  </div>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                            // </div>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {/* A ver 2 */}
                  {/* 
                  <div className=" col-12">
                    <p className="title-category col-12">

                      Tarjetas de débito
                    </p>

                    <div className={classes.root}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading}>
                            ¿Lorem ipsum dolor sit amet?
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse malesuada lacus ex, sit amet
                            blandit leo lobortis eget.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography className={classes.heading}>
                            ¿Lorem ipsum dolor sit amet?
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse malesuada lacus ex, sit amet
                            blandit leo lobortis eget.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <Typography className={classes.heading}>
                            ¿Lorem ipsum dolor sit amet?
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse malesuada lacus ex, sit amet
                            blandit leo lobortis eget.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <Typography className={classes.heading}>
                            ¿Lorem ipsum dolor sit amet?
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse malesuada lacus ex, sit amet
                            blandit leo lobortis eget.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <Typography className={classes.heading}>
                            ¿Lorem ipsum dolor sit amet dolor sit amet?
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse malesuada lacus ex, sit amet
                            blandit leo lobortis eget.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{marginTop: "100px"}}>
          <NeedHelp />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Faq;
