
import React, { useEffect } from "react";
import "./ResetPassword.scss";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import CloseIcon from "@material-ui/icons/Close";
import { useForm } from "react-hook-form";
import { resetPassword } from "../../api/";

import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CircularProgress from "@material-ui/core/CircularProgress";

import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import { useLocation, useHistory } from "react-router-dom";

type Severity = 'error' | 'success' | 'info' | 'warning' | undefined
interface AlertData {
  type: Severity
  label: string
  show: boolean
}

const ResetPassword = () => {

  

  const history = useHistory();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });
  const goWelcome = () => {
    history.push("/");
  };

  const [openAlert, setOpenAlert] = React.useState<AlertData>({
    type: undefined,
    show: false,
    label: "",
  });

  const [showLoading, setShowLoading] = React.useState(false);
  const [showPass, setShowPass] = React.useState("password");
  const [showConfirm, setShowConfirm] = React.useState("password");
  const [userData, setUserData] = React.useState({userId: 0, token: ''});
  var qs = require('qs')
  const location = useLocation()

  useEffect(() => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
    console.log('queeryParmas',queryParams);
    if (queryParams.token ) {
        setUserData(queryParams);
        // let userData = parseJwt(queryParams.token)
        // console.log('user data', userData)

        // localStorage.setItem('token', queryParams.token);
        // localStorage.setItem('token_masterup', queryParams.mutoken);
        // localStorage.setItem('id_masterup', queryParams.muid);
        // localStorage.setItem('user', userData.email);
    } else {
        // const tempUserData = parseJwt(localStorage.getItem("token"));
        // const temp = {
        //   userId: tempUserData.id,
        //   token: localStorage.getItem("token") || "",
        // }
        // setUserData(temp)
        // console.log('temp' ,temp);
    }
  }, []);

  const onSubmit = async (data:any /*{
    newPassword: string;
    pswRepeat: string;
  }*/) => {
    console.log(data.newPassword, data.pswRepeat);
    setShowLoading(true);

    try {

      // const tempUserData = parseJwt(localStorage.getItem("token"));
      // console.log('temp' ,tempUserData);

      const reqReset = {
        userId: Number(userData.userId),
        token: userData.token, 
        password: data.pswRepeat,
      };
      console.log('req',reqReset);
      
      const setResetPassword = await resetPassword(reqReset);
      if (setResetPassword.status === 201) {
        setShowLoading(false);
        setOpenAlert({ type: 'success', show: true, label: setResetPassword.data });
        setShowLoading(false);
      }
      console.log('result', setResetPassword);

    } catch (error: any) {
      console.log(error.response.data.message);
      setOpenAlert({ type: 'error', show: true, label: error.response.data.message });
      setShowLoading(false);
    }
  };
  const showPassword = () => {
    if (showPass === "password") {
      setShowPass("text");
    } else {
      setShowPass("password");
    }
  };

  const setConfirm = () => {
    if (showConfirm === "password") {
      setShowConfirm("text");
    } else {
      setShowConfirm("password");
    }
  };

  function parseJwt(token: any) {
    const base64Url = token.split('.')[1]
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )

    return JSON.parse(jsonPayload)
  }

  return (
    <div className="ResetPassword" data-testid="ResetPassword">
      {/* ResetPassword Component */}
      <div className="static-container container-fluid">
        <Header />
        <div className="container-fluid sub-menu"></div>
      </div>

      <div className="container-fluid main-container   ">
        <div className="container sub-container  d-flex align-items-stretch ">
          <div className=" col-12 d-flex justify-content-center  ">
            <div className={openAlert.show ? "login-container-alert" : "login-container"}>
              <div className="col-12 d-flex d-flex justify-content-end pb-2 px-3 pt-3">
                <CloseIcon style={{ cursor: "pointer" }} onClick={goWelcome} />
              </div>
              <div
                className="col-12 d-flex justify-content-center align-items-center p-3"
                style={{ marginTop: "-10px" }}
              >
                <div className=" col-12 col-md-9 text-left">
                  <h1 className="h1-reset-psw">Modificar Contraseña </h1>
                  <div className="sub-text">
                    Recuerda que tu constraseña debe contener: mínimo 8 caracteres, al menos una mayúscula y un caracter especial
                  </div>
                  <div className="form-container pt-4">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <div className="row pb-2">
                          <label className="col-6 col-md-7 label-form">
                            Nueva contraseña
                          </label>
                          {/* <label className="col-6 col-md-5 label-alt d-flex align-items-end">
                            ¿Cómo identifico mi número de empleado?
                          </label> */}
                        </div>
                        <input
                          {...register("newPassword", {
                            required: true,
                            pattern: {
                              value:
                                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/, message: "wrong psw",
                            },
                          })}
                          type={showPass}
                          className="form-control input"
                          id="newPassword"
                          placeholder="Ingresar nueva contraseña"
                        />
                        <div className="col-1" style={{ position:'relative', float: 'right',  marginTop: '-30px', paddingRight: '5px'}} >
                          { showPass === "password" ? <VisibilityOutlinedIcon onClick={showPassword} /> : <VisibilityOffOutlinedIcon onClick={showPassword} />}
                        </div>
                        {String(errors.newPassword?.type) === "pattern" && (
                          <span className="input-error">
                            Contraseña no válida
                          </span>
                        )}
                      </div>
                      <div className="form-group pt-4">
                        <div className="row pb-2">
                          <label className="col-6  col-md-8 label-form">
                            Repetir contraseña 
                          </label>
                          {/* <label className="col-8 col-md-6 label-alt pt-2">
                            ¿Olvidaste tu contraseña?
                          </label> */}
                        </div>
                        <input
                          {...register("pswRepeat", {
                            required: true,
                            pattern: {
                              value:
                              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                              message: "pattern message",
                            },
                            validate: {
                              sameText: (value) =>
                                value === getValues().newPassword || "error",
                            },
                          })}
                          type={showConfirm}
                          className="form-control input"
                          id="pswRepeat"
                          placeholder="Repetir contraseña"
                        />
                        <div className="col-1" style={{ position:'relative', float: 'right',  marginTop: '-30px', paddingRight: '5px'}} >
                          { showConfirm === "password" ? <VisibilityOutlinedIcon onClick={setConfirm} /> : <VisibilityOffOutlinedIcon onClick={setConfirm} />}
                        </div>
                        <div className="pt-1" style={{margin: 0}}>
                          <div >
                            {String(errors.pswRepeat?.type) === "pattern" && (
                              <span className="input-error">
                                Contraseña no válida
                              </span>
                            )}
                          </div>

                          {/* {errors.emailRepeat.type === "validate" && <p>Have to be the same</p>} */}
                          <div style={{marginTop: '-6px'}}>
                            {errors.pswRepeat && (
                              <span className="input-error">
                                Las contraseñas deben de ser coincidir
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt-4 pt-1">
                        <button
                          className="btn btn-primary col-md-6 col-10 mt-2"
                          disabled={!isValid}
                          type="submit"
                          style={{ height: "3.5em", fontSize: "14px" }}
                        >
                          Enviar
                        </button>
                      </div>
                      {showLoading && (
                        <div className="pt-3 col-12 d-flex justify-content-center">
                          <CircularProgress color="secondary" size={20} />
                        </div>
                      )}
                      <div className="pt-3">
                        <Collapse in={openAlert.show}>
                          <Alert
                            severity={openAlert.type}
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setOpenAlert({type: openAlert.type,  show: false, label: openAlert.label });
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {openAlert.label}
                          </Alert>
                        </Collapse>
                      </div>
                      <div className="text-center mt-4">
                        <span className="text-tyc mt-2">
                          Revisa <u>Terminos y Condiciones.</u>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <Footer />
      </div>
    </div>
  );
};

export default ResetPassword;
