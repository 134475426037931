import React from 'react';
import './NotFound.scss';
import HeaderOut from '../HeaderOut/HeaderOut';
import Footer from '../Footer/Footer';
import Image404 from '../../assets/images/error404.png';

const NotFound = () => { 
  
  return (
  <div className="NotFound" data-testid="NotFound">
    {/* NotFound Component */}

    <div className="static-container container-fluid">
        <HeaderOut />
        <div className="container-fluid sub-menu"></div>
      </div>

      <div className="container-fluid main-container">
        <div className="container sub-container   ">
          <div className="col-md-12 col-12 col-12 d-flex justify-content-center align-items-center" style={{height: '650px'}}>
            <img  style={{width: '70%', height: 'auto' }}  src={Image404} alt="404" />
          </div>
        </div>
        <Footer />
      </div>

  </div>
  )
};

export default NotFound;
