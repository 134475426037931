

import React, { FunctionComponent } from 'react'
import { BrowserRouter as Router, Route, Switch , RouteProps  } from 'react-router-dom'


// import App from './App'
import MainPage from './components/MainPage/MainPage';
import Faq from './components/Faq/Faq';
import Promotions from './components/Promotions/Promotions';
import FullOnBoard from './components/FullOnBoard/FullOnBoard';
import Welcome from './components/Welcome/Welcome';
import Login from './components/Login/Login';
import SingUp from './components/SingUp/SingUp';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import PromoDetail from './components/PromoDetail/PromoDetail';
import PromoDetailPublic from './components/PromoDetailPublic/PromoDetail';
import EmailError  from './components/EmailError/EmailError'
import PasswordRecovery from './components/PasswordRecovery/PasswordRecovery';
import ResetPassword from './components/ResetPassword/ResetPassword';
import Profile from './components/Profile/Profile'; 
import ChangePassword from './components/ChangePassword/ChangePassword';
import Walkthrough from './components/Walkthrough/Walkthrough';
import NotFound from './components/NotFound/NotFound';
import Calculator from './components/Calculator/Calculator';
import PromotionsPublic from './components/PromotionsPublic/PromotionsPublic';
import TermsConditions from './components/TermsConditions/TermsConditions';


export type ProtectedRouteProps = {
  isAuthenticated: boolean;
  authenticationPath: string;
} & RouteProps;

const AppRouter: FunctionComponent = () => {
  let authenticated = false;//localStorage.getItem('token');
  //console.log('token', localStorage.getItem('token'));
  if (localStorage.getItem('token') !== null) {
    authenticated =true;
  }
  const defaultProtectedRouteProps: ProtectedRouteProps = {
    isAuthenticated: authenticated,
    authenticationPath: '/login',
  };

  return (
    <Router>
      <Switch>
      <Route exact path="/" component={Welcome} />
        {/* <Route exact path="/home-page" component={MainPage} /> */}
        <ProtectedRoute exact path="/home-page" {...defaultProtectedRouteProps} component={MainPage} />
        <ProtectedRoute exact path="/faq" {...defaultProtectedRouteProps} component={Faq} />
        <ProtectedRoute exact path="/promotions" {...defaultProtectedRouteProps} component={Promotions} />
        <ProtectedRoute exact path="/full-on-board" {...defaultProtectedRouteProps} component={FullOnBoard} />
        <ProtectedRoute exact path="/promotions-details" {...defaultProtectedRouteProps} component={PromoDetail} />
        <ProtectedRoute exact path="/change-password" {...defaultProtectedRouteProps} component={ChangePassword} />
        <ProtectedRoute exact path="/profile" {...defaultProtectedRouteProps} component={Profile} />
        <ProtectedRoute exact path="/walkthrough" {...defaultProtectedRouteProps} component={Walkthrough} />
        <ProtectedRoute exact path="/walkthru" {...defaultProtectedRouteProps} component={Walkthrough} />
        <ProtectedRoute exact path="/calculator" {...defaultProtectedRouteProps} component={Calculator} />
        <Route exact path="/public-promotions" component={PromotionsPublic} />
        <Route exact path="/public-promotions-details"  component={PromoDetailPublic} />
        <Route exact path="/recover-password" component={ResetPassword} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/singup" component={SingUp} />
        <Route exact path="/validation-error" component={EmailError} />
        <Route exact path="/password-recovery" component={PasswordRecovery} />
        <Route exact path="/terms-conditions" component={TermsConditions} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  )
}

 

export default AppRouter
