import React, {useEffect} from "react";
import "./Walkthrough.scss";
import HeaderOut from "../HeaderOut/HeaderOut";
import Footer from "../Footer/Footer";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useHistory, useLocation } from "react-router";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import imageMast1 from "../../assets/images/walkthru1.png";
import imageMast2 from "../../assets/images/walkthru2.png";
import imageMast3 from "../../assets/images/walkthru3.png";
import imageMast4 from "../../assets/images/walkthru4.png";
import imageMast5 from "../../assets/images/walkthru5.png";
import imageMast6 from "../../assets/images/walkthru6.png";
import imageMast7 from "../../assets/images/walkthru7.png";
import imageMast8 from "../../assets/images/walkthru8.png";
import imageMast9 from "../../assets/images/walkthru9.png";
import imageMast10 from "../../assets/images/walkthru10.png";
import imageMast11 from "../../assets/images/walkthru11.png";
import imageMast12 from "../../assets/images/walkthru12.png";
import imageMast13 from "../../assets/images/walkthru13.png";


// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
  {
    title: "¡Hola soy Tarjetín!",
    label:
      "Soy tu colega y guía en el Centro de Medios de Pago, acompañaré tus pasos en esta plataforma y sus herramientas.",
    imgPath: imageMast1,
  },
  {
    title: "Soy versátil",
    label:
      "El cambio es mi segundo nombre, me adapto a cualquier producto o situación ¡Te encantarán mis cambios de look!",
    imgPath: imageMast2,
  },
  {
    title: "Tus herramientas",
    label:
      "Aquí encontrarás todas las herramientas, accede de manera fácil y rápida.",
    imgPath: imageMast3,
  },
  {
    title: "¡Visita tu perfil!",
    label:
      "Define un alias y un avatar que te identifique, ¡Seguro tienes una gran personalidad!",
    imgPath: imageMast4,
  },
  {
    title: "Promociones",
    label:
      "Dale un vistazo! Conoce las promociones más recientes y accede a ellas a través del carrusel en la Página de Inicio",
    imgPath: imageMast5,
  },
  {
    title: "Promociones",
    label: "Conoce todas las promociones vigentes de TDC ¡No te quedes fuera!",
    imgPath: imageMast6,
  },
  {
    title: "Promociones",
    label: "¡Busca más rápido y encuentra la promoción adecuada!",
    imgPath: imageMast7,
  },
  {
    title: "Preguntas frecuentes ",
    label:
      "¡Aumenta tu conocimiento y expertise en Medios de Pago! Aquí encontraras la solución a preguntas que te puedes hacer en el día a día",
    imgPath: imageMast8,
  },
  {
    title: "Full On Board (FOB)",
    label:
      "Continúa desarrollando tus habilidades y conocimientos con nuestros e-learnings, certifícate y pon atención a los retos que se estarán activando ¡No te quedes fuera!",
    imgPath: imageMast11,
  },
  {
    title: "Calculadora",
    label:
      "Ofrece el mejor producto a tus clientes con nuestra calculadora de puntos, crea valor para ellos y asesóralos de la mejor manera.",
    imgPath: imageMast10,
  },
  {
    title: "¿Tienes preguntas o comentarios?",
    label:
      "Si necesitas soporte técnico, tienes dudas o quieres dejarnos algún comentario, puedes externarlo a través de este formulario",
    imgPath: imageMast12,
  },
  {
    title: "¿Quieres consultar esto de nuevo?",
    label:
      "Si te encantó este recorrido ¡Puedes volver a verlo!  Estaré feliz de guiarte nuevamente",
    imgPath: imageMast13,
  },
  {
    title: "¡Seguimos en contacto!",
    label:
      "Sigue al pendiente de las nuevas funcionalidades que tenemos pensadas para ti",
    imgPath: imageMast9,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    // width: 500,
    // flexGrow: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 200,
    display: "block",
    maxWidth: 500,
    overflow: "hidden",
    width: "100%",
  },
}));

const Walkthrough = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;
  const history = useHistory();
  const location = useLocation();
  const [showBack, setShowBack] = React.useState(false);
  // var qs = require("qs");

  useEffect(() => {
    if (location.pathname === "/walkthru") {
      setShowBack(true);
    }
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === maxSteps - 1) {
      localStorage.setItem("walkthrough", "true");
      history.push("/home-page");
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const backPage = () => {
    history.goBack();
  };

  return (
    <div className="Walkthrough" data-testid="Walkthrough">
      {/* Walkthrough Component */}
      <div className="static-container container-fluid">
        <HeaderOut />
        <div className="container-fluid sub-menu">
          <div className="container" style={{ paddingTop: "18px", paddingLeft: "20px" }}>
          {showBack && (
            <span onClick={backPage} style={{ cursor: "pointer" }}>
              <span>
                {" "}
                <b>
                  <ArrowBackIosIcon
                    style={{ color: "#FF000A", marginTop: "-2px" }}
                    fontSize="small"
                  />{" "}
                </b>{" "}
              </span>
              <span className="return-btn">Regresar</span>
            </span>
          )}
          </div>
        </div>
      </div>

      <div className="container-fluid main-container">
        <div className="container sub-container   ">
          <div className=" col-md-12 col-12 col-12 d-flex justify-content-center  ">
            <div className="walk-container">
              <div className="carousel-container  ">
                <div className={classes.root}>
                  {/* <Paper square elevation={0} className={classes.header}>
                  <Typography>{tutorialSteps[activeStep].label}</Typography>
                </Paper> */}
                  <SwipeableViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    className="carouselComponent"
                    enableMouseEvents
                  >
                    {tutorialSteps.map((step, index) => (
                      <div key={step.label}>
                        {Math.abs(activeStep - index) <= 2 ? (
                          // <img
                          //   className={classes.img}
                          //   src={step.imgPath}
                          //   alt={step.label}
                          // />

                          <div className="walk-item ">
                            <div className="col-12 mast-head  d-flex justify-content-center align-items-center">
                              <img
                                className="image-mast"
                                src={step.imgPath}
                                alt={step.label}
                              />
                            </div>
                            <div
                              className="col-md-12 col-12 mast-text  d-flex justify-content-center "
                              style={{
                                height: "150px",
                                backgroundColor: "#FFF",
                              }}
                            >
                              {/* <h1> {step.title}  </h1>
                                <div>{step.label}</div> */}
                              <div className="col-md-10 col-12 text-center">
                                <h1> {step.title} </h1>
                                <div className="text-walk pt-2">
                                  {step.label}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </SwipeableViews>
                  <div
                    className=""
                    style={{
                      paddingTop: "4.5em",
                      position: "relative",
                      backgroundColor: "#FFF",
                      width: "98%",
                      marginLeft: "5px",
                    }}
                  >
                    <MobileStepper
                      steps={maxSteps}
                      position="static"
                      variant="dots"
                      activeStep={activeStep}
                      className="mobile-stepper"
                      nextButton={
                        <Button className="next-btn" onClick={handleNext}>
                          {activeStep === 0 ? "Empecemos" : "Siguiente"}
                          {/* {theme.direction === "rtl" ? (
                            <KeyboardArrowLeft />
                          ) : (
                            <KeyboardArrowRight />
                          )} */}
                        </Button>
                      }
                      backButton={
                        activeStep !== 0 && (
                          <span className="back-btn" onClick={handleBack}>
                            Ver anterior
                          </span>
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Walkthrough;
