import React, { useEffect, useRef } from "react";
import "./PromoDetail.scss";
// import Header from "../Header/Header";
import HeaderOut from "../HeaderOut/HeaderOut";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PrintIcon from "@material-ui/icons/Print";
import FooterOut from "../FooterOut/FooterOut";
import LinkIcon from "@material-ui/icons/Link";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useLocation, useHistory } from "react-router-dom";
import { getPromDetail } from "../../api/";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Controller, Scene } from "react-scrollmagic";

const ClassToggle = () => (
  <div className="styled">
    <div className="section" />
    <div id="trigger" />
    <Controller>
      <Scene
        duration={600}
        classToggle="zap"
        triggerElement="#trigger"
        indicators={true}
      >
        {(progress: any, event: any) => (
          <div className="test">
            Pin Test {event.type} {progress}
          </div>
        )}
      </Scene>
      <Scene classToggle={[".test", "yellow"]} reverse={true} indicators={true}>
        <div className="yellow">Toggle other class</div>
      </Scene>
      <Scene classToggle={[".test", "blue"]} reverse={true} indicators={true}>
        <div className="blue">Toggle other class</div>
      </Scene>
    </Controller>
    <div className="section" />
  </div>
);

const PromoDetailPublic = () => {
  const history = useHistory();
  var qs = require("qs");
  const location = useLocation();
  const [detail, setDetail] = React.useState({
    title: "",
    description: "",
    terms: "",
    additionalInfo: "",
    startsAt: "",
    endsAt: "",
    externalAssets: [],
    segments: [],
    products: [],
    files: [],
  });
  const [activeMenu, setActiveMenu] = React.useState("description");
  const [showMore, setShowMore] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [height, setHeight] = React.useState(0);



  useEffect(() => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (queryParams.id) {
      console.log(queryParams.id);
    }
    executeScroll("description");
    const getData = async () => {
      try {
        setShowLoading(true);
        const getDetail = await getPromDetail(queryParams.id);
        setShowLoading(false);
        console.log(getDetail);
        setDetail(getDetail.data);
      } catch (error) {}
    };
    window.addEventListener('scroll', handleScroll);
    getData();
  }, []);


  const handleScroll = () => {
    console.log('ele :', document.getElementById("termsScroll")?.getBoundingClientRect().height);
    const heightElement =  document.getElementById("termsScroll")?.getBoundingClientRect().height || 0;
    setHeight(heightElement)
  }

  const executeScroll = (item: string) => {
    // if (item ==='info') {
    //   var objDiv = document.getElementById("info")?.getBoundingClientRect().bottom || 0;
    //   objDiv = objDiv +100;
    //   window.scrollTo(0, objDiv);
    // }
    // else {
    //   var element = document.querySelector("#" + item);
    //   element?.scrollIntoView({ behavior: "smooth", block: "end" });
    //   setActiveMenu(item);
    // }

    switch (item) {
      case "description":
        var element = document.querySelector("#" + item);
        element?.scrollIntoView({ behavior: "smooth", block: "end" });
        setActiveMenu(item);
      break;
      case 'terms':
        
        console.log('detctMobe :', detectMob());
        if (!detectMob()) {
          var objDiv = document.getElementById("terms")?.getBoundingClientRect().height || 0;
          objDiv = objDiv+300;
        } else {
          var objDiv = document.getElementById("desScroll")?.getBoundingClientRect().height || 0;
        }
        
        window.scrollTo(0, objDiv);
        console.log('objDiv :', objDiv);
      break;
      case 'info':
        var objDivInfo = document.getElementById("info")?.getBoundingClientRect().bottom || 0;
        objDivInfo = objDivInfo +100;
        window.scrollTo(0, objDivInfo);
      break;
    }
  };

  function detectMob() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];
    
    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
  }
  const printPage = () => {};

  const backPage = () => {
    history.push("/public-promotions");
  };

  const showMoreTyC = () => {
    if (showMore) {
      setShowMore(false);
    } else {
      setShowMore(true);
    }
  };

  // const getHeight= (id:string):number => {
  //   console.log('id :', id, document.getElementById(id)?.clientHeight);
  //   return document.getElementById(id)?.clientHeight

  // } 

  return (
    <div className="PromoDetail" data-testid="PromoDetail">
      {/* PromoDetail Component */}

      <div className="static-container container-fluid">
        <HeaderOut />
        <div className="container-fluid sub-menu">
          <div
            className="container"
            style={{ paddingTop: "18px", paddingLeft: "20px" }}
          >
            <span onClick={backPage} style={{ cursor: "pointer" }}>
              <span>
                {" "}
                <b>
                  <ArrowBackIosIcon
                    style={{ color: "#FF000A", marginTop: "-2px" }}
                    fontSize="small"
                  />{" "}
                </b>{" "}
              </span>
              <span className="back-btn">Regresar a promociones</span>
            </span>
          </div>
        </div>
      </div>

      <div className="container-fluid main-container">
        <div className="container sub-container">
          {/* <div id="trigger" />  */}
          {/* <Scene
              duration={200}
              classToggle="zap"
              triggerElement="#trigger"
              indicators={true}
          >
              {(progress: any, event: any) => ( */}
          <div id="desScroll"
            className="row"
            style={{ height: "auto", backgroundColor: "#FFF" }}
          >
            <div  className="col-md-2 left-container">
              <div className="colum-static ">
                <ul className="custom-ul">
                  <li
                    className="col-12 category-item"
                    onClick={() => executeScroll("description")}
                    style={{ paddingLeft: "0.5em" }}
                  >
                    {activeMenu === "description" && (
                      <span className="red-line-v"></span>
                    )}
                    <span style={{ paddingLeft: "0.5em" }}>Descripción</span>
                  </li>
                  <li
                    onClick={() => executeScroll("terms")}
                    className="col-12 category-item"
                    style={{ paddingLeft: "0.5em" }}
                  >
                    {activeMenu === "terms" && (
                      <span className="red-line-v"></span>
                    )}
                    <span style={{ paddingLeft: "0.5em" }}>
                      Términos y Condiciones
                    </span>
                  </li>
                  <li
                    onClick={() => executeScroll("info")}
                    className="col-12 category-item"
                    style={{ paddingLeft: "0.5em" }}
                  >
                    {activeMenu === "info" && (
                      <span className="red-line-v"></span>
                    )}
                    <span style={{ paddingLeft: "0.5em" }}>
                      Información adicional
                    </span>
                  </li>
                  <br />
                </ul>
              </div>
            </div>
            <div className="col-md-8  p-3">
              {showLoading ? (
                <div className="col-12 d-flex justify-content-center">
                  <CircularProgress color="secondary" size={30} />
                </div>
              ) : (
                <div>
                  <div className="col-12" id="description">
                    <div className="red-line" />
                  </div>
                  <h1 className="pt-4 col-12">
                    {/* Tarjeta de crédito Santander Free Libre de comisiones y anualidad */}
                    <div className="row" style={{ padding: 0, margin: 0 }}>
                      <div className="col-10" style={{ padding: 0 }}>
                        {detail.title}{" "}
                      </div>
                      <div className="col-2 d-flex justify-content-end">
                        {/* <span>
                          {" "}
                          <MailOutlineIcon />{" "}
                        </span>
                        <span>
                          {" "}
                          <PrintIcon onClick={printPage} />
                        </span> */}
                      </div>
                    </div>
                  </h1>
                  <div className="col-12  pt-3">
                    <span className="title-detail"> Descripción</span>
                    <div className="red-line" />
                  </div>
                  <div>
                    <p
                      className="pt-4"
                      dangerouslySetInnerHTML={{
                        __html: detail.description,
                      }}
                    ></p>
                  </div>{" "}
                </div>
              )}
            </div>
            <div
              className="col-md-2"
              style={{ padding: 0, paddingLeft: "2px" }}
            >
              <div className="colum-static  right-container row">
                {/* right-menu */}
                <div className="col-12 ">
                  <div
                    className="title-column-right"
                    style={{ marginTop: "1.5em" }}
                  >
                    Vigencia{" "}
                  </div>
                  <div className="answer-column-right">
                    {" "}
                    {moment(detail.endsAt).format("DD-MM-YYYY")}{" "}
                  </div>
                  <div className="title-column-right">Segmentos </div>
                  <div className="row p-2">
                    {detail.segments.map((segment: any) => {
                      return <div className="item"> {segment.name}</div>;
                    })}

                    {/* <div className="item"> Universidades</div>
                  <div className="item"> Universidades</div> */}
                  </div>
                  <div className="title-column-right">Productos </div>
                  <div className="row  p-2">
                    {detail.products.map((product: any) => {
                      return <div className="item"> {product.name}</div>;
                    })}
                    {/* <div className="item"> Free</div>
                  <div className="item"> Fire</div> */}
                  </div>

                  <div className="title-column-right">Links </div>
                  {detail.externalAssets.map((asset: any) => {
                    return (
                      <div style={{ cursor: "pointer" }}>
                        {" "}
                        <a
                          href={asset.url}
                          style={{ textDecoration: "none" }}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {" "}
                          <span className="answer-column-right">
                            {asset.label}{" "}
                          </span>
                          <span>
                            {" "}
                            <LinkIcon
                              style={{
                                textDecoration: "none",
                                color: "#666",
                              }}
                            />{" "}
                          </span>{" "}
                        </a>
                      </div>
                    );
                  })}

                  {/* <div> <span className="answer-column-right">Recompensas </span><span> <LinkIcon /> </span></div>
                  <div> <span className="answer-column-right">Santader Plus </span><span> <LinkIcon /> </span></div> */}
                  <div className="title-column-right">Archivos </div>
                  {detail.files.map((file: any) => {
                    return (
                      <div>
                        {" "}
                        <a
                          href={file.url}
                          style={{ textDecoration: "none" }}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <span className="answer-column-right">
                            {" "}
                            {file.name}{" "}
                          </span>
                          <span>
                            {" "}
                            <GetAppIcon
                              style={{
                                textDecoration: "none",
                                color: "#666",
                              }}
                            />{" "}
                          </span>
                        </a>
                      </div>
                    );
                  })}
                  {/* <div>
                    {" "}
                    <span className="answer-column-right">Santader Plus </span>
                    <span>
                      {" "}
                      <GetAppIcon />{" "}
                    </span>
                  </div> */}
                  <br />
                </div>
              </div>
            </div>
            {/* Pin Test {event.type === "start" && setActiveMenu("terms")}{" "}
                  {progress} */}
          </div>
          {/* )} 
           </Scene> */}
        </div>
        {/* </Controller> */}
        {/* <div>
        <ClassToggle />
        </div> */}






        
        <div id="trigger" />
        <Controller>
          <Scene duration={height} triggerElement="#trigger" indicators={false}>
          {(progress: any, event: any) => (
            <div className=" tyc-container  d-flex justify-content-center">
              {event.type === "start" && setActiveMenu("terms")}
              {event.type === "end" && setActiveMenu("terms")}
              {/* {event.type === "leave" && setActiveMenu("terms")} */}
              { (event.type === "leave" || event.type === "" ) && setActiveMenu("description")}
              <div id="termsScroll" className="container" style= {{height: 'auto'}}>
                <div className="row" style= {{height: 'auto'}}>
                  <div className="col-1 col-md-2"></div>
                  <div className="col-10 col-md-8">
                    {!showLoading && (
                      <div id="post-container" className="col-12  pt-3">
                        <span className="title-detail">
                          {" "}
                          Términos y Condiciones
                        </span>
                        <div className="red-line" />
                        <div id="terms"></div>
                      </div>
                    )}
                    <div>
                      <p className="pt-4">
                        {!showMore ? (
                          <div
                            className="more "
                            dangerouslySetInnerHTML={{
                              __html: detail.terms.substring(0, 1500) + "",
                            }}
                          ></div>
                        ) : (
                          <div
                            className="less"
                            dangerouslySetInnerHTML={{ __html: detail.terms }}
                          ></div>
                        )}
                        {detail.terms.length >= 1500 && (
                          <span
                            className="ver-mas"
                            onClick={() => showMoreTyC()}
                          >
                            {!showMore ? "Ver más" : "Ver menos"}
                          </span>
                        )}
                      </p>
                    </div>
                    {/* <div className="pt-2" id="terms"></div> */}
                  </div>
                  <div className="col-1 col-md-2"></div>
                </div>
                {/* {event.type} */}
              </div>
            </div>)}
          </Scene>
          <Scene indicators={false}>
          {(progress: any, event: any) => (
            <div className=" info-container  d-flex justify-content-center">
              {event.type === "start" && setActiveMenu("info")}
              {/* {event.type === "end" && setActiveMenu("info")} */}
              <div id="infoScroll" className="container">
                  <div className="row">
                    <div className="col-1 col-md-2"></div>
                    <div className="col-10 col-md-8">
                      {!showLoading && (
                        <div className="col-12  pt-3">
                          <span className="title-detail">
                            {" "}
                            Información adicional
                          </span>
                          <div className="red-line" />
                        </div>
                      )}
                      <div>
                        <p
                          className="pt-4"
                          dangerouslySetInnerHTML={{
                            __html: detail.additionalInfo,
                          }}
                        ></p>
                      </div>
                      <div className="pt-2" id="info"></div>
                    </div>
                    <div className="col-1 col-md-2"></div>
                  </div>
              </div>
            </div>
            )}
          </Scene>
        </Controller>

        <FooterOut />
        <div id="info"></div>
      </div>
    </div>
  );
};

export default PromoDetailPublic;
