import React, { FC } from "react";
import "./TermsConditions.scss";
import HeaderOut from "../HeaderOut/HeaderOut";
import Footer from "../Footer/Footer";
import { Link, useHistory } from "react-router-dom";

interface TermsConditionsProps {}

const TermsConditions: FC<TermsConditionsProps> = () =>  {
  const history = useHistory();
  return(
  <div className="TermsConditions" data-testid="TermsConditions">
    {/* TermsConditions Component */}
    <div className="static-container container-fluid">
      <HeaderOut />
      <div className="container-fluid sub-menu"></div>
    </div>

    <div className="container-fluid main-container">
      <div className="container sub-container   ">
        <div className=" col-md-12 col-12 col-12 d-flex justify-content-center  ">
          <div className="terms-container container p-4 d-flex justify-content-center">
            <div className="tyc-content p-1 ">
              <h1>Términos y condiciones</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse ullamcorper tortor sit amet ante bibendum, et
                suscipit ipsum facilisis. Pellentesque congue mi eu justo
                vulputate dictum. Etiam venenatis metus sapien, ut laoreet
                lectus pretium vitae. Donec ac eros at lacus viverra dapibus nec
                nec nunc. Donec vel orci semper, vulputate turpis sed, euismod
                elit. Mauris velit nisl, porta eu facilisis at, accumsan quis
                turpis. Class aptent taciti sociosqu ad litora torquent per
                conubia nostra, per inceptos himenaeos. <br />
                Duis in interdum risus, sit amet semper leo. Mauris faucibus
                elit eget est lobortis volutpat. Donec dignissim ex quis risus
                rutrum dapibus. Cras a nibh id enim aliquam molestie.
                Suspendisse gravida molestie eleifend. Aenean in mi diam. In
                ultrices ipsum et ligula lobortis gravida. Aliquam a nibh nisl.
                Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
                posuere cubilia curae; Aliquam a auctor quam. Vivamus a ipsum at
                purus aliquam sollicitudin. Cras luctus nibh quis nisi luctus
                gravida. Nulla pharetra, est in vestibulum aliquet, urna ligula
                lobortis orci, vitae vulputate lacus diam sollicitudin mi. Nulla
                non dapibus metus, commodo molestie turpis. Fusce vitae eleifend
                nisi. Proin volutpat sem sed sollicitudin sollicitudin. Aliquam
                erat volutpat. Donec at euismod arcu, eu aliquet odio. Maecenas
                blandit velit maximus ligula ultricies, in fermentum elit
                condimentum. Maecenas a leo vehicula, varius elit eget, pretium
                dui. Proin lacinia volutpat dui at ultricies. Integer vel mauris
                vitae tellus pharetra finibus vitae vel enim. In finibus nulla
                in ultrices porta. Pellentesque condimentum nisi vel orci
                feugiat finibus. Mauris in diam molestie, facilisis ante nec,
                varius mauris. Curabitur libero urna, cursus id pellentesque
                vulputate, tincidunt non est. Curabitur sagittis molestie lectus
                sit amet porttitor. Pellentesque in malesuada magna, at
                efficitur libero. <br /> <br />
                Sed dapibus, odio eu pharetra lobortis, velit eros placerat
                augue, ut posuere leo est sed urna. Suspendisse eget tellus
                nulla. Pellentesque vehicula lacus at ipsum interdum, ut
                elementum mi commodo. Curabitur id efficitur lacus. Mauris a
                nunc id neque eleifend fringilla non ut mi. Cras nec augue
                aliquet, lacinia ipsum a, tempus mi. Suspendisse volutpat
                vulputate purus et faucibus. Nulla aliquam quis urna vulputate
                dictum. Donec ultricies tempor mauris vitae venenatis. Aliquam
                quis sapien in eros viverra molestie. Vestibulum in mi urna.
                Integer justo metus, lacinia ac dolor sed, facilisis
                sollicitudin purus. Pellentesque odio orci, pellentesque sit
                amet dictum vitae, luctus vitae nulla. Sed sollicitudin nunc vel
                leo aliquam iaculis. Duis semper purus at hendrerit facilisis.
                Maecenas malesuada porttitor augue, in pulvinar neque venenatis
                et. Nunc varius porta aliquam. Etiam lectus ipsum, consectetur a
                ultricies nec, venenatis auctor enim. Nulla sit amet quam nisl.
                Etiam ac lorem sed erat ultrices ultricies vitae ut neque. Proin
                vulputate venenatis massa, a volutpat neque sodales quis. Fusce
                vitae arcu eu justo commodo iaculis ac ac odio. Duis augue urna,
                luctus sit amet venenatis tempus, rutrum non orci. Vivamus
                pretium sed diam sed vehicula. Vestibulum non nunc id ligula
                elementum pharetra eget vitae arcu. <br />
                <br />
                Phasellus in sem a augue imperdiet molestie ut vel purus.
                Curabitur fringilla nisi ac libero tincidunt convallis. Praesent
                euismod leo metus, eget tempor erat rutrum sit amet. Donec
                interdum lorem a nisi consequat sollicitudin. Phasellus nec
                pretium turpis. Cras condimentum commodo est quis sollicitudin.
                Donec varius aliquam tortor sed imperdiet. Cras et sagittis
                nisl. Duis placerat mauris at ex feugiat ullamcorper. Cras et
                erat lacus. Nullam ultricies dui vehicula velit egestas, et
                viverra leo consectetur. Quisque turpis leo, gravida vel turpis
                sed, convallis iaculis magna. Vivamus dignissim orci id libero
                varius accumsan.
              </p>
              <div className="col-12 text-center">
                
                  <button
                  onClick={() => {history.goBack()} }
                    className="btn btn-primary col-md-2 col-12 mt-4"
                  >
                    Entendido
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
)};

export default TermsConditions;
