import React from 'react';
import './FooterOut.scss';
// import packageJson from '../../../package.json';


const FooterOut = () => (
  <div className="FooterOut" data-testid="FooterOut">
    <div className="container-fluid footer-container d-flex align-items-center">
      {/* 2021 ©Santander | v{packageJson.version} */}
      2021 ©Santander | Información confidencial. No compartir
    </div>
  </div>
);

export default FooterOut;
