import React, { useEffect } from "react";
import "./Promotions.scss";
import Header from "../Header/Header";
import PromItem from "../PromItem/PromItem";
import DetailsIcon from "@material-ui/icons/Details";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";

import {
  ThemeProvider,
  createTheme,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import FilterItem from "../FilterItem/FilterItem";
import SubHeader from "../SubHeader/SubHeader";
import {
  getPromCategories,
  getPromsByCategory,
  searchProm,
  getPromSegments,
  fiterProm,
  getPromProducts,
} from "../../api/";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import tarjetin from "../../assets/images/tarjetin-no-result.png"
// import { Filter, FilterSharp } from "@material-ui/icons";
import filterButton from "../../assets/images/filter.png";
import NeedHelp from "../NeedHelp/NeedHelp";
import Footer from "../Footer/Footer";


type Anchor = "top" | "left" | "bottom" | "right";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    bgColor: {
      minWidth: "40px",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

interface State {
  search: string;
}
interface Segment {
  id: number;
  name: string;
  active: boolean;
}

interface Product {
  id: number;
  name: string;
  active: boolean;
}

const Promotions = () => {
  const [values, setValues] = React.useState<State>({
    search: "",
  });

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [categories, setCategories] = React.useState([]);
  const [title, setTitle] = React.useState("");
  const [promotions, setPromotions] = React.useState([]);
  const classes = useStyles();
  const [showLoading, setShowLoading] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [segmentTemp, setSegmentTemp] = React.useState<Segment[]>([]);
  const [productTemp, setProductTemp] = React.useState<Product[]>([]);
  const [showAppliedFilter, setShowAppliedFilter] = React.useState(false);
  const [filters, setFilters] = React.useState<any>([]);
  const [index, setIndex] = React.useState(0);

  const history = useHistory();

  useEffect(() => {
    const getCategories = async () => {
      try {
        setShowLoading(true);
        const req_categories = await getPromCategories();
        console.log(req_categories);
        // setShowLoading(false);
        req_categories.data.forEach((item: any) => {
          item.active = false;
        });
        req_categories.data[0].active = true;
        setTitle(req_categories.data[0].category);
        showCategory(req_categories.data[0].id);
        setIndex(req_categories.data[0].id)
        req_categories.data.push({"id":0,"category":"Promociones Vencidas"})
        setCategories(req_categories.data.slice(0));
      } catch (error: any) {
        console.log("error categories", error);
        setShowLoading(false);
      }
    };

    const getFilterSegments = async () => {
      try {
        // setShowLoading(true);
        const segmentsReq = await getPromSegments();
        segmentsReq.data.forEach((item: any) => {
          item.active = false;
        });
        console.log(segmentsReq);
        // setShowLoading(false);
        setSegmentTemp(segmentsReq.data);
      } catch (error) {
        setShowLoading(false);
      }
    };

    const getFilterProduct = async () => {
      try {
        // setShowLoading(true);
        const productsReq = await getPromProducts();
        productsReq.data.forEach((item: any) => {
          item.active = false;
        });
        console.log('products filter',productsReq);
        // setShowLoading(false);
        setProductTemp(productsReq.data);
      } catch (error) {
        setShowLoading(false);
      }
    };

    getFilterProduct();
    getFilterSegments();
    getCategories();
    
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#407FA0",
      },
    },
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      // const aFiltersActive = filters.slice();
      // const aSegmentTemp = segmentTemp.slice();
      // const aProductTemp = productTemp.slice();

      // console.log(aFiltersActive, aSegmentTemp);

      // for (let i = 0; i < aFiltersActive.length; i++) {
      //   for (let j = 0; j < aSegmentTemp.length; j++) {
      //     if (aSegmentTemp[j].id === aFiltersActive[i].itemData.id) {
      //       aSegmentTemp[j].active = aFiltersActive[i].itemData.active;
      //     }
      //   }
      // }
      // console.log(aSegmentTemp);
      // setSegmentTemp(aSegmentTemp);

      // for (let i = 0; i < aFiltersActive.length; i++) {
      //   for (let j = 0; j < aProductTemp.length; j++) {
      //     if (aProductTemp[j].id === aFiltersActive[i].itemData.id) {
      //       aProductTemp[j].active = aFiltersActive[i].itemData.active;
      //     }
      //   }
      // }
      // console.log(aProductTemp);
      // setProductTemp(aProductTemp);


      setState({ ...state, [anchor]: open });
    };

  const closeDrawer = () => {
    setState({ ...state, right: false });
  };

  const [tempFilter, setTempFilter] = React.useState<any[]>([]);
  const addNewFilter = (filter: any) => {
    console.log(filter);
    if (filter.add) {
      setTempFilter([...tempFilter, filter]);
    } else {
      setTempFilter(
        tempFilter.filter((e: any) => e.itemData.id !== filter.itemData.id)
      );

      if (tempFilter.length === 1) {
        console.log ("quite the las filter");
        clearFilters();
      }
    }
  }

  const addFilter = (itemFilter: any) => {
    console.log(itemFilter);
    if (itemFilter.add) {
      setFilters([...filters, itemFilter]);
    } else {
      setFilters(
        filters.filter((e: any) => e.itemData.id !== itemFilter.itemData.id)
      );

      if (filters.length === 1) {
        console.log ("quite the las filter");
        clearFilters();
      }
    }
  };

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
      event.preventDefault();
    };

  // const handleClickSearch = async () => {
  //   try {
  //     setShowLoading(true);
  //     setTitle("Busqueda por");
  //     const reqPromos = await searchProm(values.search);
  //     console.log(reqPromos);
  //     setPromotions(reqPromos.data.items.slice(0));
  //     setSearch(values.search);
  //     setShowLoading(false);
  //   } catch (error) {
  //     setShowLoading(false);
  //   }
  //   console.log("to search", values.search);
  // };

  const handleClickSearch = () => {
    
    
    const searchFilter = {
      add: false,
      category: 'search',
      itemData: {
        id: 'search',
        name: values.search,
        value: values.search,
      }
    }

     const aTemSearch = filters.slice(0)

    for (let i = 0; i <  aTemSearch.length; i++) {
      if (aTemSearch[i].category === "search") {
        aTemSearch[i].itemData.name = values.search;
        aTemSearch[i].itemData.value =  values.search;
        setTempFilter(aTemSearch);
        setFilters(aTemSearch);
        setShowAppliedFilter(true);
        return;
      }
    }

    setTempFilter([...tempFilter, searchFilter]);
    setFilters([...filters, searchFilter]);
    // applyFilter();
    setShowAppliedFilter(true);
  };

  const deactivateCategory = () => {
    const temp = categories.slice();

    temp.forEach((category: any) => {
      category.active = false;
    });
    setTitle("Tus resultados:");
    // setSearch("");
    setCategories(temp);
  };

  const showCategory = async (id: number) => {


    const temp = categories.slice();
    window.scrollTo(0, 0);
    temp.forEach((category: any) => {
      category.active = false;
      if (category.id === id) {
        category.active = true;
        setTitle(category.category);
      }
    });
    setSearch("");
    setCategories(temp);
    setPromotions([]);

    if (id === 0) {
      try {
        setShowLoading(true);
        // const expiredReq = await fiterProm('id=0&');
        const expiredReq = await fiterProm('categoryId=0&');
        // console.log('expieds=',expiredReq)
        expiredReq.data.items.forEach((item: any) => {
          item.expired = true;
        });
        // console.log('expiredReq',expiredReq)
        setPromotions(expiredReq.data.items);
        setShowLoading(false);
        setShowAppliedFilter(false);
        setOrder("default");
        setValues({search: ""});
      } catch (error) {
        setShowLoading(false);
        console.log("error prom", error);
      }

    } else {
      try {
        setShowLoading(true);
        const req_proms = await getPromsByCategory(id);
        console.log(req_proms.data.items);
        setPromotions(req_proms.data.items);
        setShowLoading(false);
        setShowAppliedFilter(false);
        setOrder("default");
        setValues({search: ""});
      } catch (error) {
        setShowLoading(false);
        console.log("error prom", error);
      }
    }
  };

  const removeSearch = () => {
    setSearch("");
    showCategory(index);
    setValues({
      search: "",
    });
  };

  const removeFilter = (filter: any) => {
    // console.log(fiter.id);

    setTempFilter(
      tempFilter.filter((e: any) => e.itemData.id !== filter.itemData.id)
    )
    
    setFilters(
      filters.filter((e: any) => e.itemData.id !== filter.itemData.id)
    );
    const aSegmentTemp = segmentTemp.slice();

    if (filter.category === "order") {
      setOrder("default");
    }


    if (filter.category === "search") {
      setValues({
        search: "",
      });
    }

    // console.log(aFiltersActive, aSegmentTemp);

    for (let j = 0; j < aSegmentTemp.length; j++) {
      if (aSegmentTemp[j].id === filter.itemData.id) {
        aSegmentTemp[j].active = false;
      }
    }
    setSegmentTemp(aSegmentTemp);

    //remove active from productTemp  if filter is active

    const aProductTemp = productTemp.slice(); 

    for (let j = 0; j < aProductTemp.length; j++) {
      if (aProductTemp[j].id === filter.itemData.id) {
        aProductTemp[j].active = false;
      }
    }
    setProductTemp(aProductTemp);
    



    // at las filter active return to default category 
    const aFiltersActive = filters.slice();
    if (aFiltersActive.length === 1 && !aFiltersActive[0].itemData.active) {
      showCategory(index);
    } else {
      applyFilter();
    }
  };

  const clearFilters = () => {
    const aSegmentTemp = [];
    for (let j = 0; j < segmentTemp.length; j++) {
      const item = {
        id: segmentTemp[j].id,
        name: segmentTemp[j].name,
        active: false,
      };
      aSegmentTemp.push(item);
    }
    console.log(aSegmentTemp);

    setSegmentTemp(aSegmentTemp);



    // product
    const aProductTemp = [];
    for (let j = 0; j < productTemp.length; j++) {
      const item = {
        id: productTemp[j].id,
        name: productTemp[j].name,
        active: false,
      };
      aProductTemp.push(item);
    }
    console.log(aProductTemp);

    setProductTemp(aProductTemp);


    showCategory(index);
    setFilters([]);
  };


  useEffect(() => {
    // ref.current = value;
    console.log('inside', filters);
    // applyFilter();
    if (filters.length !== 0 && showAppliedFilter) {
      applyFilter();
    }
  }, [filters]);

  const applyFilter = async () => {

    const reqFilter = filters.slice();
    console.log(reqFilter);
    let query = "";

    reqFilter.forEach((filter: any) => {
      console.log('filter cat', filter.category);
      if (filter.itemData.active && filter.category === "segment") {
        const segment = "segmentIds=" + filter.itemData.id.toString() + "&";
        query = query + segment;
      }
      if (filter.category === "order") {

        const order = "orderBy=" + filter.itemData.value + "&orderDirection="+filter.itemData.direction + "&";
        query = query + order;
      }


      if (filter.category === "search") {

        const search = "title=" + filter.itemData.value + "&";
        query = query + search;
      }


      if (filter.itemData.active && filter.category === "product") {
        const category = "productIds=" + filter.itemData.id.toString() + "&";
        query = query + category;
      }
      
      

    });
    console.log("query", query);
    
    try {
      setPromotions([]);
      setShowLoading(true);
      const reqFilters = await fiterProm(query);
      setPromotions(reqFilters.data.items.slice(0));
      setShowLoading(false);
      closeDrawer();
      setShowAppliedFilter(true);
      deactivateCategory();
      setFilters(tempFilter);
      //quite active menu
    } catch (error) {
      setShowLoading(false);
    }
  };

  const [order, setOrder] = React.useState("");

  const handleChangeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setOrder(event.target.value as string);
    

    if (
      (event.target.value as string) === "startsAt" ||
      (event.target.value as string) === "endsAt"
    ) {
      console.log ("selected",(event.target.value as string));
      const orderFilter = {
        add: false,
        category: 'order',
        itemData: {
          id: 'order',
          name: (event.target.value as string) === "startsAt" ? "Más reciente" : "Menos reciente",
          value: event.target.value as string,
          direction: (event.target.value as string) === "startsAt" ? "DESC" : "ASC",
        }
      }
      console.log('orderFilter',orderFilter);

       const aTemOrder = filters.slice(0)

      for (let i = 0; i <  aTemOrder.length; i++) {
        if (aTemOrder[i].category === "order") {
          console.log('enter');
          aTemOrder[i].itemData.name =  orderFilter.itemData.name;
          aTemOrder[i].itemData.value =  "startsAt";
          aTemOrder[i].itemData.direction =  orderFilter.itemData.direction;
          setTempFilter(aTemOrder);
          setFilters(aTemOrder);
          setShowAppliedFilter(true);
          return;
        }
      }
      
      setTempFilter([...tempFilter, orderFilter]);
      setFilters([...filters, orderFilter]);
      // applyFilter();
      setShowAppliedFilter(true);
      
      // setTitle('Filtrado por :');


    }
  };


  const  setFOPage = (item:object) => {
    history.push('/full-on-board?page='+item);
  }

  return (
    <div className="Promotions" data-testid="Promotions">
      <div className="static-container container-fluid">
        <Header />
        <SubHeader path="/promotions" setFOMenu={setFOPage} />
        {/* <div className="container-fluid sub-menu"></div> */}
      </div>

      <div className="container-fluid main-container ">
        <div className="container sub-container">
          <div className="container-static container no-fixed" style={{/*zIndex: 999*/}}>
            <div className="upto container"></div>
            <div
              className="row  mast-height-auto"
              style={{
                margin: 0,
                /*minHeight: "160px",*/ 
                paddingTop: "2em",
                padding: 0,
              }}
            >
              <div className="col-md-3  h-100 d-inline-block " style={{padding: 0}}>
                {/* title */}
                <div className="" style={{width:'90%', /*backgroundColor: '#FFF', border: '1px solid #FFF',*/ paddingLeft: '5px'}}>

                
                <div className="col-12" >
                &nbsp;
                  <div className="red-line "></div>
                </div>
                <h1 className="pt-4">Promociones</h1>
                <p className="sub-title-section col-12 col-md-12" >
                  Promociones disponibles para TDC
                </p>
                </div>
                <div className="  col-md-12 col-10 ">
                  <ul className="custom-ul pb-3" style={{/*backgroundColor: '#FFF',*/ width:'90%', borderRadius:'5px'}}>
                    {categories?.map((category: any, index: number) => {
                      return (
                        <li
                          onClick={() => showCategory(category.id)}
                          key={category.id}
                          className="col-12 category-item"
                        >
                          {" "}
                          {category.active && (
                            <span className="red-line-v"></span>
                          )}{" "}
                          <span
                            className={category.active ? "menu-text" : ""}
                            style={{ paddingLeft: "0.5em" }}
                          >
                            {category.category}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              
            </div>
          </div>
          <div className="proms-container col-12 ">
            <div className="row">
              <div className="col-md-3"> {/*empty */}</div>
              <div className="col-md-9 tile-container p-3">

              <form>
                  <div className="row pt-2 form-contianer ">
                    <div className="col-md-3"></div>
                    <div className="col-md-3 pt-2">
                      <ThemeProvider theme={theme}>
                        <TextField
                          id="standard-adornment-password"
                          type="text"
                          label="Buscar"
                          value={values.search}
                          onKeyPress={(ev) => {
                            // console.log(`Pressed keyCode ${ev.key}`);
                            if (ev.key === "Enter") {
                              handleClickSearch()
                              // Do code here
                              ev.preventDefault();
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {" "}
                                <SearchIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={handleClickSearch}
                                />
                              </InputAdornment>
                            ),
                          }}
                          onChange={handleChange("search")}
                          className={classes.bgColor}
                        ></TextField>
                      </ThemeProvider>

                      {/* <input
                        type="text"
                        placeholder="Buscar"
                        className="form-control"
                      ></input> */}
                    </div>
                    <div className="col-md-3  pt-2">
                      {/* <select className="form-control">
                        <option value="female">Ordenar por</option>
                        <option value="male">Más reciente</option>
                        <option value="other">Más relevante</option>
                        <option value="other">Categorías (Default)</option>
                      </select> */}
                      <Select
                        value={order}
                        onChange={handleChangeSelect}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="default">
                          <em>Ordenar por</em>
                        </MenuItem>
                        <MenuItem value="startsAt">Más reciente</MenuItem>
                        <MenuItem value="endsAt">Menos reciente</MenuItem>
                        {/* <MenuItem value="default">Más relevante</MenuItem> */}
                        {/* <MenuItem value="default">Default categorias</MenuItem> */}
                      </Select>
                    </div>
                    <div className="col-md-3 text-center">
                      {/* <button
                        onClick={toggleDrawer("right", true)}
                        className="btn btn-secondary col-md-8 col-12 mt-2"
                      >
                        Filtrar por <DetailsIcon />
                      </button> */}
                      <Button
                        onClick={toggleDrawer("right", true)}
                        className="btn btn-secondary  mt-2" style={{width: "auto"}}
                      >
                        <span style={{ fontVariant: "small-caps" }}>
                          Filtrar por{" "}
                        </span>
                        {/* <DetailsIcon /> */}
                        <img src={filterButton} alt="filter" style={{marginLeft: '5px'}}/>
                      </Button>
                    </div>
                  </div>
                </form>





                {showLoading ? (
                  <div className="col-12 d-flex justify-content-center ">
                    <CircularProgress color="secondary" size={30} />
                  </div>
                ) : (
                  <div className="pt-4">
                    {/* <span className="prom-title">{title} &nbsp; {search !== "" && <span className="filters-added" onClick={removeSearch}> {search} &nbsp; x</span>}</span> */}
                    <span className="prom-title">{title} &nbsp;</span>
                    <div className="vert-red-line"></div>
                    
                    {showAppliedFilter && (
                      <div className="row filters-container">
                        {filters?.map((filter: any, i: number) => {
                          return (
                            <span style={{height: 'auto', width: 'auto'}} key={filter.itemData.id}>
                              <span  onClick={() => removeFilter(filter)}>
                                <span className="filters-added"> {filter.itemData.name} </span> <span className="no-underline"> <CloseIcon  fontSize="small"/> </span>
                              </span>
                              <span
                                style={{
                                  textDecorationLine: "none",
                                  color: "#000",
                                }}>
                                &nbsp;
                              </span>{" "}
                            </span>
                          );
                        })}
                      </div>
                    )}
                    
                    {promotions.length === 0 ? 
                    <div className="tarjetin-cointainer col-12 p-2">
                      {/* <div style={{color: 'red'}}>¡Lo sentimos! No encontramos resultados. </div> */}
                      <div className="col-md-7 col-10">
                        <div className="row">
                          <div className="col-md-4 col-6 " style={{padding: '0px'}}>
                            <img src={tarjetin} className="col-12 img" alt="tarjetin"/>
                          </div>
                          <div className="col-md-8 col-6 p-3">
                              <h1 style={{fontSize: '19px'}}> Lo sentimos, </h1>
                              <div className="text-no-result pb-3" style={{borderBottom: 'solid 1px #E4E4E4'}}>
                                No se encontraron resultados que 
                                contengan todos los términos de tu búsqueda.
                              </div>
                              <div className="text-no-result pt-3">
                                <div className="pb-2"><b>Sugerencias:</b></div>
                                Asegúrate de que todas las palabras estén escritas correctamente.
                                Prueba con diferentes palabras clave
                                o palabras más generales.
                              </div>
                          </div>
                        </div>
                      </div>
                   </div>
                    :
                      <div className="row tiles-container">
                        {promotions.map((prom: any) => {
                          return <PromItem key={prom.id} data={prom} from="private"/>;
                        })}
                      </div> }
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <React.Fragment key={"right"}>
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
          >
            {/* {list("right")} */}
            <div className="col-12 " style={{ width: "350px", height: "auto" }}>
              <div
                className="col-12 drawer-container  p-4"
                style={{ overflowY: "auto", height: "100%" }}
              >
                <div className="row">
                  <div className=" col-10 title-filter">Filtrar por</div>
                  <div
                    className="col-2"
                    onClick={closeDrawer}
                    style={{ cursor: "pointer" }}
                  >
                    <CloseIcon />
                  </div>
                  <br />
                </div>

                <br />
                <p className="sub-title-filter mt-2">Segmento</p>
                <div
                  className="col-12 segmento-container p-2"
                  style={{ overflowY: "auto" }}
                >
                  <div className="row">
                    {segmentTemp.map((segment: any, index: number) => {
                      return (
                        <span key={segment.id} className="filter-item">
                          <FilterItem
                            setFilter={addNewFilter}
                            data={segment}
                            category="segment"
                          />{" "}
                        </span>
                      );
                    })}
                    {/* <span className="filter-item"><FilterItem label="Universidades" /> </span>
                    <span className="filter-item"><FilterItem label="Preferente" /> </span>
                    <span className="filter-item"><FilterItem label="Selecto" /> </span>
                    <span className="filter-item"><FilterItem label="Universidades" /> </span>
                    <span className="filter-item"><FilterItem label="Preferente" /> </span>
                    <span className="filter-item"><FilterItem label="Selecto" /> </span>
                    <span className="filter-item"><FilterItem label="Universidades" /> </span>
                    <span className="filter-item"><FilterItem label="Preferente" /> </span>
                    <span className="filter-item"><FilterItem label="Selectos 1" /> </span> */}
                  </div>
                </div>
                <br />
                <p className="sub-title-filter mt-2">Producto</p>
                <div
                  className="col-12 segmento-container p-2"
                  style={{ overflowY: "auto" }}
                >
                  <div className="row">
                  {productTemp.map((product: any, index: number) => {
                      return (
                        <span key={product.id} className="filter-item">
                          <FilterItem
                            setFilter={addNewFilter}
                            data={product}
                            category="product"
                          />{" "}
                        </span>
                      );
                    })}
                    {/* <span className="filter-item">
                      <FilterItem label="Free" />{" "}
                    </span>
                    <span className="filter-item">
                      <FilterItem label="Light" />{" "}
                    </span>
                    <span className="filter-item">
                      <FilterItem label="Flex Card" />{" "}
                    </span>
                    <span className="filter-item">
                      <FilterItem label="Free" />{" "}
                    </span>
                    <span className="filter-item">
                      <FilterItem label="Light" />{" "}
                    </span>
                    <span className="filter-item">
                      <FilterItem label="Free" />{" "}
                    </span>
                    <span className="filter-item">
                      <FilterItem label="Light" />{" "}
                    </span> */}
                  </div>
                </div>
                {/* <p className="sub-title-filter mt-2">Vigencia</p> */}
                {/* <div
                  className="col-12  segmento-container p-2"
                  style={{ overflowY: "auto" }}
                >
                  <div className="row">
                   
                  </div>
                </div> */}
                <div className="col-12 buttons-drawer d-flex justify-content-between pt-2">
                  <button
                    disabled={tempFilter.length === 0}
                    onClick={clearFilters}
                    className="btn btn-secondary col-md-5 col-5 mt-2"
                  >
                    Limpiar
                  </button>
                  <button
                    disabled={tempFilter.length === 0}
                    onClick={applyFilter}
                    className="btn btn-primary col-md-5 col-5 mt-2 "
                  >
                    Aplicar
                  </button>
                </div>
                {/* {filters[0]?.itemData.name} */}
                {/* {filters.length} */}
              </div>
            </div>
          </Drawer>
        </React.Fragment>
        <div style={{marginTop: "100px"}}>
          <NeedHelp />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Promotions;
