import React from "react";
import "./MainPage.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import HomeCorousel from "../HomeCorousel/HomeCorousel";
import {Link, useHistory}from 'react-router-dom'
import SubHeader from "../SubHeader/SubHeader";
import Logo from "../../assets/images/logo-cmp-v2-plataforma.png";
import NeedHelp from "../NeedHelp/NeedHelp";


const MainPage = () => { 
  
  const history = useHistory();
  

  const  setFOPage = (item:any) => {
    
    history.push('/full-on-board?page='+item);
  }
  
  return (
  <div className="MainPage" data-testid="MainPage">
    {/* MainPage Component */}
    <div className="static-container container-fluid">
      <Header />
      <SubHeader path="/home-page" setFOMenu={setFOPage}/>
    </div>
    <div className="container-fluid section-two ">
      <div className="pleca-home"></div>
      <div className="container">
        <div className="row " style={{ height: "auto", marginLeft: '2em' }}>
          <div
            className="col-md-6  d-flex justify-content-center align-items-center"
            style={{ height: "510px" }}
          >
            <div className=" col-12  col-md-10">
              <img src={Logo} alt="" style={{height: '106.5px', width: '145', zIndex:9}} />
              <h2 className= "pt-2">CENTRO DE</h2>
              <h1 style={{marginTop: '-10px'}}> Medios de Pago</h1>
              <div className="col-12 ">
                <div className="red-line" />
              </div>
              <p className="subtitle col-md-12 col-12 pt-2">
                {/* El portal para ayudarte a cumplir tus objetvios. <br />{" "}
                Encontraras todo respecto a medios de pagos. */}
                Un portal hecho para apoyarte a cumplir tus metas y objetivos, todo lo que necesitas a un solo click de distancia.
              </p>
            </div>
          </div>
          <div className="col-md-6  p-3" style={{ height: "510px" }}>
            <div className="col-12" style={{paddingLeft: '2em'}}>
              <div className="red-line" />
            </div>
            <h1 className="pt-2 d-flex justify-content-between" style={{paddingLeft: '1em'}}>
              Promociones<div className="link"style={{marginTop: '20px'}}>  <Link to="/promotions">Ver todas </Link></div>{" "}
            </h1>
            <div className="carouser-container">
              <HomeCorousel />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid section-three">
      <div className="container pt-4">
        <div className="d-flex justify-content-center align-items-center  p-4">
          <div className="col-12 col-md-10  m-t p-4">
            <h3>¿Sabías que?</h3>
            <h1>Rentabilidad de sucursal</h1>
            <p className="bottom-container">
              Tú eres el principal responsable de la rentabilidad de tu sucursal, por lo tanto está en ti el crecimiento del banco; recuerda que la mejor manera de hacer crecer tu rentabilidad es logrando tus metas comerciales, cuidando insolvencias, analizando tu cuenta de resultados e incrementando tus carteras de clientes.
            </p>
            <button className="btn btn-primary col-md-2 col-12">
              Ver informe
            </button>
          </div>
        </div>
      </div>
    </div>
    <NeedHelp />
    <Footer />
  </div>
)};

export default MainPage;
