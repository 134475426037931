import React, { useEffect } from "react";
import "./HomeCorousel.scss";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import ItemHome from "../ItemHome/ItemHome";
// import { useRef } from "hoist-non-react-statics/node_modules/@types/react";
import { indexProm } from "../../api";
import { autoPlay } from "react-swipeable-views-utils";
import { CircularProgress } from "@material-ui/core";
// import { duration } from "moment";
import PromItem from "../PromItem/PromItem";


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

// const tutorialSteps = [
//   {
//     label: "San Francisco – Oakland Bay Bridge, United States",
//     imgPath:
//       "https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60",
//   },
//   {
//     label: "Bird",
//     imgPath:
//       "https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60",
//   },
//   {
//     label: "Bali, Indonesia",
//     imgPath:
//       "https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80",
//   },
//   {
//     label: "NeONBRAND Digital Marketing, Las Vegas, United States",
//     imgPath:
//       "https://images.unsplash.com/photo-1518732714860-b62714ce0c59?auto=format&fit=crop&w=400&h=250&q=60",
//   },
//   {
//     label: "Goč, Serbia",
//     imgPath:
//       "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
//   },
// ];

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    flexGrow: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: "theme.palette.background.default",
  },
  div: {
    backgroundColor: "#000",
  },
  img: {
    height: 255,
    display: "block",
    maxWidth: 400,
    overflow: "hidden",
    width: "100%",
  },
}));

const HomeCorousel = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [maxSteps, setMaxSteps] = React.useState(0);
  const [showLoading, setShowLoading] = React.useState(false);
  const [indexPromotions, setIndexPromotions] = React.useState([]);
  const [runCarrusel, setRunCarrusel] = React.useState(false);

  useEffect(() => {
    const getIndexPromo = async () => {
      try {
        setShowLoading(true);
        const req = await indexProm();
        setIndexPromotions(req.data.items);
        console.log(req.data.items);
        setMaxSteps(req.data.items.length);
        setShowLoading(false);
      } catch (error) {}
    };

    getIndexPromo();
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    if (runCarrusel !== true) {
      setActiveStep(step);
    }
  };

  return (
    <div className={classes.root}>
      {showLoading ?             <div className="col-12 d-flex justify-content-center">
                    <CircularProgress color="secondary" size={30} />
                  </div> : 
      <div onMouseEnter={()=> setRunCarrusel(true)} onMouseLeave={()=> setRunCarrusel(false)}>
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          springConfig={{duration: '1.35s', easeFunction: 'cubic-bezier(0.15, 0.3, 0.25, 1)', delay:'0s'}}
          enableMouseEvents
        >
          {indexPromotions.map((promo: any, index) => (
            <div className="d-flex justify-content-center" key={promo.id}>
              {Math.abs(activeStep - index) <= 2 ? (
                <ItemHome data={promo} />
                // <PromItem data={promo}/>
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <MobileStepper
          steps={maxSteps}
          position="static"
          variant="dots"
          activeStep={activeStep}
          nextButton={
            <Button
              style={{ marginTop: "-28em", color: "#000" }}
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {/* Next */}
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              style={{ marginTop: "-28em", color: "#000" }}
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              {/* Back */}
            </Button>
          }
        />
      </div>}
    </div>
  );
};

export default HomeCorousel;
