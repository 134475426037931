import client from './client';


export const getCalculatorCards = () => {
    return client.get('/card');
}
 
const  getToken = () => {
    return localStorage.getItem('token')
}

export const setLikes = (id: number) => {
    return client.get(`/faq/like/${id}`, {
        headers: { Authorization: "Bearer " + getToken() }
    })
}

export const setDislikes = (id: number) => {
    return client.get(`/faq/dislike/${id}`, {
        headers: { Authorization: "Bearer " + getToken() }
    })
}

export const setTicket = (data:object) => {
    return client.post('/ticket', data ,{
        headers: { Authorization: "Bearer " + getToken() }
      });
}

export const setTicketAccess = (data:object) => {
    return client.post('/ticket/access', data );
}

export const getUser = () => {
    return client.get('/user/profile', {
        headers: { Authorization: "Bearer " + getToken() }
      });
}

export const patchUser = (patchUser:object) => {
    return client.patch('/user/profile',  patchUser, {
        headers: { Authorization: "Bearer " + getToken() }
      });
}

export const getAvatars = () => {
    return client.get('/avatar', {
        headers: { Authorization: "Bearer " + getToken() }
      });
}

export const getFaqCategories = () => {
    return client.get('/faq-category', {
        headers: { Authorization: "Bearer " + getToken() }
      });
}

export const getFaqs = (id:number) => {
    return client.get('/faq/' + id , {
        headers: { Authorization: "Bearer " + getToken() }
      });
}

export const searchFAQ = (text:string) => {
    return client.get('/faq/search/' + text, {
        headers: { Authorization: "Bearer " + getToken() }
      });
}

export const register = (data:object) => {
    return client.post('/user/register/', data);
}

export const login = (req:object) => {
    return client.post('/user/login/', req);
}

export const getPromCategories = () => {
    return client.get('/promotion-category');
}

export const getPromsByCategory = (id:number) => {
    return client.get('/promotion?categoryId='+ id.toString() + "&limit=50");
}

export const getPromDetail = (id:number) => {
    return client.get('/promotion/'+ id.toString());
}

export const searchProm = (toSerch:string) => {
    return client.get('promotion?title='+ toSerch);
}

export const getPromSegments = () => {
    return client.get('/promotion-segment');
}

export const getPromProducts = () => {
    return client.get('/promotion-product');
}

export const fiterProm = (filters:string) => {
    return client.get('/promotion?'+ filters + 'limit=1000');
}


export const indexProm = () => {
    return client.get('/promotion?orderBy=startsAt&limit=5&orderDirection=DESC');
}

export const requestResetPassword = (reset:object) => {
    return client.post('/user/request-reset-password', reset);
}

export const resetPassword = (reset:object) => {
    return client.post('/user/reset-password', reset);
}


export const changePassword = (change:object) => {
    return client.post('/user/reset-password-logged', change ,{
        headers: { Authorization: "Bearer " + getToken() }
      });
}

export const getFullOnBoardStats = () => {
    return client.get('/masterup/progress', {
        headers: { Authorization: "Bearer " + getToken() }
      });
}





