import React, {useEffect} from 'react';
import './FilterItem.scss';

const FilterItem = (props:any) => {
  
  const [state, setState] = React.useState(false);
  
  
  useEffect(()=>{
    setState(props.data.active)
  }, [props.data]);


  const changeState = () => {
    if (state === true) {
      setState(false)
      props.data.active = false;  
    }
    else{
      setState(true)
      props.data.active = true;
    }
    console.log('current state', state);
    const res = {
      add: !state,
      category: props.category,
      itemData: props.data,
    }

    props.setFilter(res); 
  }

  return (
  <div className="FilterItem" data-testid="FilterItem">
    {/* FilterItem Component */}
      <button onClick={changeState} className={ !state ? "filter-items d-flex align-items-center p-2" : "filter-item-selected d-flex align-items-center p-2"}>{props.data?.name}</button>
  </div>)
};

export default FilterItem;
