import React, { useEffect } from "react";
import "./SingUp.scss";
import HeaderOut from "../HeaderOut/HeaderOut";
import Footer from "../Footer/Footer";
import CloseIcon from "@material-ui/icons/Close";
import { useForm } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import { register as apiRegister } from "../../api";

import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CircularProgress from "@material-ui/core/CircularProgress";

import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import TarjetinThanks  from "../../assets/images/sign-up-thanks.png";


const SingUp = () => {
  const router = useHistory();
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  const [openAlert, setOpenAlert] = React.useState({
    show: false,
    label: "error",
  });

  const [success, setSuccess] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [showPass, setShowPass] = React.useState("password");

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      router.push("/home-page");
    }
  });

  const goWelcome = () => {
    router.push("/");
  };

  const onSubmit = async (
    data: any,
    e: any /*{
    idlogin: string;
    emailSingUp: string;
    passwordLogin: string;
  }*/
  ) => {
    setShowLoading(true);
    try {
      const req = {
        username: data.idlogin,
        email: data.emailSingUp,
        password: data.passwordLogin,
      };
      const setUser = await apiRegister(req);
      console.log("set user", setUser);
      setShowLoading(false);
      setSuccess(true);
      setOpenAlert({ show: false, label: "" });
      e.target.reset();
      // setTimeout(() => {
      //   router.push("/");
      // }, 5000);
    } catch (error: any) {
      console.log(error.response.data.message);
      setOpenAlert({ show: true, label: error.response.data.message });
      setShowLoading(false);
      setSuccess(false);
    }
  };

  const showPassword = () => {
    if (showPass === "password") {
      setShowPass("text");
    } else {
      setShowPass("password");
    }
  };

  return (
    <div className="SingUp" data-testid="SingUp">
      {/* SingUp Component */}
      <div className="static-container container-fluid">
        <HeaderOut />
        <div className="container-fluid sub-menu"></div>
      </div>

      <div className="container-fluid main-container ">
        <div className="container sub-container  d-flex align-items-stretch ">
          <div className=" col-12 d-flex justify-content-center  ">
            <div className="login-container">
              <div className="col-12 d-flex d-flex justify-content-end pb-2 px-3 pt-3">
                <CloseIcon onClick={goWelcome} style={{ cursor: "pointer" }} />
              </div>
              <div
                className="col-12 d-flex justify-content-center align-items-center p-3 "
                style={{ marginTop: "-10px" }}
              >
                {" "}
                {!success ? (
                  <div className=" col-12 col-md-9 text-left">
                    <h1>Registro </h1>
                    <div className="sub-text">
                      Ingresa tus datos para registrarte{" "}
                    </div>
                    <div className="form-container pt-4">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                          <div className="row pb-2">
                            <label className="col-4 col-md-7 label-form">
                              No. de empleado
                            </label>
                            <label className="col-8 col-md-5 label-alt">
                              ¿Cómo identifico mi número de empleado?
                            </label>
                          </div>
                          <input
                            {...register("idlogin", {
                              required: true,
                              // minLength: 7,
                              // maxLength: 7,
                              // pattern: { value: /^\d+$/, message: "bad code" },
                            })}
                            // maxLength={7}
                            style={{ textTransform: "uppercase" }}
                            type="text"
                            className="form-control input"
                            id="idlogin"
                            aria-describedby="emailHelp"
                            placeholder="N123456"
                          />
                          {String(errors.idlogin?.type) === "pattern" && (
                            <span className="input-error">
                              Solo números permitidos
                            </span>
                          )}
                          {String(errors.idlogin?.type) === "minLength" && (
                            <span className="input-error">
                              7 dígitos requeridos
                            </span>
                          )}
                          {/* <small id="emailHelp" className="form-text text-muted">
                        We'll never share your email with anyone else.
                      </small> */}
                        </div>
                        <div className="form-group pt-4">
                          <div className="row pb-2">
                            <label className="col-4  col-md-6 label-form">
                              Correo electrónico
                            </label>
                            {/* <label className="col-8 col-md-6 label-alt">
                          ¿Olvidaste tu contrseña?
                        </label> */}
                          </div>
                          <input
                            {...register("emailSingUp", {
                              required: true,
                              pattern: {
                                value:
                                  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                                message: "pattern message",
                              },
                            })}
                            type="email"
                            className="form-control input"
                            id="emailSingUp"
                            placeholder="usuario@mail.com"
                          />
                          {errors.emailSingUp &&
                            String(errors.emailSingUp.type) === "pattern" && (
                              <small className="input-error">
                                Correo electrónico no válido
                              </small>
                            )}
                        </div>
                        <div className="form-group pt-4">
                          <div className="row pb-2">
                            <label className="col-4  col-md-6 label-form">
                              Constraseña
                            </label>
                            <label className="col-8 col-md-6 label-alt">
                              {/* ¿Olvidaste tu contraseña? */}
                            </label>
                          </div>
                          <input
                            {...register("passwordLogin", {
                              required: true,
                              pattern: {
                                value:
                                  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                                message: "wrong psw",
                              },
                              // minLength: 7,
                              // maxLength: 7,
                            })}
                            // maxLength={7}
                            type={showPass}
                            className="form-control input"
                            id="passwordLogin"
                            placeholder="Ingresa contraseña"
                          />
                          <div
                            className="col-1"
                            style={{
                              position: "relative",
                              float: "right",
                              marginTop: "-30px",
                              paddingRight: "5px",
                            }}
                          >
                            {showPass === "password" ? (
                              <VisibilityOutlinedIcon onClick={showPassword} />
                            ) : (
                              <VisibilityOffOutlinedIcon
                                onClick={showPassword}
                              />
                            )}
                          </div>

                          {String(errors.passwordLogin?.type) === "minLength" && (
                            <span className="input-error">
                              7 dígitos requeridos
                            </span>
                          )}
                          {String(errors.passwordLogin?.type) === "pattern" && (
                            <div className="input-error-relative col-12 pt-1">
                              Al menos un caracter especial(%), un número.{" "}
                              <br /> Una mayuscula, una minuscula y al menos 8
                              caracteres son requeridos.
                              <br />
                            </div>
                          )}
                        </div>
                        <div className="tyc-container mt-3 pt-2">
                          <input
                            {...register("terms", { required: true })}
                            type="checkbox"
                            className="check-size"
                            id="terms"
                          />{" "}
                          &nbsp; He leído y acepto los{" "}
                          <button
                            style={{
                              textDecoration: "none",
                              backgroundColor: "#fff",
                              border: "none",
                            }}
                          > 

                              <u onClick={()=>{router.push('/terms-conditions')}}>Términos y Condiciones.</u>
                          </button>
                        </div>
                        <div className="text-center mt-2 pt-1">
                          <button
                            type="submit"
                            className="btn btn-primary col-md-6 col-10 mt-2"
                            // disabled={!isValid}
                            disabled={true}
                            style={{ height: "3.5em", fontSize: "14px" }}
                          >
                            Registrarme
                          </button>
                        </div>
                        {showLoading && (
                          <div className="pt-3 col-12 d-flex justify-content-center">
                            <CircularProgress color="secondary" size={20} />
                          </div>
                        )}
                        <div className="pt-2">
                          <Collapse in={openAlert.show}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setOpenAlert({
                                      show: false,
                                      label: "error",
                                    });
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              {openAlert.label}
                            </Alert>
                          </Collapse>
                        </div>
                        <div className="text-center mt-4">
                          {/* <div
                          className={
                            success ? "text-tyc-success mt-2" : "text-tyc mt-2"
                          }
                        >
                          {success && (
                            <div>
                              <b>¡Gracias por querer ser parte de nuestra
                              comunidad!</b>
                              <br />
                            </div> 
                          )}
                          
                          Se enviará un correo para validar y activar tu cuenta.
                          por favor revísalo.  En caso de no encontrarlo, busca
                          en la bandeja de "Correo no deseado" o en  "Spam".
                          
                        </div> */}
                        </div>
                      </form>
                    </div>
                  </div>
                ) : (
                  <div className="col-12 p-4 d-flex justify-content-center align-items-center">
                    <div className="p-2 text-center pt-4">
                      <h1 style={{fontSize: '1.5em', lineHeight: '1em', textAlign: 'center'}}>
                        ¡Gracias por querer ser parte de nuestra comunidad!
                      </h1>
                      <img src={TarjetinThanks} alt="thanks" className="col-10 pt-2" ></img>
                      <p className="pt-2">
                        Se enviará un correo para validar y activar tu cuenta.
                        por favor revísalo. En caso de no encontrarlo, busca en
                        la bandeja de "Correo no deseado" o en "Spam".
                      </p>

                      <Link to="/">
                        <button className="btn btn-primary col-md-4 col-12 mt-4">
                          Entendido
                        </button>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <Footer />
      </div>
    </div>
  );
};

export default SingUp;
