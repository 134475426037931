import React, { useEffect } from "react";
import "./SubHeader.scss";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/Home";
import StarIcon from "@material-ui/icons/Star";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import { useHistory, useLocation } from "react-router";
import { Box, Typography } from "@material-ui/core";

import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import imgMenu from "../../assets/images/logo-mobile-menu.png";
import {getFullOnBoardStats} from '../../api/';

import {
  HomeOutlined,
  ImportContacts,
  SystemUpdateAlt,
  AccountBalance,
  VideogameAsset,
  DescriptionOutlined,
  BarChart,
} from "@material-ui/icons";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  nested: {
    // paddingLeft: theme.spacing(4),
  },
});

type Anchor = "top" | "left" | "bottom" | "right";

const SubHeader = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = React.useState({
    top: true,
    left: false,
    bottom: false,
    right: false,
  });

  const location = useLocation();
  var qs = require("qs");

  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState("");
  const [foStats,  setFoStats] = React.useState({progress: "", challengeActive: "", availableChallenges: "", totalChallenges: "", finished: "", inProgress: "", pending: ""});

  useEffect(() => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (queryParams.page) {
      setPage(queryParams.page);
    }

    const getStats = async () => {
      try {
        const response = await getFullOnBoardStats();
        console.log("response", response);
        console.log("response", response.data["Cupon 1"]);

        let finished = 0;
        let inProgress = 0;

        for (let i = 0; i < response.data.courses.length; i++) {

          if (response.data.courses[i] === "100%") {
            finished++;
          }

          const courseNumber = String(response.data.courses[i]).replace("%", "");

          if (Number(courseNumber) > 0 && Number(courseNumber) < 100) {
            inProgress++;
          }
        }

       
        const resFO = {
          progress: String(response.data.generalProgress).replace("%", ""),
          challengeActive: String(response.data.challenges.completed),
          totalChallenges: String(response.data.challenges.total),
          availableChallenges: String(Number(response.data.challenges.total) - Number(response.data.challenges.completed)),
          finished: String(finished),
          inProgress: String(inProgress),
          pending: String(response.data.courses.length - finished),
        }
        setFoStats(resFO);
        console.log("resFO", resFO);
      } catch (error) {
        console.log("error", error)  
      }
    }


    getStats()

  }, []);

  const toggleDrawer =
    (anchor: Anchor, openLocal: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      if (openLocal && page !== "") {
        setOpen(true);
      }

      setState({ ...state, [anchor]: openLocal });
    };

  const theme = createTheme({
    typography: {
      fontFamily: "santanderText",
    },
  });

  const backPage = () => {
    history.push("/home-page");
  };

  const setFOMenuItem = (page: string) => {
    props.setFOMenu(page);
    setPage(page);
    setState({ ...state, left: false });
    history.push("/full-on-board?page=" + page);

    //  if (page === "/full-on-board") {

    //  }
    //  else {
    // history.push('/full-on-board?page='+page);
    //  }
  };

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      style={{ width: "350px", overflowX: "hidden" }}
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="nav-cont ">
        <div className="masth-container " style={{paddingRight: '12px', paddingTop: '12px'}}>
        <div className="col-12 d-flex justify-content-end">
          <CloseIcon onClick={toggleDrawer("left", false)} /> 
        </div>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-4  d-flex justify-content-center ">
              <div>
                <img src={imgMenu} alt="menu" className="menu-img" />
                <div className="h-red-sqare"></div>
              </div>
            </div>
            <div className="col-8" style={{ padding: 0 }}>
              <div className="text-masth">CENTRO DE</div>
              <div className="text-masth-main">MEDIOS DE PAGO</div>
            </div>
          </div>
        </div>
        <List component="nav">
          <ThemeProvider theme={theme}>
            <ListItem
              style={{
                borderBottom: "1px solid #e6e6e6",
                marginLeft: "10px",
                width: "100%",
              }}
              button
              onClick={() => {
                history.push("/home-page");
                toggleDrawer(anchor, false);
              }}
            >
              {props.path === "/home-page" && (
                <div className="v-red-sqare"></div>
              )}
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    className={
                      props.path === "/home-page"
                        ? "item-menu-drawer active-drawer"
                        : "item-menu-drawer"
                    }
                  >
                    INICIO
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              button
              style={{
                borderBottom: "1px solid #e6e6e6",
                marginLeft: "10px",
                width: "90%",
              }}
              onClick={() => {
                history.push("/promotions");
                toggleDrawer(anchor, false);
              }}
            >
              {props.path === "/promotions" && (
                <div className="v-red-sqare"></div>
              )}
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    className={
                      props.path === "/promotions"
                        ? "item-menu-drawer active-drawer"
                        : "item-menu-drawer"
                    }
                  >
                    PROMOCIONES
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                history.push("/faq");
                toggleDrawer(anchor, false);
              }}
              style={{
                borderBottom: "1px solid #e6e6e6",
                marginLeft: "10px",
                width: "90%",
              }}
            >
              {props.path === "/faq" && <div className="v-red-sqare"></div>}
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    className={
                      props.path === "/faq"
                        ? "item-menu-drawer active-drawer"
                        : "item-menu-drawer"
                    }
                  >
                    {/* Preguntas frecuentes */}
                    PREGUNTAS FRECUENTES
                  </Typography>
                }
              />
            </ListItem>
            {/* { props.path !== "/full-on-board" ?
        <ListItem button onClick={() => {
              history.push("/full-on-board");
            }}>
          <ListItemIcon>
            <LocalLibraryIcon className={props.path === "/full-on-board" ? "active-drawer" : ""} />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body2" className={props.path === "/full-on-board" ? "active-drawer" : ""}>Full Onboard </Typography>}
          />
        </ListItem>
          :
        <ListItem button onClick={() => {
             setOpen(!open);
            }} >
          <ListItemIcon>
            <LocalLibraryIcon className={props.path === "/full-on-board" ? "active-drawer" : ""} />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body2" className={props.path === "/full-on-board" ? "active-drawer" : ""}>Full Onboard </Typography>}
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem> } */}

            <ListItem
              button
              onClick={() => {
                setOpen(!open);
              }}
              style={{
                borderBottom: "1px solid #e6e6e6",
                marginLeft: "10px",
                width: "90%",
              }}
            >
              {props.path === "/full-on-board" && (
                <div className="v-red-sqare"></div>
              )}
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    className={
                      props.path === "/full-on-board"
                        ? "item-menu-drawer active-drawer"
                        : "item-menu-drawer"
                    }
                  >
                    FULL ONBOARD{" "}
                    <span
                      onClick={toggleDrawer("right", true)}
                      style={{
                        color: "red",
                        fontSize: "0.8em",
                        marginLeft: "15px",
                      }}
                    >
                      {" "}
                      Ver mi avance
                    </span>
                  </Typography>
                }
              />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          </ThemeProvider>
          {/* { props.path === "/full-on-board" && */}
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() => setFOMenuItem("home")}
                style={{
                  paddingLeft: "2em",
                  borderBottom: "1px solid #e6e6e6",
                  marginLeft: "10px",
                  width: "90%",
                }}
              >
                {page === "home" && <div className="v-red-sqare"></div>}
                <ListItemText
                  primary={
                    <Typography
                      variant="body2"
                      className={
                        page === "home"
                          ? "item-menu-drawer active-drawer"
                          : "item-menu-drawer"
                      }
                    >
                      Inicio{" "}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() => setFOMenuItem("certificates")}
                style={{
                  paddingLeft: "2em",
                  borderBottom: "1px solid #e6e6e6",
                  marginLeft: "10px",
                  width: "90%",
                }}
              >
                {page === "certificates" && <div className="v-red-sqare"></div>}
                <ListItemText
                  primary={
                    <Typography
                      variant="body2"
                      className={
                        page === "certificates"
                          ? "item-menu-drawer active-drawer"
                          : "item-menu-drawer"
                      }
                    >
                      Certificaciones{" "}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() => setFOMenuItem("downloads")}
                style={{
                  paddingLeft: "2em",
                  borderBottom: "1px solid #e6e6e6",
                  marginLeft: "10px",
                  width: "90%",
                }}
              >
                {page === "downloads" && <div className="v-red-sqare"></div>}
                {/* <ListItemIcon>
                  <SystemUpdateAlt />
                </ListItemIcon> */}
                <ListItemText
                  primary={
                    <Typography
                      variant="body2"
                      className={
                        page === "downloads"
                          ? "item-menu-drawer active-drawer"
                          : "item-menu-drawer"
                      }
                    >
                      Descargas{" "}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() => setFOMenuItem("digital-certificates")}
                className={classes.nested}
                style={{
                  paddingLeft: "2em",
                  borderBottom: "1px solid #e6e6e6",
                  marginLeft: "10px",
                  width: "90%",
                }}
              >
                {page === "digital-certificates" && (
                  <div className="v-red-sqare"></div>
                )}
                <ListItemText
                  primary={
                    <Typography
                      variant="body2"
                      className={
                        page === "digital-certificates"
                          ? "item-menu-drawer active-drawer"
                          : "item-menu-drawer"
                      }
                    >
                      Certificados digitales{" "}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() => setFOMenuItem("challenges")}
                className={classes.nested}
                style={{
                  paddingLeft: "2em",
                  borderBottom: "1px solid #e6e6e6",
                  marginLeft: "10px",
                  width: "90%",
                }}
              >
                {page === "challenges" && <div className="v-red-sqare"></div>}
                <ListItemText
                  primary={
                    <Typography
                      variant="body2"
                      className={
                        page === "challenges"
                          ? "item-menu-drawer active-drawer"
                          : "item-menu-drawer"
                      }
                    >
                      Mis retos{" "}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() => setFOMenuItem("terms-and-conditions")}
                className={classes.nested}
                style={{
                  paddingLeft: "2em",
                  borderBottom: "1px solid #e6e6e6",
                  marginLeft: "10px",
                  width: "90%",
                }}
              >
                {page === "terms-and-conditions" && (
                  <div className="v-red-sqare"></div>
                )}
                <ListItemText
                  primary={
                    <Typography
                      variant="body2"
                      className={
                        page === "terms-and-conditions"
                          ? "item-menu-drawer active-drawer"
                          : "item-menu-drawer"
                      }
                    >
                      Términos y condiciones{" "}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Collapse>
          <ListItem
            button
            onClick={() => {
              history.push("/calculator");
              toggleDrawer(anchor, false);
            }}
            style={{
              borderBottom: "1px solid #e6e6e6",
              marginLeft: "10px",
              width: "90%",
            }}
          >
            {props.path === "/calculator" && (
              <div className="v-red-sqare"></div>
            )}
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  className={
                    props.path === "/calculator"
                      ? "item-menu-drawer active-drawer"
                      : "item-menu-drawer"
                  }
                >
                  CALCULADORA
                </Typography>
              }
            />
          </ListItem>

          <ListItem
            button
            onClick={() => {
              history.push("/profile");
              toggleDrawer(anchor, false);
            }}
            style={{ marginLeft: "10px", width: "90%", marginTop: "2em" }}
          >
            {props.path === "/profile" && <div className="v-red-sqare"></div>}
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  className={
                    props.path === "/profile"
                      ? "item-menu-drawer active-drawer"
                      : "item-menu-drawer"
                  }
                >
                  MI PERFIL
                </Typography>
              }
            />
          </ListItem>
        </List>
      </div>
    </div>
  );

  return (
    <div className="SubHeader container-fluid sub-menu">
      <div className=" container" style={{ paddingTop: "18px" }}>
        {props.path !== "/home-page" ? (
          <span
            className="container-back-btn"
            onClick={backPage}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <span>
              {" "}
              <b>
                <ArrowBackIosIcon
                  style={{ color: "#FF000A", marginTop: "-2px" }}
                  fontSize="small"
                />{" "}
              </b>{" "}
            </span>{" "}
            <span className="back-btn">Regresar al inicio</span>{" "}
          </span>
        ) : (
          <span className="container-back-btn">
            {" "}
            <span> </span>{" "}
          </span>
        )}
      </div>
      <div className="hamburguer-btn ">
        <Box display={{ xs: "block", sm: "block", md: "none", lg: "none" }}>
          <IconButton
            onClick={toggleDrawer("left", true)}
            edge="start"
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
        </Box>
      </div>
      <div>
        <React.Fragment key="left">
          <Drawer
            anchor="left"
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
          >
            {list("left")}
          </Drawer>
        </React.Fragment>
        <React.Fragment key="left">
          <Drawer
            anchor="left"
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
          >
            <div className="stats-drawer-container">
              <div className="stats-drawer-header col-12 d-flex justify-content-end p-2">
                <CloseIcon onClick={toggleDrawer("right", false)} />
              </div>
              <div className=" col-12 stats-container p-3">
                <h1 style={{ fontSize: "1.5em", marginTop: '-1em' }}>Full OnBoard</h1>
                <p className="stat-text">
                  ¡Sigue desarrollando tu conocimiento! Te invitamos a que
                  continúes con tu entrenamiento a través de los materiales de
                  e-learning. ¡Estás a un solo click!
                </p>
                <div className="title-stats pt-2">Mis retos</div>
                <div className="col-12">
                  <span className="chanllenge-result">
                  {foStats.finished } {/*1*/}<span className="chanllenge-missing">/ {foStats.totalChallenges} {/*4*/}</span>
                    <span className="chanllenge-label">activo</span>
                  </span>
                </div>
                <div className="col-12">
                  <div className="alert-challenges p-2 mt-2 col-12 d-flex justify-content-start align-items-center">
                    <div className="challenge-alert-label">
                      Tienes {' ' + foStats.availableChallenges + ' '} retos disponbiles para inscribirte
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    <div className="black-divisor col-12 "></div>
                    <div className="title-stats">Mi avance general</div>
                    <span className="chanllenge-result">
                      {/* %15 */}
                      %{foStats.progress}
                      <span className="chanllenge-missing">/ 100</span>
                    </span>
                    <div className="black-divisor col-12"></div>
                  </div>
                  <div className="col-12">
                  <div className="title-stats">Mis certificaciones</div>
                    <div className="stat-item d-flex justify-content-between pt-2 ">
                      <div>En progreso</div><div>{foStats.inProgress}</div>
                    </div>
                    <div className="stat-item d-flex justify-content-between pt-2 ">
                      <div>Finalizados</div><div>{foStats.finished}</div>
                    </div>
                    <div className="stat-item d-flex justify-content-between pt-2 ">
                      <div>Pendientes</div><div>{foStats.pending}</div>
                    </div>
                  </div>
                  <div className="col-12 pt-4 d-flex justify-content-center">
                  <button
                    className="btn btn-primary col-6 btn-goFull"
                    onClick={() => { history.push("/full-on-board") }}
                  >
                    Ir a Full OnBoard
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>
        </React.Fragment>
      </div>
    </div>
  );
};

export default SubHeader;
