import React, { useEffect } from "react";
import "./Calculator.scss";
import Header from "../Header/Header";
import SubHeader from "../SubHeader/SubHeader";
import NeedHelpNoLogin from "../NeedHelpNoLogin/NeedHelpNoLogin";
import { useHistory } from "react-router-dom";
import {
  createStyles,
  makeStyles,
  Theme,
  ThemeProvider,
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import CardImage from "../../assets/images/card-calculator.png";
// import RewardProgramImage from "../../assets/images/rewards-program.png";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
// import DetailCard from "../../assets/images/card-detail.png";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import { getCalculatorCards } from "../../api";
import NumberFormat from "react-number-format";
import PrintIcon from "@material-ui/icons/Print";

import Footer from "../Footer/Footer";
import NeedHelp from "../NeedHelp/NeedHelp";
import NoLoyalty from "../../assets/images/not-loyalty-program.png";
import NoCardImage  from "../../assets/images/tarjeta-neutral.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      // minWidth: 100%'',
      width: "95%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    select: {
      "&:before": {
        borderColor: "#407FA0",
      },
      "&:after": {
        borderColor: "red",
      },
      "&:not(.Mui-disabled):hover::before": {
        borderColor: "red",
      },
    },
    inputRoot: {
      width: "70%",
      backgroundColor: "#f7fafc",
    },
    input: {
      color: "#000",
    },
    inputExpense: {
      width: "100%",
      backgroundColor: "#f7fafc",
    },
    inputChange: {
      width: "40%",
      backgroundColor: "#f7fafc",
    },
  })
);

const GreenCheckbox = withStyles({
  root: {
    color: "#000",
    "&$checked": {
      color: "red",
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const theme = createTheme({
  palette: {
    primary: {
      main: "#407FA0",
    },
  },
});

interface NumberFormatCustomProps {
  inputRef: (instance: any | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      decimalScale={2}
      fixedDecimalScale={true}
      // prefix="$"
    />
  );
}

interface Card {
  id: number;
  name: string;
  minimumIncome: number;
  maximumInterestRate: string;
  cat: string;
  annualFee: number;
  pointsPerUsd: number;
  mxnPointValue: number;
  externalAssets: [];
  benefits: string;
  wellcomeBonus: number;
  wellcomeBonusInfo: string;
  createdAt: string;
  updatedAt: string;
  image: string;
  loyaltyProgram: string;
}

interface StateValues {
  totalAmount: string;
  home: string;
  transporting: string;
  groceries: string;
  telephony: string;
  entertainment: string;
  rent: string;
  otherMonth: string;
  localTaxes: string;
  carfee: string;
  insurance: string;
  vacations: string;
  membership: string;
  college: string;
  otherYear: string;
  exchangeRate: string;
}

const Calculator = () => {
  const classes = useStyles();
  const [currentCard, setCurrentCard] = React.useState("Selecciona una TDC");
  const [currentCardData, setCurrentCardData] = React.useState<Card>();
  const [showExpenses, setShowExpenses] = React.useState(false);
  const [showCalculate, setShowCalculate] = React.useState(false);
  const [cardSelected, setCardSelected] = React.useState(false);
  const [cards, setCards] = React.useState<Card[]>([]);
  const [enabledCalculate, setEnabledCalculate] = React.useState(false);
  const [totalAmount, setTotalAmount] = React.useState("");
  const [values, setValues] = React.useState<any>({
    totalAmount: "",
    home: "",
    transporting: "",
    groceries: "",
    telephony: "",
    entertainment: "",
    rent: "",
    otherMonth: "",
    localTaxes: "",
    carfee: "",
    insurance: "",
    vacations: "",
    membership: "",
    college: "",
    otherYear: "",
    exchangeRate: "",
  });

  const [calculatedValues, setCalculatedValues] = React.useState({
    annualPoints: 0,
    additionalCardPoints: 0,
    valueOfPoints: 0,
    annualExpenses: 0,
    approximatePoints: 0,
    totalPoints: 0,
  });

  const [state, setState] = React.useState({
    checkedG: false,
  });

  const monthlyExpenses = [
    { id: "home", label: "Hogar", value: "" },
    { id: "transporting", label: "Transporte", value: "" },
    { id: "groceries", label: "Supermercado", value: "" },
    { id: "telephony", label: "Telefonía", value: "" },
    { id: "entertainment", label: "Entretenimiento", value: "" },
    { id: "rent", label: "Domiciliaciones", value: "" },
    { id: "otherMonth", label: "Otros", value: "" },
  ];

  const yearlyExpenses = [
    { id: "localTaxes", label: "Predial" },
    { id: "carfee", label: "Tenencia" },
    { id: "insurance", label: "Seguros" },
    { id: "vacations", label: "Vacaciones" },
    { id: "membership", label: "Membresías" },
    { id: "college", label: "Colegiaturas" },
    { id: "otherYear", label: "Otros" },
  ];

  const history = useHistory();

  useEffect(() => {
    const getCards = async () => {
      try {
        const reqCards = await getCalculatorCards();
        console.log("cards", reqCards);
        setCards(reqCards.data);
      } catch (error) {
        console.log(error);
      }
    };

    getCards();
  }, []);

  // const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   setCurrentCard(event.target.value as string);
  //   setCardSelected(true);
  // };

  const handleChangeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    console.log("event", event.target.value);
    setCurrentCard(event.target.value as string);

    cards.forEach((card) => {
      if (card.name === (event.target.value as string)) {
        card.externalAssets.forEach((asset: any) => {
          if (!String(asset.url).includes("http")) {
            asset.url = "http://" + asset.url;
          }
        });

        setCurrentCardData(card);
        console.log("currentCardId", card);
        setShowCalculate(false);
      }
    });
    if (event.target.value === "Selecciona una TDC") {
      setCardSelected(false);
    } else {
      setCardSelected(true);
    }
  };

  // const handleChangeInputCheckbox = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };

  const handleChangeInput =
    (prop: keyof StateValues) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });

      if (prop === "totalAmount") {
        setTotalAmount(event.target.value as string);
      }
      // console.log("values", values);
    };

  useEffect(() => {
    setEnabledCalculate(false);
    let tempMonthly = 0;
    let tempYearly = 0;

    if (
      values.totalAmount !== "" &&
      values.totalAmount !== "0.00" &&
      values.exchangeRate !== "" &&
      values.exchangeRate !== "0.00" &&
      values.exchangeRate !== undefined &&
      values.totalAmount !== undefined
    ) {
      setEnabledCalculate(true);
    }

    monthlyExpenses.forEach((element: any) => {
      if (values[element.id] !== "" && values[element.id] !== "0") {
        tempMonthly = tempMonthly + Number(values[element.id]) * 12;
      }
    });

    yearlyExpenses.forEach((element: any) => {
      if (values[element.id] !== "" && values[element.id] !== "0") {
        tempYearly = tempYearly + Number(values[element.id]);
      }
    });

    if (showExpenses) {
      calculateExpenses(tempMonthly || 0, tempYearly || 0);
    }
  }, [values, showExpenses]);

  const calculateExpenses = (monthly: number, yearly: number) => {
    let yearlyTaxes = 0;
    yearlyTaxes = monthly + yearly;
    console.log("yearlyTaxes", yearlyTaxes, monthly, yearly);
    // setTotalAmount(yearlyTaxes.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').toString());
    setTotalAmount(yearlyTaxes.toString());
    // setValues(tempValue);
  };

  const handleCalculate = () => {
    console.log("anualExpenses", totalAmount);
    console.log("exChangeRate", values.exchangeRate);
    const annualExpenses = Number(totalAmount);

    const byDefine = 0;

    // Gatos anuales en dolares
    const expensesInUsd = annualExpenses / Number(values.exchangeRate);
    console.log("expensesInUsd", expensesInUsd);

    console.log("currentData", currentCardData);
    const currentCard = { ...currentCardData };

    // Puntos ganados por estos gastos
    const annualPoints = expensesInUsd * Number(currentCard.pointsPerUsd);
    console.log("annualPoints", annualPoints);

    // TODO: Definir el valor de cada punto en pesos
    const mxnValueOfPoint = Number(currentCard.mxnPointValue); //0.08;

    // TODO: Calcular Puntos adicionales
    const additionalCardPoints = 0;

    // Suma total de puntos con bono de bienvenida en pesos menos anualidad
    // const valueOfPoints =
    //   (annualPoints +
    //     Number(currentCard.wellcomeBonus) +
    //     additionalCardPoints) *
    //     mxnValueOfPoint -
    //   Number(currentCard.annualFee);

    const approximatePoints =
      (Number(currentCard.wellcomeBonus) + annualPoints) * mxnValueOfPoint;

    const valueOfPoints = Number(
      approximatePoints - Number(currentCard.annualFee)
    );

    const tempCalValue = {
      annualPoints: Number(annualPoints.toFixed(2)),
      additionalCardPoints: Number(additionalCardPoints.toFixed(2)),
      valueOfPoints: Number(valueOfPoints.toFixed(2)),
      annualExpenses: Number(annualExpenses.toFixed(2)),
      approximatePoints: Number(approximatePoints.toFixed(2)),
      totalPoints: Number(Number(annualPoints - byDefine).toFixed(2)),
    };

    setCalculatedValues(tempCalValue);

    console.log("tempCalValue", tempCalValue);

    setShowCalculate(true);
  };

  // const handleNewCalculate = () => {
  //   console.log("anualExpenses", totalAmount);
  //   console.log("exChangeRate", values.exchangeRate);
  //   const annualExpenses = Number(totalAmount);

  //   const byDefine = 0;

  //   // Gatos anuales en dolares
  //   const expensesInUsd = annualExpenses / Number(values.exchangeRate);
  //   console.log("expensesInUsd", expensesInUsd);

  //   console.log("currentData", currentCardData);
  //   const currentCard = { ...currentCardData };

  //   // Puntos ganados por estos gastos
  //   const annualPoints = expensesInUsd * Number(currentCard.pointsPerUsd);
  //   console.log("annualPoints", annualPoints);

  //   // TODO: Definir el valor de cada punto en pesos
  //   const mxnValueOfPoint = 0.37;

  //   // TODO: Calcular Puntos adicionales
  //   const additionalCardPoints = 0;

  //   // Suma total de puntos con bono de bienvenida en pesos menos anualidad
  //   const valueOfPoints =
  //     (annualPoints +
  //       Number(currentCard.wellcomeBonus) +
  //       additionalCardPoints) *
  //       mxnValueOfPoint -
  //     Number(currentCard.annualFee);

  //   const approximatePoints =
  //     (Number(currentCard.wellcomeBonus) + annualPoints + byDefine) *
  //     mxnValueOfPoint;

  //   // const valueOfPoints = Number(approximatePoints - Number(currentCard.annualFee));

  //   const tempCalValue = {
  //     annualPoints: Number(annualPoints.toFixed(2)),
  //     additionalCardPoints: Number(additionalCardPoints.toFixed(2)),
  //     valueOfPoints: Number(valueOfPoints.toFixed(2)),
  //     annualExpenses: Number(annualExpenses.toFixed(2)),
  //     approximatePoints: Number(approximatePoints.toFixed(2)),
  //     totalPoints: Number(Number(annualPoints - byDefine).toFixed(2)),
  //   };

  //   setCalculatedValues(tempCalValue);

  //   console.log("tempCalValue", tempCalValue);

  //   setShowCalculate(true);
  // };

  const clearInputs = () => {
    setValues({
      totalAmount: "",
      home: "",
      transporting: "",
      groceries: "",
      telephony: "",
      entertainment: "",
      rent: "",
      otherMonth: "",
      localTaxes: "",
      carfee: "",
      insurance: "",
      vacations: "",
      membership: "",
      college: "",
      otherYear: "",
      exchangeRate: "",
    });
    setTotalAmount("");
    // setEnabledCalculate(false);
    setCardSelected(false);
    // setShowCalculate(false);
    setCurrentCard("Selecciona una TDC");
    setShowExpenses(false);
  };

  function numberWithCommas(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const printContent = () => {
    // const print = document.getElementById("print") || document.createElement("div");

    window.print();
  };

  const setFOPage = (item: object) => {
    history.push("/full-on-board?page=" + item);
  };
  return (
    <div className="Calculator" data-testid="Calculator">
      {/* Calculator Component */}
      <div className="static-container container-fluid">
        <Header />
        <SubHeader path="/calculator" setFOMenu={setFOPage} />
      </div>

      <div className="container-fluid main-container">
        <div className="container">
          <div className="sub-container">
            <div
              className="row "
              style={{ /*height: "1000px"*/ height: "auto" }}
            >
              <div className=" col-md-4 col-12 left-container  ">
                <div className="col-12">
                  <div className="red-line" />
                </div>
                <h1 className="pt-4">Calculadora de puntos</h1>
                <p className="sub-title-section col-10 col-md-10">
                  Selecciona una TDC Santander para tu cliente y descubre cuanto
                  puede ahorrar anualmente
                </p>
                <FormControl className={classes.formControl}>
                  <ThemeProvider theme={theme}>
                    <Select
                      value={currentCard}
                      onChange={handleChangeSelect}
                      displayEmpty
                      className={classes.select}
                      renderValue={(value: any) => (
                        <div className="text-item-calculator">{value}</div>
                      )}
                    >
                      <MenuItem value="Selecciona una TDC">
                        <em>Selecciona una TDC</em>
                      </MenuItem>
                      {cards.map((card: Card) => {
                        return (
                          <MenuItem key={card.id} value={card.name}>
                            <div className="select-item-calculator">
                              <div className="row">
                                <div className="col-10 d-flex  align-items-center">
                                  <div>
                                    <span>
                                      <b>
                                        <ChevronRightIcon
                                          style={{
                                            color: "#FF000A",
                                            marginTop: "-2px",
                                          }}
                                        />
                                      </b>
                                    </span>
                                    <span className="text-item-calculator ">
                                      {card.name}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="col-2 d-flex align-items-center justify-content-center"
                                  style={{ height: "64px" }}
                                >
                                  <img
                                    style={{ height: "auto", width: "70%" }}
                                    src={card.image ? card.image : NoCardImage}
                                    alt="test"
                                  />
                                </div>
                              </div>
                            </div>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </ThemeProvider>
                </FormControl>
                {/* 
              <br />
              <FormControl className={classes.formControl}>
                <ThemeProvider theme={theme}>
                  <Select
                    value={currentCard}
                    onChange={handleChange}
                    displayEmpty
                    className={classes.select}
                    renderValue={(value: any) => (
                      <div className="text-item-calculator">{value}</div>
                    )}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="Selecciona una TDC">
                      <em>Selecciona una TDC</em>
                    </MenuItem>
                    <MenuItem value="Black Unlimited Mastercard">
                      <div className="select-item-calculator">
                        <div className="row">
                          <div className="col-10 d-flex  align-items-center">
                            <div>
                              <span>
                                <b>
                                  <ChevronRightIcon
                                    style={{
                                      color: "#FF000A",
                                      marginTop: "-2px",
                                    }}
                                  />
                                </b>
                              </span>
                              <span className="text-item-calculator ">
                                Black Unlimited Mastercard
                              </span>
                            </div>
                          </div>
                          <div className="col-2">
                            <img src={CardImage} alt="test" />
                          </div>
                        </div>
                      </div>
                    </MenuItem>
                    <MenuItem value="Fiesta Rewards Clasica Mastercard">
                      <div className="select-item-calculator ">
                        <div className="row">
                          <div className="col-10 d-flex  align-items-center">
                            <div>
                              <span>
                                <b>
                                  <ChevronRightIcon
                                    style={{
                                      color: "#FF000A",
                                      marginTop: "-2px",
                                    }}
                                  />
                                </b>
                              </span>
                              <span className="text-item-calculator ">
                                Fiesta Rewards Clasica Mastercard
                              </span>
                            </div>
                          </div>
                          <div className="col-2">
                            <img src={CardImage} alt="test" />
                          </div>
                        </div>
                      </div>
                    </MenuItem>
                    <MenuItem value="Fiesta Rewards Oro Mastercard">
                      <div className="select-item-calculator">
                        <div className="row">
                          <div className="col-10  d-flex align-items-center">
                            <div>
                              <span>
                                <b>
                                  <ChevronRightIcon
                                    style={{
                                      color: "#FF000A",
                                      marginTop: "-2px",
                                    }}
                                  />
                                </b>
                              </span>
                              <span className="text-item-calculator ">
                                Fiesta Rewards Oro Mastercard
                              </span>
                            </div>
                          </div>
                          <div className="col-2">
                            <img src={CardImage} alt="test" />
                          </div>
                        </div>
                      </div>
                    </MenuItem>
                    
                  </Select>
                </ThemeProvider>
              </FormControl> */}
                {/* <div>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={state.checkedG}
                        onChange={handleChangeInputCheckbox}
                        name="checkedG"
                      />
                    }
                    label={
                      <div className="checkbox-text">
                        El cliente desea una tarjeta de crédito adicional
                      </div>
                    }
                  />
                </div> */}
                <div className="pt-4">
                  <h2>Gastos</h2>
                  <div className="red-line" />
                </div>
                <div className="mt-4">
                  <TextField
                    // type="number"
                    name="totalAmount"
                    value={totalAmount}
                    onChange={handleChangeInput("totalAmount")}
                    className={classes.inputRoot}
                    disabled={!cardSelected}
                    InputProps={{
                      classes: {
                        input: classes.input,
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          {" "}
                          <AttachMoneyIcon style={{ fontSize: "1.2em" }} />
                        </InputAdornment>
                      ),
                      inputComponent: NumberFormatCustom as any,
                    }}
                    id="anualBill"
                    label={
                      <div className="input-label-calc">Facturación Anual</div>
                    }
                  />
                </div>
                <div className="mt-4">
                  <button
                    disabled={!cardSelected}
                    onClick={() => {
                      showExpenses
                        ? setShowExpenses(false)
                        : setShowExpenses(true);
                    }}
                    className="expenses-button"
                  >
                    Desglozar gastos
                    <span>
                      {" "}
                      {showExpenses ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </span>
                  </button>
                  {showExpenses ? (
                    <div className="expenses-container mt-2 p-2">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <span className="detail-title">Gastos mensuales</span>
                          {monthlyExpenses.map((item: any, index: number) => {
                            return (
                              <div key={item.id} className="p-1">
                                <TextField
                                  id="formatted-numberformat-input"
                                  name={item.id}
                                  value={values[item.id]}
                                  onChange={handleChangeInput(item.id)}
                                  className={classes.inputExpense}
                                  InputProps={{
                                    classes: {
                                      input: classes.input,
                                    },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {" "}
                                        <AttachMoneyIcon
                                          style={{ fontSize: "1.2em" }}
                                        />
                                      </InputAdornment>
                                    ),
                                    inputComponent: NumberFormatCustom as any,
                                  }}
                                  // id={item.id}
                                  label={
                                    <div className="input-label-calc">
                                      {item.label}
                                    </div>
                                  }
                                />
                              </div>
                            );
                          })}
                        </div>
                        <div className="col-12 col-md-6">
                          <span className="detail-title">Gastos anuales</span>
                          {yearlyExpenses.map((item: any, index: number) => {
                            return (
                              <div key={item.id} className="p-1">
                                <TextField
                                  // type="number"
                                  value={values[item.id]}
                                  onChange={handleChangeInput(item.id)}
                                  className={classes.inputExpense}
                                  InputProps={{
                                    classes: {
                                      input: classes.input,
                                    },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {" "}
                                        <AttachMoneyIcon
                                          style={{ fontSize: "1.2em" }}
                                        />
                                      </InputAdornment>
                                    ),
                                    inputComponent: NumberFormatCustom as any,
                                  }}
                                  id={item.id}
                                  label={
                                    <div className="input-label-calc">
                                      {item.label}
                                    </div>
                                  }
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <hr />
                  )}
                </div>
                <div className="mt-2 p-2">
                  <TextField
                    // type="number"
                    name="exchangeRate"
                    value={values.exchangeRate}
                    onChange={handleChangeInput("exchangeRate")}
                    className={classes.inputChange}
                    disabled={!cardSelected}
                    InputProps={{
                      classes: {
                        input: classes.input,
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          {" "}
                          <AttachMoneyIcon style={{ fontSize: "1.2em" }} />
                        </InputAdornment>
                      ),
                      inputComponent: NumberFormatCustom as any,
                    }}
                    id="exchangeRate"
                    label={
                      <div className="input-label-calc">Tipo de cambio</div>
                    }
                  />
                </div>
                <div className="mt-3">
                  <div className="row">
                    <div className="col-6">
                      <button
                        disabled={!enabledCalculate}
                        onClick={handleCalculate}
                        className="btn btn-primary col-md-10 col-10 mt-2"
                      >
                        Calcular
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        // onClick={() => {setCardSelected(false); setCurrentCard('Selecciona una TDC')}}
                        onClick={clearInputs}
                        className="btn btn-secondary col-md-10 col-10 mt-2"
                      >
                        Limpiar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-md-8 col-12">
                {!cardSelected ? (
                  <div className="no-card-selected">
                    <h1>Programa de lealtad</h1>
                    <p className="sub-title-section">
                      Ingresa los gastos de tu cliente para calcular los puntos
                      en su programa de lealtad.{" "}
                    </p>
                  </div>
                ) : (
                  <div id="print" className="card-info-container pt-4">
                    <div className="row ">
                      <div className="col-11">
                        <h1 style={{ fontSize: "1.8em" }}>
                          {currentCardData?.name}
                        </h1>
                      </div>
                      <div className="col-1 pt-2">
                        <PrintIcon
                          fontSize="small"
                          style={{ cursor: "pointer" }}
                          onClick={printContent}
                        />
                      </div>
                    </div>
                    <div className="row-info-container row pt-2">
                      <div className="col-4  p-3">
                        <div className="d-flex justify-content-center">
                          <img
                            style={{ height: "auto", width: "65%" }}
                            src={currentCardData?.image ? currentCardData?.image : NoCardImage}
                            alt="card-detail"
                          />
                        </div>
                        <div className="mt-4" style={{ height: "auto" }}>
                          <p className="card-info-mini-detail">
                            {/* Ingreso mínimo $25,000 Tasa de Interés máxima (TIIE)
                          +55% 76.1% CAT promedio sin IVA */}
                            Ingreso mínimo{" "}
                            {numberWithCommas(
                              Number(currentCardData?.minimumIncome)
                            )}{" "}
                            <br />
                            Tasa de Interés máxima (TIIE){" "}
                            {currentCardData?.maximumInterestRate} <br />
                            {currentCardData?.cat} CAT promedio sin IVA
                          </p>
                        </div>
                        <div className="mt-4">
                          <span className="link-title">Links</span>
                          {currentCardData?.externalAssets.map(
                            (item: any, index: number) => {
                              return (
                                <a
                                  href={item.url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <span className="link-text">
                                    <br />
                                    {/* Black Unlimited{" "} */}
                                    {item.label}
                                    <span>
                                      <InsertLinkIcon />
                                    </span>
                                  </span>
                                </a>
                              );
                            }
                          )}
                          {/* <br />
                          <span className="link-text">
                            Black Unlimited{" "}
                            <span>
                              <InsertLinkIcon />
                            </span>
                          </span>
                          <br />
                          <span className="link-text">
                            Recompensas{" "}
                            <span>
                              <InsertLinkIcon />
                            </span>
                          </span> */}
                        </div>
                      </div>
                      <div className="col-8 ">
                        {showCalculate ? (
                          <div className="row detail-container">
                            <div className=" col-md-4  ">
                              <h2>Tus Puntos</h2>
                              {currentCardData?.loyaltyProgram ? (
                                <div
                                  className="reward-container   d-flex align-items-center"
                                  style={{
                                    backgroundImage: `url(${currentCardData?.loyaltyProgram})`,
                                  }}
                                ></div>
                              ) : (
                                <div
                                  className="reward-container   d-flex align-items-center"
                                  style={{
                                    backgroundImage: `url(${NoLoyalty})`,
                                  }}
                                ></div>
                              )}
                              <div className="red-line mt-4" />
                              <div className="mt-3">
                                <div className="output-point">
                                  {/* 11,111 puntos */}
                                  {numberWithCommas(
                                    calculatedValues.annualPoints
                                  )}{" "}
                                  puntos
                                </div>
                                <div className="output-point-label">
                                  acumulados anualmente
                                </div>
                              </div>
                              {/* <div className="mt-3">
                                <div className="output-point">
                                  {numberWithCommas(
                                    calculatedValues.additionalCardPoints
                                  )}{" "}
                                  puntos
                                </div>
                                <div className="output-point-label">
                                  de tarjetas adicionales
                                </div>
                              </div> */}
                            </div>
                            <div className="col-md-8 p-3">
                              <div className="result-container col-12 d-flex align-items-center justify-content-center">
                                <div className="result-container-text">
                                  <div className="text-center">
                                    <div className="title-points">
                                      {/* $12,924 MXN */}$
                                      {numberWithCommas(
                                        calculatedValues.valueOfPoints
                                      )}{" "}
                                      MXN
                                    </div>
                                    <div className="output-point-label">
                                      es el valor de tus puntos acumulados
                                    </div>
                                  </div>
                                  <div className="result-stats  col-12">
                                    <table className="col-12">
                                      <tr>
                                        <td className="label-item-points">
                                          Facturación anual
                                        </td>
                                        <td className="result-item-points">
                                          {/* ${"100,000"} MXN */}$
                                          {numberWithCommas(
                                            Number(
                                              calculatedValues.annualExpenses
                                            )
                                          )}{" "}
                                          MXN
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="label-item-points">
                                          Puntos totales
                                        </td>
                                        <td className="result-item-points">
                                          {/* {"20444"} */}$
                                          {numberWithCommas(
                                            // calculatedValues.valueOfPoints
                                            calculatedValues.totalPoints // TODO: wait for the definition of the points
                                          )}{" "}
                                          MXN
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="label-item-points">
                                          Valor aproximado de puntos
                                        </td>
                                        <td className="result-item-points">
                                          {/* ${"14,923"} MXN */}$
                                          {numberWithCommas(
                                            calculatedValues.approximatePoints
                                          )}{" "}
                                          MXN
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="label-item-points">
                                          Anualidad TDC Santander (+IVA)
                                        </td>
                                        <td className="result-item-points">
                                          {/* ${"2,000"} MXN */}$
                                          {numberWithCommas(
                                            Number(currentCardData?.annualFee)
                                          )}{" "}
                                          MXN
                                        </td>
                                      </tr>
                                    </table>
                                    <div className="text-center col-12">
                                      <span className="mini-label">
                                        Producto emitido por Santander Consumo
                                        S.A. de C.V. SOFOM E.R.
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row detail-container">
                            <div className="col-4 ">
                              <h2>Tus Puntos</h2>
                              {/* NoLoyalty */}
                              {currentCardData?.loyaltyProgram ? (
                                <div
                                  className="reward-container  "
                                  style={{
                                    backgroundImage: `url(${currentCardData?.loyaltyProgram})`,
                                  }}
                                ></div>
                              ) : (
                                <div
                                  className="reward-container  "
                                  style={{
                                    backgroundImage: `url(${NoLoyalty})`,
                                  }}
                                ></div>
                              )}

                              {/* <img
                                  style={{ height: "auto", width: "100%" }}
                                  className=""
                                  src={currentCardData?.loyaltyProgram}
                                  alt="reward program"
                                /> */}
                              {/* </div> */}
                              <div className="red-line mt-4" />
                            </div>
                            <div className="col-8  p-3">
                              <div className="result-container  d-flex align-items-center justify-content-center">
                                {currentCardData?.loyaltyProgram ? (
                                  <p className="label-no-result p-2">
                                    Ingresa los gastos de tu cliente para
                                    calcular los puntos en su programa de
                                    lealtad.
                                  </p>
                                ) : (
                                  <div className="no-loyal-info  d-flex justify-content-center">
                                    <div className="no-result-container">
                                      <div className="no-result-container-text p-2 mt-4">
                                        Esta tarjeta <b>no cuenta con programa de
                                        lealtad y no genera puntos</b>, pero puedes
                                        disfrutar sus múltiples beneficios.
                                      </div>
                                      <div className="col-12 d-flex justify-content-center">
                                        <div className="tarjetin-no-reward"></div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className=" mt-2">
                          <h2>Bono de bienvenida</h2>
                          <div className="red-line mt-2" />
                          <p
                            className="sub-title-section mt-2"
                            dangerouslySetInnerHTML={{
                              __html: String(
                                currentCardData?.wellcomeBonusInfo
                              ),
                            }}
                          >
                            {/* 20,000 puntos bono de Bienvenida (al facturar $60,000
                          MXN en los primeros 3 meses a partir de la activación) */}
                          </p>
                        </div>
                        <div className=" mt-2">
                          <h2>Beneficios</h2>
                          <div className="red-line mt-2" />
                          <p
                            className="sub-title-section mt-2"
                            dangerouslySetInnerHTML={{
                              __html: String(currentCardData?.benefits),
                            }}
                          >
                            {/* Acumula 2 Puntos Recompensas por cada dólar gastado.
                          Bono de 20,000 Puntos Recompensas por Bienvenida.
                          Estacionamiento sin costo y acceso al Salón Lounge en
                          el aeropuerto de la CDMX. Siempre protegido con
                          Seguros Mastercard Nivel Platino. */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "100px" }}>
          <NeedHelp />
        </div>
        <Footer />
        <NeedHelpNoLogin />
      </div>
    </div>
  );
};

export default Calculator;
