import React from 'react'
import './EmailError.scss'
import errorMailImg from '../../assets/images/error-mail.png'
import HeaderOut from '../HeaderOut/HeaderOut'
import Footer from '../Footer/Footer'

const EmailError = () => (
  <div className="EmailError">
    {/* EmailError Component */}

    <div className="static-container container-fluid">
        <HeaderOut />
        <div className="container-fluid sub-menu"></div>
      </div>

      <div className="container-fluid main-container">
        <div className="container sub-container   ">
          <div className="col-md-12 col-12 col-12 d-flex justify-content-center align-items-center" style={{height: '650px'}}>
            <div className="col-7">
              <img className="col-12" src={errorMailImg} alt="error mail" />
              <div className="col-12 text-center pt-4">
                <b>El código de activación ha expirado </b> <br />
                ó tienes un nuevo código en tu bandeja de entrada.
              </div>
            </div>
            
          </div>
        </div>
        <Footer />
      </div>

   
  </div>
)

export default EmailError
