import React, {useEffect} from 'react';
import './FullOnBoard.scss';
import { useLocation}from 'react-router-dom'
import Header from "../Header/Header";
import SubHeader from '../SubHeader/SubHeader';
import NeedHelp from '../NeedHelp/NeedHelp';
import Footer from '../Footer/Footer';
import { setTimeout } from 'timers';
import NeedHelpNoLogin from '../NeedHelpNoLogin/NeedHelpNoLogin';



const FullOnBoard = () => { 
  
  const [url, setUrl] = React.useState("")
  const location = useLocation();
  var qs = require("qs");
  useEffect (()=> {


    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (queryParams.page) {
      console.log( 'page ' , queryParams.page);

        if (url === "") {
            setUrl("https://masterup.micmp.app/?token=" + localStorage.getItem('token_masterup') +"&id="+ localStorage.getItem('id_masterup')+"/")

            setTimeout(function(){
              setUrl('https://masterup.micmp.app/' + queryParams.page);
            }, 1000);
        }
        else {
          setUrl('https://masterup.micmp.app/' + queryParams.page);
        }

    } else {
      if (localStorage.getItem('token') !== null) {
        setUrl("https://masterup.micmp.app/?token=" + localStorage.getItem('token_masterup') +"&id="+ localStorage.getItem('id_masterup')+"/")
      }
    }


    
    // if (localStorage.getItem('token') !== null) {
      
    //   console.log('token', localStorage.getItem('token_masterup') , 'id ',localStorage.getItem('id_masterup'))

      
      
    //   // setUrl("https://masterup-prod.uc.r.appspot.com/?token=" + localStorage.getItem('token_masterup') +"&id="+ localStorage.getItem('id_masterup')+"/")
    //   setUrl("https://masterup.micmp.app/?token=" + localStorage.getItem('token_masterup') +"&id="+ localStorage.getItem('id_masterup')+"/")
    //   console.log ( "URL ","https://masterup-prod.uc.r.appspot.com/?token=" + localStorage.getItem('token_masterup') +"&id="+ localStorage.getItem('id_masterup')) 
      
      
      
    //   // setUrl("http://localhost:3001/?token=" + localStorage.getItem('token_masterup') +"&id="+ localStorage.getItem('id_masterup'))  
    //   // setUrl("http://localhost:3001/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImpvaGVsZXI4N0BnbWFpbC5jb20iLCJpYXQiOjE2MzY0OTgxMjl9.dG_EQBYhNyjAQn4aOrax3GOXIBRd9mjQSasymp2ZBVo&id=618aea26f90db80042d857fb")  
    //   //  setUrl( process.env.FULL_ON_BOARD_URL + "/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImpvaGVsLmVzcGFuYUBqZWxseWZpc2guY29tIiwiaWF0IjoxNjMxNjY3NTYzfQ.MuxYUPrN8Oda48idptVvdB43xCeBxsGm92Py3yc0WO0&id=609423efcaaf89269e6bd00e")
    //   //  setUrl("http://localhost:3005/")
    //   // setUrl("https://masterup-dev-dot-mastercard-rebound.uc.r.appspot.com/?token=" + localStorage.getItem('token_masterup') +"&id="+ localStorage.getItem('id_masterup'))  
     
    // }
  }, [])

  const setFOItem = (item:any) => {
    console.log('setFOItem', item)

    // if (url === "") {
      // setUrl("https://masterup.micmp.app/?token=" + localStorage.getItem('token_masterup') +"&id="+ localStorage.getItem('id_masterup')+"/")
    // }
    // setUrl("https://masterup.micmp.app/?token=" + localStorage.getItem('token_masterup') +"&id="+ localStorage.getItem('id_masterup')+"/")

    setUrl('https://masterup.micmp.app/' + item);
  }
  

  return(
  <div className="FullOnBoard" data-testid="FullOnBoard">
    {/* FullOnBoard Component */}
    <div className="container-fluid static-container">
        <Header />
        <SubHeader path="/full-on-board" setFOMenu={setFOItem} />
        {/* <button className="btn btn-primary" onClick={testChange}>test</button> */}
    </div>
    <div className="container-fluid main-container">
      {/* <code><iframe src="https://masterup-prod.uc.r.appspot.com/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImpvaGVsZXI4N0BnbWFpbC5jb20iLCJpYXQiOjE2MzY0OTgxMjl9.dG_EQBYhNyjAQn4aOrax3GOXIBRd9mjQSasymp2ZBVo&id=618aea26f90db80042d857fb/" width="100%" height="1000" title="W3Schools Free Online Web Tutorials"></iframe> </code>  */}
      <code><iframe className="holds-the-iframe" src={url} width="100%" height="1000" title="W3Schools Free Online Web Tutorials"></iframe> </code> 
      <NeedHelp />
      <Footer />
    </div>

    <NeedHelpNoLogin />
    {/* change */}

  </div>
)};

export default FullOnBoard;
