import React, { useEffect } from "react";
import "./Profile.scss";
import Header from "../Header/Header";
import SubHeader from "../SubHeader/SubHeader";
import Footer from "../Footer/Footer";
import CloseIcon from "@material-ui/icons/Close";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { getUser, getAvatars, patchUser } from "../../api";

import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CircularProgress from "@material-ui/core/CircularProgress";
import LockImage from "../../assets/images/lock.png"
import EditIcon from '@material-ui/icons/Edit';
type Severity = 'error' | 'success' | 'info' | 'warning' | undefined
interface AlertData {
  type: Severity
  label: string
  show: boolean
}

interface AvatarData {
  id: number,
  active: boolean,
  url: string,
}


const Profile = () => {
  const router = useHistory();
  const [userData, setUserData] = React.useState({alias: "", username: "", name:"", avatar:{url:"", id: null}, role: "", zone:"", position:"", branchOffice:"", email:"" }) 
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    // shouldUnregister: false
  });

  const [openAlert, setOpenAlert] =  React.useState<AlertData>({
    type: undefined,
    show: false,
    label: "",
  });

  const [showLoading, setShowLoading] = React.useState(false);
  const [showPass, setShowPass] = React.useState("password");
  const [showSelectAvatar, setShowSelectAvatar] = React.useState(false);
  const [avatars, setAvatars] = React.useState<AvatarData[]>([]);
  const [tooltip, setTooltip] = React.useState(false);

  useEffect(() => {
    const getAvatares = async () => {
      setShowLoading(true);
      try {
        const avatarsTemp =  await getAvatars(); 
        avatarsTemp.data.forEach((avatar:any)=> {
          avatar = avatar.active = false;
        })

        setAvatars(avatarsTemp.data);
        console.log('Aatar', avatarsTemp);
      } catch (error) {
        
      }
      
    }

    const getProfile = async() => {

      try {
        const profile = await getUser();
        console.log(profile.data)
        
        // const temp = {userName: profile.data.userName || ""};
         setUserData(profile.data);
          // profile.data.alias = "";
         setShowLoading(false);
        // console.log('Profile', temp);  
      } catch (error) {
        console.log('req'); 
      }
      
    }

    getAvatares();  
    getProfile();
  }, []);

  const goWelcome = () => {
    router.push("/home-page");
  };

  const onSubmit = async (data:any /*{
    alias: string;
    email: string;
  }*/) => {
    setShowLoading(true);
    if (userData.avatar !== null) {
      // console.log("Alias" ,data.alias);
      // console.log("Alias" ,getValues().alias);
      // console.log("Alias" ,watch("alias"));
      console.log("Alias" ,userData.alias);
      if (data.alias !== "" || userData.alias !== "") {
        try {
          const req = {
            alias: data.alias || userData.alias,
            email: watch("email") === "" ? userData.email : data.email,
            // avatarId: 32,
            avatarId: userData.avatar === null ? null : userData.avatar.id,
          };
          console.log("req",req)
  
          const patch_user = await patchUser(req);
  
          console.log("patch user", patch_user);
          setUserData(patch_user.data.user);
          localStorage.setItem('alias', patch_user.data.user.alias);
          localStorage.setItem('avatar', patch_user.data.user.avatar.url);
          setShowLoading(false);
          setTimeout(() => {
            router.go(0);
          }, 1500)
          setOpenAlert({ show: true, type: 'success', label: "Datos actualizados correctamente" });
  
  
  
        } catch (error: any) {
          console.log(error.response.data.message);
          setOpenAlert({ show: true, type: 'error' ,label: error.response.data.message });
          setShowLoading(false);
        }


      }
      else {
        setShowLoading(false);
        setOpenAlert({ show: true, type: 'error' ,label: 'Por favor ingresar un alias' });
      }
    } else {
      setShowLoading(false);
      setOpenAlert({ show: true, type: 'error' ,label: 'Por favor seleccionar un avatar' });
    }
  };

  const backSelected = async() => {
    setShowSelectAvatar(false);
  }

  const setAvatar = (avatar:any, index: number) => {


    const tempAvatar = {
      url: avatar.url,
      id: avatar.id
    }

    userData.avatar = tempAvatar;

    // userData.avatar.url = avatar.url;
    // userData.avatar.id = avatar.id;

    const tempAvatars:any = [];

    avatars.forEach((item)=> {
      if (item.id === avatar.id) {
        item.active  = true;
      }
      else {
        item.active  = false;
      }
      tempAvatars.push(item);
    })

    setAvatars(tempAvatars);
    setUserData(userData);

  }

  const showTooltip = () => { 
    setTooltip(true);

  }

  const hideTooltip = () => { 
    setTooltip(false);
    
  }


  return (
    <div className="Profile" data-testid="Profile">
      <div className="static-container container-fluid">
        <Header  />
        <SubHeader path="/profile" />
      </div>

      <div className="container-fluid main-container ">
        <div className="container sub-container  d-flex align-items-stretch ">
          <div className=" col-12 d-flex justify-content-center  ">
           
            <div className={showSelectAvatar ? "login-container hide" : "login-container show"}>
              <div className="col-12 d-flex d-flex justify-content-end pb-2 px-3 pt-3">
                <CloseIcon onClick={goWelcome} style={{ cursor: "pointer" }} />
              </div>
              <div
                className="col-12 d-flex justify-content-center align-items-center p-3"
                style={{ marginTop: "-10px" }}
              >
                <div className=" col-12 col-md-9 text-left">
                  <div className=" row">
                    <div className="col-4  d-flex justify-content-center align-items-center" style={{position: 'relative'}}>
                      { (  userData.avatar && userData.avatar.url) && <div className="avatar-dot" onClick={()=> setShowSelectAvatar(true)}  style={{backgroundImage: `url(${userData.avatar.url})`, backgroundSize: 'auto 100%', backgroundPosition: 'center'}}> <div className="dot-edit d-flex justify-content-center align-items-center">  <EditIcon style={{ fontSize: 15 }} />   </div> </div>}
                      {   userData.avatar === null && <div onClick={()=> setShowSelectAvatar(true)} className="avatar-dot d-flex justify-content-center align-items-center hi-title">{<div>{userData.name.substring(0,2)}</div>}    <div className="dot-edit d-flex justify-content-center align-items-center">  <EditIcon style={{ fontSize: 15 }} />   </div> </div>}
                    </div>
                    <div className="col-8  terxt-right p-2 hi-title">
                      <div>Hola </div>
                      <div>{watch("alias") === "" ? userData.alias : watch("alias")} </div>
                    </div>
                  </div>
                  {/* <div className="sub-text">
                    Ingresa tus datos para registrarte{" "}
                  </div> */}
                  <div className="form-container pt-2">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group pt-1">
                        <div className="row pb-1">
                          <label className="col-12  col-md-12 label-form">
                            Alias
                          </label>
                        </div>
                        
                        <input 
                          {...register("alias", {
                            required: false,
                          })}
                          defaultValue={userData.alias}
                          maxLength={15}
                          type="text"
                          className="form-control input-text"
                          id="alias"
                          placeholder="Ingresar Alias"
                        /> 
                      </div>

                      <div className="form-group pt-1">
                        <div className="row pb-1">
                          <label className="col-12  col-md-12 label-form">
                            ID
                          </label>
                        </div>
                        <input
                          {...register("id", {
                            required: false,
                          })}
                          value={userData.username}
                          type="text"
                          disabled={true}
                          className="form-control input-text"
                          id="id"
                        />
                      </div>

                      <div className="form-group pt-1">
                        <div className="row pb-1">
                          <label className="col-12  col-md-12 label-form">
                            Nombre Completo
                          </label>
                        </div>
                        <input
                          {...register("nombre", {
                            required: false,
                          })}
                          value={userData.name}
                          type="text"
                          disabled={true}
                          className="form-control input-text"
                          id="nombre"
                        />
                      </div>

                      <div className="form-group pt-1">
                        <div className="row pb-1">
                          <label className="col-12  col-md-12 label-form">
                            Ejecutivo
                          </label>
                        </div>
                        <input
                          {...register("ejecutivo", {
                            required: false,
                          })}
                          value={userData.position}
                          disabled={true}
                          type="text"
                          className="form-control input-text"
                          id="ejecutivo"
                        />
                      </div>

                      <div className="form-group pt-1">
                        <div className="row pb-1">
                          <label className="col-12  col-md-12 label-form">
                            Zona
                          </label>
                        </div>
                        <input
                          {...register("zona", {
                            required: false,
                          })}
                          value={userData.zone}
                          type="text"
                          disabled={true}
                          className="form-control input-text"
                          id="zona"
                        />
                      </div>

                      <div className="form-group pt-1">
                        <div className="row pb-1">
                          <label className="col-12  col-md-12 label-form">
                            Sucursal
                          </label>
                        </div>
                        <input
                          {...register("sucursal", {
                            required: false,
                          })}
                          value={userData.branchOffice}
                          type="text"
                          disabled={true}
                          className="form-control input-text"
                          id="sucursal"
                        />
                      </div>

                      <div className="form-group pt-1">
                        <div className="row pb-1">
                          <label className="col-12  col-md-12 label-form">
                            Correo electrónico
                          </label>
                        </div>
                        <input
                          {...register("email", {
                            pattern: {
                              value:
                                /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                              message: "pattern message",
                            },
                            required: false,
                          })}
                          type="email"
                          defaultValue={userData.email}
                          className="form-control input-text"
                          id="email"
                          placeholder=""
                          disabled={true}
                        />
                        {errors.email &&
                          String(errors.email.type) === "pattern" && (
                            <small className="input-error">
                              Correo electrónico no válido
                            </small>
                          )}
                      </div>

                      <div className="text-center mt-2 pt-2" style={{position: 'relative'}}>
                      
                        <button
                          type="submit"
                          className="btn btn-primary col-md-6 col-10 mt-2"
                          disabled={!isValid}
                          style={{ height: "3.5em", fontSize: "14px" }}
                        >
                          Aceptar
                        </button>
                        { tooltip && <div className="custom-tooltip p-1 d-flex align-items-center ">Cambio de contraseña </div>}
                        <img onMouseOut={hideTooltip}  onMouseOver={showTooltip} onClick={()=> {router.push('/change-password')}} src={LockImage} alt="lock image" style={{paddingLeft: '10px', paddingTop: '10px', cursor: 'pointer'}}/>
                      </div>
                      {showLoading && (
                        <div className="pt-3 col-12 d-flex justify-content-center">
                          <CircularProgress color="secondary" size={20} />
                        </div>
                      )}
                      <div className="pt-2">
                        <Collapse in={openAlert.show}>
                          <Alert
                            severity={openAlert.type}
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setOpenAlert({ type:openAlert.type, show: false, label: openAlert.label });
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {openAlert.label}
                          </Alert>
                        </Collapse>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div> 



            
            
             <div className={!showSelectAvatar ? "login-container hide" : "login-container show"} style={{height: '70%', width: '376px'}}>
               <div className="col-12 d-flex d-flex justify-content-end pb-2 px-3 pt-3">
                 <CloseIcon onClick={backSelected} style={{ cursor: "pointer" }} />
               </div>
               <div
                className="col-12 p-3"
                style={{ marginTop: "-30px" }}
              >
                  <h1 className="hi-title">Selecciona tu avatar</h1>
                  <div className="row" style={{height: '400px', overflowY: 'auto', paddingLeft: '0.4em'}}>
                    {avatars.map((avatar:any, index:number) => {
                      return (<div key={avatar.id} className={avatar.active ? "avatar-dot-selected": "avatar-dot-select"} onClick={() => setAvatar(avatar, index)} style={{backgroundImage: `url(${avatar.url})`}}></div>)
                    })}
                    {/* <div className="avatar-dot-select"></div>
                    <div className="avatar-dot-select"></div>
                    <div className="avatar-dot-select"></div> */}
                  </div>
               </div>
               <div className ="d-flex align-items-center justify-content-center">
                <button onClick={()=> setShowSelectAvatar(false)}
                  className="btn btn-primary col-md-4 col-10 mt-2"
                  style={{ height: "3.5em", fontSize: "14px" }}>
                  Seleccionar
                </button>
               </div>
             </div>
            
          

              





          </div>
        </div>
        <div></div>
        <Footer />
      </div>
    </div>
  );
};

export default Profile;
