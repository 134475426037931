import React from "react";
import "./NeedHelp.scss";

import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import ImageHelp from "../../assets/images/we-want-to-listen.png";
import ImageThanks from "../../assets/images/tarjetin-ticket-thanks.png";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";

import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import { setTicket } from "../../api/";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },

  select: {
    width: "80%",
    "&:before": {
      borderColor: "#407FA0",
    },
    "&:after": {
      borderColor: "red",
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "red",
    },
  },
  inputRoot: {
    width: "70%",
    backgroundColor: "#f7fafc",
  },
  input: {
    color: "#000",
  },
});

type Anchor = "top" | "left" | "bottom" | "right";

interface StateValues {
  section: string;
  subject: string;
  message: string;
}

type Severity = "error" | "success" | "info" | "warning" | undefined;

interface AlertData {
  type: Severity;
  label: string;
  show: boolean;
}

const NeedHelp = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [values, setValues] = React.useState<any>({
    section: "Sección",
    subject: "Asunto",
    message: "",
  });

  const [success, setSuccess] = React.useState(false);

  const [alert, setAlert] = React.useState<AlertData>({
    type: undefined,
    label: "",
    show: false,
  });
  // const [open, setOpen] = React.useState(false);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      if (open) {
        setSuccess(false);
      }

      setState({ ...state, [anchor]: open });
    };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#407FA0",
      },
    },
  });

  const setHelpTicket = async () => {
    const ticket = {
      section: "",
      subject: "",
      description: values.message,
    };

    switch (values.section) {
      case "Sección":
        ticket.section = "";
        break;
      case "Página de Inicio":
        ticket.section = "HOME";
        break;
      case "Promociones":
        ticket.section = "PROMOCIONES";
        break;
      case "Preguntas frecuentes":
        ticket.section = "FAQS";
        break;
      case "Full on Board":
        ticket.section = "FOB";
        break;
      case "Calculadora de puntos":
        ticket.section = "CALCULADORA";
        break;
      case "Mi perfil":
        ticket.section = "PERFIL";
        break;
    }

    switch (values.subject) {
      case "Comentario":
        ticket.subject = "Comment";
        break;
      case "Pregunta":
        ticket.subject = "Question";
        break;
      case "Sugerencias":
        ticket.subject = "Suggestion";
        break;
      case "Problemas técnicos":
        ticket.subject = "Technical Problem";
        break;
    }

    console.log(ticket);

    //const registerAlert: AlertData = { type: severity, label: res.data.message, show: true }

    try {
      const ticketResponse = await setTicket(ticket);
      console.log(ticketResponse);

      if (ticketResponse.status === 201) {
        setSuccess(true);
        setValues({
          section: "Sección",
          subject: "Asunto",
          message: "",
        });
      }
      // setAlert({
      //   type: "success",
      //   label: "Un nuevo ticket ha sido asignado, por favor revisa tu correo",
      //   show: true,
      // });
      // setTimeout(() => {
      //   setAlert({ type: "success", label: "", show: false });
      // }, 3000);
    } catch (error) {
      console.log(error);
      setAlert({ type: "error", label: "Error al generar ticket", show: true });
      setTimeout(() => {
        setAlert({ type: "error", label: "", show: false });
      }, 3000);
    }
  };

  const handleChangeSection = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    console.log("event section", event.target.value);
    setValues({ ...values, section: event.target.value as string });
  };

  const handleChangeSubject = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    console.log("event subject", event.target.value);
    setValues({ ...values, subject: event.target.value });
  };

  const handleChangeInput =
    (prop: keyof StateValues) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  return (
    <div className="NeedHelp" data-testid="NeedHelp">
      <div className=" search-section ">
        <div className="container ">
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "450px" }}
          >
            <div className="did-you-find col-12  p-3  d-flex align-items-center ">
              <div className="text-help-container">
                <h1 style={{fontSize: '1.8em'}}>¿Podemos ayudarte? </h1>
                <p className="pt-2 text-help">
                  Contáctanos para solucionar tus dudas, retroalimentar tu
                  experiencia en MiCMP o si tienes algún problema técnico.
                </p>
                <p className="  text-help">¡Será un placer ayudarte!</p>
                <button
                  onClick={toggleDrawer("bottom", true)}
                  className="btn btn-primary col-md-2 col-12 mt-3"
                >
                  Contáctanos
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <React.Fragment key={"bottom"}>
        <Drawer
          anchor={"bottom"}
          open={state["bottom"]}
          onClose={toggleDrawer("bottom", false)}
        >
          <div className="need-help-drawer ">
            <div className="container">
              {!success ? (
                <div
                  className="row p-4 d-flex align-items-center"
                  style={{ height: "30em" }}
                >
                  <div className="col-md-4 col-12   pt-4 d-flex justify-content-center">
                    <img className="img col-10" src={ImageHelp} alt="" />
                  </div>
                  <div className="col-md-8 col-12  p-3">
                    <div className="row">
                      <div className="col-11">
                        <h1 className="h1-help">¡Queremos Escucharte!</h1>
                      </div>
                      <div className="col-1">
                        <CloseIcon
                          onClick={toggleDrawer("bottom", false)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                    <p className="text-help col-10">
                      Por favor compártenos cualquier duda o comentario que
                      tengas relacionado a Centro de Medios de Pago.
                    </p>
                    {/* <form> */}
                    <div className="form-container">
                      <div className="row">
                        <div className="col-6">
                          <ThemeProvider theme={theme}>
                            <Select
                              value={values.section}
                              onChange={handleChangeSection}
                              displayEmpty
                              className={classes.select}
                              // renderValue={(value: any) => (
                              //   <div className="text-item-calculator">
                              //     {value}
                              //   </div>
                              // )}
                            >
                              <MenuItem value="Sección">
                                <em>Sección</em>
                              </MenuItem>
                              <MenuItem value="Página de Inicio">
                                <div>Página de Inicio</div>
                              </MenuItem>
                              <MenuItem value="Promociones">
                                <div>Promociones</div>
                              </MenuItem>
                              <MenuItem value="Preguntas frecuentes">
                                <div>Preguntas frecuentes</div>
                              </MenuItem>
                              <MenuItem value="Full on Board">
                                <div>Full on Board</div>
                              </MenuItem>
                              <MenuItem value="Calculadora de puntos">
                                <div>Calculadora de puntos</div>
                              </MenuItem>
                              <MenuItem value="Mi perfil">
                                <div>Mi perfil</div>
                              </MenuItem>
                            </Select>
                          </ThemeProvider>
                        </div>
                        <div className="col-6">
                          <ThemeProvider theme={theme}>
                            <Select
                              value={values.subject}
                              onChange={handleChangeSubject}
                              displayEmpty
                              className={classes.select}
                              // renderValue={(value: any) => (
                              //   <div className="text-item-calculator">
                              //     {value}
                              //   </div>
                              // )}
                            >
                              <MenuItem value="Asunto">
                                <em>Asunto</em>
                              </MenuItem>
                              <MenuItem value="Comentario">
                                <div>Comentarios y sugerencias</div>
                              </MenuItem>
                              <MenuItem value="Pregunta">
                                <div>Pregunta</div>
                              </MenuItem>
                              {/* <MenuItem value="Sugerencias">
                                <div>Sugerencias</div>
                              </MenuItem> */}
                              <MenuItem value="Problemas técnicos">
                                <div>Problemas técnicos</div>
                              </MenuItem>
                            </Select>
                          </ThemeProvider>
                        </div>
                      </div>
                      <div className="pt-4">
                        <TextField
                          // type="number"
                          name="comments"
                          value={values.message}
                          onChange={handleChangeInput("message")}
                          multiline
                          rows={4}
                          className={classes.inputRoot}
                          InputProps={{
                            classes: {
                              input: classes.input,
                            },
                          }}
                          id="anualBill"
                          label={
                            <div className="input-label-calc">
                              {/* ¿Cómo podemos mejorar para tí? */}
                              ¿Cómo podemos mejorar para ti?
                            </div>
                          }
                        />
                      </div>
                    </div>
                    <button
                      onClick={setHelpTicket}
                      className="btn btn-primary col-md-2 col-12 mt-4"
                      disabled={
                        values.message !== "" &&
                        values.section !== "Sección" &&
                        values.subject !== "Asunto"
                          ? false
                          : true
                      }
                    >
                      Enviar
                    </button>
                    {/* </form> */}
                    <div className="pt-3">
                      <Collapse in={alert.show}>
                        <Alert
                          className="col-12"
                          severity={alert.type}
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setAlert({
                                  type: undefined,
                                  label: "",
                                  show: false,
                                });
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {alert.label}
                        </Alert>
                      </Collapse>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="row p-4 d-flex align-items-center"
                  style={{ height: "30em" }}
                >
                  <div className="col-md-4 col-12 pt-4 ">
                    <img className="img col-12" src={ImageThanks} alt="" />
                  </div>
                  <div className="col-md-8 col-12 p-3 ">
                    <div className="row">
                      <div className="col-11">
                        <h1 className="h1-help">¡Gracias por contactarnos!</h1>
                      </div>
                      <div className="col-1">
                        <CloseIcon
                          onClick={toggleDrawer("bottom", false)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                    <p className="text-help">
                      Tu mensaje será revisado y te responderemos al correo que
                      diste de alta en MiCMP a la brevedad posible*
                    </p>
                    <div className="pt-1 text-help">
                      {/* <b>¡Seguimos en contacto!</b> */}
                      <p
                        className="mt-2"
                        style={{ fontSize: "0.8em", lineHeight: "1em" }}
                      >
                        * Te llegará un correo electrónico en un lapso no mayor
                        a 24 hrs. en los días de atención de Lunes a Viernes.
                      </p>
                    </div>
                    <button
                      onClick={toggleDrawer("bottom", false)}
                      className="btn btn-primary col-md-2 col-12 mt-4"
                    >
                      Entendido
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default NeedHelp;
