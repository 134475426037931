import React, {useEffect} from "react";
import "./Login.scss";
import HeaderOut from "../HeaderOut/HeaderOut";
import Footer from "../Footer/Footer";
import NeedHelpNoLogin from "../NeedHelpNoLogin/NeedHelpNoLogin";
import CloseIcon from "@material-ui/icons/Close";
import { useForm } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import { login } from "../../api/";

import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CircularProgress from "@material-ui/core/CircularProgress";

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import TagManager from 'react-gtm-module';



const Login = () => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });
  const goWelcome = () => {
    history.push("/");
  };

  const [openAlert, setOpenAlert] = React.useState({
    show: false,
    label: "error",
  });

  const [showLoading, setShowLoading] = React.useState(false);
  const [showPass, setShowPass] = React.useState('password')

  useEffect(() => {
    console.log('token', localStorage.getItem('token'))
    if (localStorage.getItem('token') !== null) {
      history.push('/home-page');
    }
  }, [])


  const onSubmit = async (data:any) => {
    console.log(data.idlogin, data.passwordLogin)
    setShowLoading(true);
    
    try {
      const reqLogin = {
        "credentials": data.idlogin,
        "password": data.passwordLogin,
      };
      const getLogin = await login(reqLogin);
      console.log(getLogin);
      setShowLoading(false);
      if (getLogin.data.ok) {
        localStorage.setItem('token', getLogin.data.token);
        localStorage.setItem('token_masterup', getLogin.data.masterupLoginData.token);
        localStorage.setItem('id_masterup', getLogin.data.masterupLoginData.id);
        localStorage.setItem('user', getLogin.data.user.name);
        localStorage.setItem('alias', getLogin.data.user.alias);
        localStorage.setItem('avatar', getLogin.data.user.avatar.url);

        const tagManagerArgs = {
          gtmId: 'GTM-T76RXL4',
          dataLayer: {
              userID: String(getLogin.data.user.id),
              actPageTitle: 'Centro de Medios de Pagos'
          }
        }
        console.log(tagManagerArgs);
        TagManager.initialize(tagManagerArgs);

        if (localStorage.getItem('walkthrough') !== null) {
          setTimeout(() => {
            history.push('/home-page')
          }, 500)
        } else {
          setTimeout(() => {
            history.push('/walkthrough')
          }, 500)
        }
      }
    } catch (error:any) {
       console.log(error.response.data.message)
       setOpenAlert({ show: true, label: error.response.data.message });
       setShowLoading(false);
    }
    
  }
  const showPassword = () => {
    if (showPass === "password") {
      setShowPass('text');
    } else {
      setShowPass('password');
    }
  }

  

  return (
    <div className="Login" data-testid="Login">
      {/* Login Component */}
      <div className="static-container container-fluid">
        <HeaderOut />
        <div className="container-fluid sub-menu"></div>
      </div>

      <div className="container-fluid main-container   ">
        <div className="container sub-container  d-flex align-items-stretch ">
          <div className=" col-12 d-flex justify-content-center  ">
            <div className=" login-container">
              <div className="col-12 d-flex d-flex justify-content-end pb-2 px-3 pt-3">
                <CloseIcon style={{ cursor: "pointer" }} onClick={goWelcome} />
              </div>
              <div
                className="col-12 d-flex justify-content-center align-items-center p-3"
                style={{ marginTop: "-10px" }}
              >
                <div className=" col-12 col-md-9 text-left">
                  <h1>Iniciar sesión </h1>
                  <div className="sub-text">
                    Ingresa tus datos para iniciar sesión{" "}
                  </div>
                  <div className="form-container pt-4">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <div className="row pb-2">
                          <label className="col-6 col-md-7 label-form">
                            No. empleado o email
                          </label>
                          <label className="col-6 col-md-5 label-alt d-flex align-items-end">
                            ¿Cómo identifico mi número de empleado?
                          </label>
                        </div>
                        <input
                          {...register("idlogin", {
                            required: true,
                            /*minLength: 7,
                            maxLength: 7,
                            pattern: { value: /^\d+$/, message: "bad code" },*/
                          })}
                          // maxLength={7}
                          type="text"
                          className="form-control input"
                          id="idlogin"
                          aria-describedby="emailHelp"
                          placeholder="1234567"
                        />
                        {String(errors.idlogin?.type) === "pattern" && (
                          <span className="input-error">
                            Solo números permitidos
                          </span>
                        )}
                        {String(errors.idlogin?.type) === "minLength" && (
                          <span className="input-error">
                            7 caracteres requeridos
                          </span>
                        )}
                        {/* <small id="emailHelp" className="form-text text-muted">
                        We'll never share your email with anyone else.
                      </small> */}
                      </div>
                      <div className="form-group pt-4">
                        <div className="row pb-2">
                          <label className="col-4  col-md-6 label-form">
                            Constraseña
                          </label>
                          <label className="col-8 col-md-6 label-alt pt-2">
                           <Link to="/password-recovery"> ¿Olvidaste tu contraseña? </Link>
                          </label>
                        </div>
                        <input
                          {...register("passwordLogin", {
                            required: true,
                            pattern: { value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/, message: "wrong psw" },
                            // minLength: 7,
                            // maxLength: 7,
                          })}
                          // maxLength={7}
                          type={showPass}
                          className="form-control input"
                          id="passwordLogin"
                          placeholder="Ingresa contraseña"
                        />
                        <div className="col-1" style={{ position:'relative', float: 'right',  marginTop: '-30px', paddingRight: '5px'}} >
                          { showPass === "password" ? <VisibilityOutlinedIcon onClick={showPassword} /> : <VisibilityOffOutlinedIcon onClick={showPassword} />}
                        </div>
                        {String(errors.passwordLogin?.type) === "minLength" && (
                          <span className="input-error">
                            7 dígitos requeridos
                          </span>
                        )}
                        {String(errors.passwordLogin?.type) === "pattern" && (
                          <span className="input-error">
                           Formato de contraseña inválido
                          </span>
                        )}
                      </div>
                      <div className="text-center mt-4 pt-1">
                        {/* <Link to="/home-page"> */}
                          <button
                            className="btn btn-primary col-md-6 col-10 mt-2"
                            disabled={!isValid}
                            type="submit"
                            style={{ height: "3.5em", fontSize: "14px" }}
                          >
                            Iniciar sesión
                          </button>
                        {/* </Link> */}
                      </div>
                      {showLoading && (
                        <div className="pt-3 col-12 d-flex justify-content-center">
                          <CircularProgress color="secondary" size={20} />
                        </div>
                      )}
                      <div className="pt-2">
                        <Collapse in={openAlert.show}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setOpenAlert({ show: false, label: "error" });
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {openAlert.label}
                          </Alert>
                        </Collapse>
                      </div>
                      <div className="text-center mt-4">
                        <span className="text-tyc mt-2">
                          Revisa <u>Terminos y Condiciones.</u>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        {/* <NeedHelpNoLogin /> */}
      </div>
    </div>
  );
};

export default Login;
