import React from "react";
import "./HeaderOut.scss";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import headerLogoImage from "../../assets/images/logo-hub-pagos.svg";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // flexGrow: 1,‹
    },
    menuButton: {
      // marginRight: theme.spacing(2),
    },
    bgColor: {
      backgroundColor: "red",
      boxShadow: "none",
    },
    title: {
      flexGrow: 1,
    },
  })
);

const HeaderOut = () => { 
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  return (
  <div className="HeaderOut" data-testid="HeaderOut">
    <AppBar position="static" className={classes.bgColor}>
      <Toolbar className="container">
        <Box width={1} display="flex">
          <Box flexGrow={1} display="inline-flex">
            <Box p={0.8}>
              {/* <Link to="/"> */}
                <img src={headerLogoImage} alt="Hub Pagos" />
              {/* </Link> */}
            </Box>
            {/* <Box
              width={1}
              ml={4}
              mt={1.4}
              display={{ xs: "none", sm: "none", md: "block", lg: "block" }}
            >
              <Box className="colorNose" pl={4} display="inline">
                <Link className="menu-item" to="/promotions">
                  Promociones
                </Link>
              </Box>
              <Box pl={4} display="inline">
                <Link className="menu-item" to="/faq">
                  Preguntas frecuentes
                </Link>
              </Box>
              <Box pl={4} display="inline">
                <Link className="menu-item" to="/full-on-board">
                  Full Onboard
                </Link>
              </Box>
            </Box> */}
          </Box>
          {/* <Box>
            Karla Días
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Mi perfil</MenuItem>
              <MenuItem onClick={handleClose}>Cerrar sesión</MenuItem>
            </Menu>
          </Box> */}
        </Box>
      </Toolbar>
    </AppBar>
  </div>
)};

export default HeaderOut;
