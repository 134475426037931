import React from "react";
import "./PromItem.scss";
import { useHistory, Link } from "react-router-dom";
import moment from "moment";

const PromItem = (props: any) => {
  // const history = useHistory();

  // const viewDetail = ()=> {
  //   history.push('/promotions-details?id=' + props.data.id);
  // }

  return (
    <div
      className="PromItem"
      data-testid="PromItem"
      style={{ position: "relative" }}
    >
      {!props.data.expired ? (
        <div
          className="debug item"
          style={{ backgroundImage: `url(${props.data.image})` }}
        >
          <div className="sub-item  p-3">
            {/* <div className={props.data.expired ? "sub-item-expired p-3" : "sub-item p-3"}> */}
            <div className="title-prom">{props.data.title}</div>
            <div
              className="description pt-1"
              dangerouslySetInnerHTML={{
                __html: String(props.data.description.substring(0, 115)),
              }}
            ></div>
            <div
              className="pt-3"
              style={{ position: "absolute", bottom: "125px" }}
            >
              <span className="label">Vigencia</span>
              <span className="description-text">
                {" "}
                &nbsp; {moment(props.data.startsAt).format("DD-MM-YYYY")}{" "}
                &nbsp;a&nbsp; {moment(props.data.endsAt).format("DD-MM-YYYY")}{" "}
              </span>
            </div>
            <div
              className="pt-2"
              style={{ position: "absolute", bottom: "75px", height: "50px" }}
            >
              <span className="label">Segmentos</span>
              {props.data.segments[0] && (
                <div className="segmentos"> {props.data.segments[0].name} </div>
              )}
              {props.data.segments[1] && (
                <div className="segmentos"> {props.data.segments[1].name} </div>
              )}
            </div>
            <div
              className="col-10"
              style={{ position: "absolute", bottom: "15px", height: "40px" }}
            >
              {props.from === "private" ? (
                <Link to={"/promotions-details?id=" + props.data.id}>
                  <button
                    className="btn btn-primary col-md-8 col-8"
                    style={{ marginLeft: "-0.2em" }}
                  >
                    Ver más
                  </button>
                </Link>
              ) : (
                <Link to={"/public-promotions-details?id=" + props.data.id}>
                  <button
                    className="btn btn-primary col-md-8 col-8"
                    style={{ marginLeft: "-0.2em" }}
                  >
                    Ver más
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className="item item-expired"
          style={{ backgroundImage: `url(${props.data.image})` }}
        >
          <div className="sub-item-expired  p-3">
            {/* <div className={props.data.expired ? "sub-item-expired p-3" : "sub-item p-3"}> */}
            <div className="title-prom">{props.data.title}</div>
            <div
              className="description pt-1"
              dangerouslySetInnerHTML={{
                __html: String(props.data.description.substring(0, 115)),
              }}
            ></div>
            <div
              className="pt-3"
              style={{ position: "absolute", bottom: "125px" }}
            >
              <span className="label">Vigencia</span>
              <span className="description-text">
                {" "}
                &nbsp; {moment(props.data.startsAt).format("DD-MM-YYYY")}{" "}
                &nbsp;a&nbsp; {moment(props.data.endsAt).format("DD-MM-YYYY")}{" "}
              </span>
            </div>
            <div
              className="pt-2"
              style={{ position: "absolute", bottom: "75px", height: "50px" }}
            >
              <span className="label">Segmentos</span>
              {props.data.segments[0] && (
                <div className="segmentos"> {props.data.segments[0].name} </div>
              )}
              {props.data.segments[1] && (
                <div className="segmentos"> {props.data.segments[1].name} </div>
              )}
            </div>
            <div
              className="col-10"
              style={{ position: "absolute", bottom: "15px", height: "40px" }}
            >
              {props.from === "private" ? (
                <Link to={"/promotions-details?id=" + props.data.id}>
                  <button
                    className="btn btn-primary col-md-8 col-8"
                    style={{ marginLeft: "-0.2em" }}
                  >
                    Ver más
                  </button>
                </Link>
              ) : (
                <Link to={"/public-promotions-details?id=" + props.data.id}>
                  <button
                    className="btn btn-primary col-md-8 col-8"
                    style={{ marginLeft: "-0.2em" }}
                  >
                    Ver más
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PromItem;
