import React from "react";
import "./ItemHome.scss";
import moment from "moment";
import { Link } from "react-router-dom";

const ItemHome = (props: any) => 
   (
    <div className="ItemHome  col-12 " data-testid="ItemHome" style={{position: 'relative'}}>
          <div
            className="debug item"
            style={{ backgroundImage: `url(${props.data.image})` }}
          >
            <div className="sub-item p-3">
              <div className="title-prom">{props.data.title}</div>
              <div
                className="description pt-1"
                dangerouslySetInnerHTML={{
                  __html: String(props.data.description.substring(0, 115)),
                }}
              ></div>
              <div
                className="pt-3"
                style={{ position: "absolute", bottom: "120px" }}
              >
                <span className="label">Vigencia</span>
                <span className="description-text">
                  {" "}
                  &nbsp; {moment(props.data.startsAt).format("DD-MM-YYYY")}{" "}
                  &nbsp;a&nbsp; {moment(props.data.endsAt).format("DD-MM-YYYY")}{" "}
                </span>
              </div>
              <div
                className="pt-2"
                style={{ position: "absolute", bottom: "80px" }}
              >
                <span className="label">Segmentos</span>
                {props.data.segments[0] && (
                  <div className="segmentos">
                    {" "}
                    {props.data.segments[0].name}{" "}
                  </div>
                )}
                {props.data.segments[1] && (
                  <div className="segmentos">
                    {" "}
                    {props.data.segments[1].name}{" "}
                  </div>
                )}
              </div>
              <div
                className="col-10"
                style={{ position: "absolute", bottom: "20px", height: "40px" }}
              >
                 <Link to={'/promotions-details?id=' + props.data.id}>
                <button
                  // onClick={viewDetail}
                  className="btn btn-primary col-md-8 col-8"
                  style={{ marginLeft: "-0.2em" }}
                >
                  Ver más
                </button>
                </Link>
              </div>
            </div>
          </div>
    </div>
  );

export default ItemHome;
