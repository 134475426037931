import React from "react";
import "./NeedHelpNoLogin.scss";
import CercleHelp from "../../assets/images/heelp-out-btn.png";
import { useForm } from "react-hook-form";
import { setTicketAccess } from "../../api/";

import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

import Drawer from "@material-ui/core/Drawer";

type Anchor = "top" | "left" | "bottom" | "right";

type Severity = "error" | "success" | "info" | "warning" | undefined;

interface AlertData {
  type: Severity;
  label: string;
  show: boolean;
}

const NeedHelpNoLogin = () => {
  const [state, setState] = React.useState(false);
  const [alert, setAlert] = React.useState<AlertData>({
    type: undefined,
    label: "",
    show: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });
  const [success, setSuccess] = React.useState(false);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      if (open) {
        setSuccess(false);
      }

      setState(open);
    };

  const onSubmit = async (data:any /*{
    name: string;
    userId: string;
    zone: string;
    branchOffice: string;
    rol: string;
    position: string;
    description: string;
    email: string;
  }*/) => {
    console.log(data);

    try {
      const response = await setTicketAccess(data);
      if (response.status === 201) {
        // setAlert({
        //   type: "success",
        //   label: "Un nuevo ticket ha sido asignado, por favor revisa tu correo",
        //   show: true,
        // });

        // setTimeout(() => {
        //   setAlert({ type: "success", label: "", show: false });
        // }, 3000);
        setSuccess(true);
      }
      console.log(response);
    } catch (error) {
      console.log(error);
      setAlert({ type: "error", label: "Error al generar ticket", show: true });
      setTimeout(() => {
        setAlert({ type: "error", label: "", show: false });
      }, 3000);
    }
  };

  return (
    <div
      className="NeedHelpNoLogin   d-flex justify-content-end"
      style={{ padding: 0, height: "50px", right: "1em", bottom: "1em", position: "fixed" }}
      data-testid="NeedHelpNoLogin"
    >
      {/* NeedHelpNoLogin Component */}
      <div className="button-container">
        <img
          className="img-help"
          onClick={toggleDrawer("right", true)}
          src={CercleHelp}
          alt="Help"
        />
      </div>

      <div>
        <React.Fragment key={"right"}>
          <Drawer
            anchor={"right"}
            open={state}
            onClose={toggleDrawer("right", false)}
          >
            {!success ? (
              <div className="drawer-help-out">
                <div className="header-help col-12">
                  ¿Tienes problemas para ingresar?
                </div>
                <div className="col-12 p-3 mt-2">
                  <div className="label-form-help">
                    Compártenos tus datos para darle un correcto seguimiento a
                    tu problema de acceso
                  </div>
                  <div className="input-container col-12 pt-3">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="p-1">
                        <label className="label-form-help pb-2">
                          Nombre completo
                        </label>
                        <input
                          {...register("name", {
                            required: true,
                          })}
                          type="text"
                          className="form-control input"
                          id="name"
                          aria-describedby="nameHelp"
                          placeholder="Juan Pérez García"
                        />
                      </div>
                      <div className="p-1">
                        <label className="label-form-help pb-2">
                          ID Usuario
                        </label>
                        <input
                          {...register("userId", {
                            required: true,
                          })}
                          type="text"
                          className="form-control input"
                          id="userId"
                          aria-describedby="idUser"
                          placeholder="1234567"
                        />
                      </div>
                      <div className="p-1">
                        <label className="label-form-help pb-2">Zona</label>
                        <input
                          {...register("zone", {
                            required: true,
                          })}
                          type="text"
                          className="form-control input"
                          id="zone"
                          aria-describedby="zone"
                          placeholder="Norte"
                        />
                      </div>
                      <div className="p-1">
                        <label className="label-form-help pb-2">Sucursal</label>
                        <input
                          {...register("branchOffice", {
                            required: true,
                          })}
                          type="text"
                          className="form-control input"
                          id="branchOffice"
                          aria-describedby="zone"
                          placeholder="Hipódromo"
                        />
                      </div>
                      <div className="p-1">
                        <label className="label-form-help pb-2">Perfil</label>
                        <input
                          {...register("role", {
                            required: true,
                          })}
                          type="text"
                          className="form-control input"
                          id="role"
                          aria-describedby="role"
                          placeholder="RED, FAS, CCS o Personal"
                        />
                      </div>
                      <div className="p-1">
                        <label className="label-form-help pb-2">Puesto</label>
                        <input
                          {...register("position", {
                            required: true,
                          })}
                          type="text"
                          className="form-control input"
                          id="position"
                          aria-describedby="position"
                          placeholder="Ejecutivo, Supervisor o Director"
                        />
                      </div>
                      <div className="p-1">
                        <label className="label-form-help pb-2">
                          Detalle del Problema
                        </label>
                        <textarea
                          {...register("description", {
                            required: true,
                          })}
                          className="form-control input"
                          id="description"
                          style={{ height: "100px" }}
                          aria-describedby="des"
                          placeholder="¿Cuál es tu problema?"
                        />
                      </div>
                      <div className="p-1">
                        <label className="label-form-help pb-2">
                          Correo de contacto
                        </label>
                        <input
                          {...register("email", {
                            required: true,
                            pattern: {
                              value:
                                /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                              message: "pattern message",
                            },
                          })}
                          type="text"
                          className="form-control input"
                          id="email"
                          aria-describedby="email"
                          placeholder="usuario@mail.com"
                        />
                        {errors.email && String(errors.email.type) === "pattern" && (
                          <small style={{ color: "red" }}>
                            Correo electrónico no válido
                          </small>
                        )}
                      </div>
                      <div className="p-1">
                        <label className="label-form-help pb-2" style={{color:'red', fontSize:'0.8em'}}>
                         *Todos los campos son obligatorios
                        </label>
                        <Collapse in={alert.show}>
                          <Alert
                            className="col-12"
                            severity={alert.type}
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setAlert({
                                    type: undefined,
                                    label: "",
                                    show: false,
                                  });
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {alert.label}
                          </Alert>
                        </Collapse>
                      </div>
                      <div className="p-1">
                        <button
                          className="btn btn-primary col-md-6 col-10 mt-2"
                          disabled={!isValid}
                          type="submit"
                          style={{ height: "3.5em", fontSize: "14px" }}
                        >
                          Enviar
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="footer-help col-12 mt-2 pt-2">
                  <div>Powered by FreshDesk Service Management</div>
                </div>
              </div>
            ) : (
              <div className="drawer-help-out">
                <div
                  className="debug p-3 d-flex justify-content-center align-items-center"
                  style={{ height: "100vh" }}
                >
                  <div>
                    <p
                      className="label-form-help"
                      style={{ fontSize: "1.2em", lineHeight: "1.3em" }}
                    >
                      Se ha generado un ticket para atender tu solicitud, en
                      cuanto revisemos tu situación, te responderemos al correo
                      que nos proporcionaste*, agradecemos mucho tu paciencia y
                      nos da gusto que seas parte de nuestra comunidad
                    </p>
                    <div
                      className="pt-2 label-form-help"
                      style={{ fontSize: "0.8em", lineHeight: "1.6em" }}
                    >
                      * Responderemos en un lapso no mayor a 72 horas en días
                      hábiles de L-V de 09:00 am a 18:00 pm
                    </div>
                    <div className="pt-2">
                    <button
                          className="btn btn-primary col-md-6 col-10 mt-2"
                          onClick={toggleDrawer("right", false)}
                          style={{ height: "3.5em", fontSize: "14px" }}
                        >
                          Entendido
                        </button>

                    </div>

                  </div>
                </div>
              </div>
            )}
          </Drawer>
        </React.Fragment>
      </div>
    </div>
  );
};

export default NeedHelpNoLogin;
