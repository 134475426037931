import React, {useEffect} from "react";
import "./Welcome.scss";
import HeaderOut from "../HeaderOut/HeaderOut";
import Footer from "../Footer/Footer";
import { Link, useLocation, useHistory } from "react-router-dom";

const Welcome = () => { 
  const history = useHistory();
  var qs = require('qs')
  const location = useLocation()

  useEffect(()=> {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
    // console.log(queryParams.token);
    // console.log(queryParams.mutoken);
    // console.log(queryParams.muid);
    if (queryParams.token ) {

        let userData = parseJwt(queryParams.token)
        console.log('user data', userData)

        localStorage.setItem('token', queryParams.token);
        localStorage.setItem('token_masterup', queryParams.mutoken);
        localStorage.setItem('id_masterup', queryParams.muid);
        localStorage.setItem('user', userData.email);

        if (localStorage.getItem('walkthrough') !== null) {

          setTimeout(() => {
            history.push('/home-page')
          }, 150)

        } else {

          setTimeout(() => {
            history.push('/walkthrough')
          }, 150)

        }

        
    }

      if (localStorage.getItem("token") !== null) {
        history.push("/home-page");
      }
    
  })


  function parseJwt(token: any) {
    const base64Url = token.split('.')[1]
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )

    return JSON.parse(jsonPayload)
  }
  
  return(
  <div className="Welcome" data-testid="Welcome">
    {/* Welcome Component */}

    <div className="static-container container-fluid">
      <HeaderOut />
      <div className="container-fluid sub-menu"></div>
    </div>

    <div className="container-fluid main-container  ">
      <div className="container sub-container  ">
        <div className="row" style={{ height: "auto" }}>
          <div
            className="col-md-6 d-flex justify-content-center align-items-center"
            style={{ height: "510px" }}
          >
            <div className=" col-10  col-md-10">
              <div className="col-12 ">
                <div className="red-line" />
              </div>
              <h1 className="pt-4">Centro de Medios de Pago</h1>
              <p className="subtitle">
                Un portal hecho para apoyarte a cumplir tus metas y objetivos,
                todo lo que necesitas a un solo click de distancia.
              </p>
            </div>
          </div>
          <div className="col-md-6 order-first  p-4 mt-4" style={{ height: "510px" }}>
            <div className="col-12 mt-4  d-flex justify-content-center align-items-center ">
              {/* <div className="red-line" /> */}
              <div className="col-12 col-md-7  btns-container d-flex justify-content-center align-items-center">
                <div className="text-center">
                  <div className="title-rect">Ingresa a la plataforma</div>{" "}
                  <div className="sub-title-rect d-flex justify-content-center ">
                    <div className="col-8 pt-4 ">
                      Ingresa para encontrar todo lo que necesitas en tu día a
                      día
                    </div>
                  </div>
                  <div className="pt-4">
                    <Link to="/login">
                      <button
                        className="btn btn-primary col-md-8 col-12 mt-2"
                        style={{ fontSize: "15px" }}
                      >
                        Iniciar sesión
                      </button>
                    </Link>
                  </div>
                  <div className="pt-2">
                    <Link to="/singup">
                      <button
                        className="btn btn-secondary col-md-8 col-12 mt-2"
                        style={{ fontSize: "15px" }}
                      >
                        Regístrate
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-foot">
        <div className="container">
          <div className="d-flex justify-content-center align-items-center  p-4">
            {/* <div className="col-12 col-md-8  p-4">
              <h3> ¿Sabías que?</h3>
              <h1>Importancia de un cliente vinculado</h1>
              <p className="bottom-container">
                Un cliente vinculado es casi un 30% más rentable que uno no
                vinculado, y un cliente digital es casi 10% más rentable que un
                cliente activo.
              </p>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
)};

export default Welcome;
