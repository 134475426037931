import React, { useEffect } from "react";
import "./PasswordRecovery.scss";

import HeaderOut from "../HeaderOut/HeaderOut";
import Footer from "../Footer/Footer";
import CloseIcon from "@material-ui/icons/Close";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { requestResetPassword } from "../../api/";

import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CircularProgress from "@material-ui/core/CircularProgress";

import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

type Severity = 'error' | 'success' | 'info' | 'warning' | undefined
interface AlertData {
  type: Severity
  label: string
  show: boolean
}

const PasswordRecovery = () => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });
  const goWelcome = () => {
    history.push("/");
  };

  const [openAlert, setOpenAlert] = React.useState<AlertData>({
    type: undefined,
    show: false,
    label: "",
  });

  const [showLoading, setShowLoading] = React.useState(false);
  const [showPass, setShowPass] = React.useState("password");

  useEffect(() => {
    console.log("token", localStorage.getItem("token"));
    if (localStorage.getItem("token") !== null) {
      history.push("/home-page");
    }
  }, []);

  const onSubmit = async (data:any /*{
    emailReset: string;
    emailRepeat: string;
  }*/) => {
    console.log(data.emailReset, data.emailRepeat);
    setShowLoading(true);

    try {
      const reqReset = {
        email: data.emailRepeat,
      };

      const setResetPassword = await requestResetPassword(reqReset);
      if (setResetPassword.status === 201) {
        setShowLoading(false);
        setOpenAlert({ type: 'success', show: true, label: 'Se ha enviado un correo electronico a '+watch("emailReset")+', para que puedas reestablecer tu cuenta' });
        setShowLoading(false);
      }
      console.log('result', setResetPassword);

    } catch (error: any) {
      console.log(error.response.data.message);
      setOpenAlert({ type: 'error', show: true, label: error.response.data.message });
      setShowLoading(false);
    }
  };
  const showPassword = () => {
    if (showPass === "password") {
      setShowPass("text");
    } else {
      setShowPass("password");
    }
  };

  return (
    <div className="PasswordRecovery" data-testid="PasswordRecovery">
      {/* PasswordRecovery Component */}
      <div className="static-container container-fluid">
        <HeaderOut />
        <div className="container-fluid sub-menu"></div>
      </div>

      <div className="container-fluid main-container   ">
        <div className="container sub-container  d-flex align-items-stretch ">
          <div className=" col-12 d-flex justify-content-center  ">
            <div className=" login-container">
              <div className="col-12 d-flex d-flex justify-content-end pb-2 px-3 pt-3">
                <CloseIcon style={{ cursor: "pointer" }} onClick={goWelcome} />
              </div>
              <div
                className="col-12 d-flex justify-content-center align-items-center p-3"
                style={{ marginTop: "-10px" }}
              >
                <div className=" col-12 col-md-9 text-left">
                  <h1 className="h1-reset-psw">Reestablecer constraseña </h1>
                  <div className="sub-text">
                    Ingresa tu correo para reestablecer contraseña
                  </div>
                  <div className="form-container pt-4">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <div className="row pb-2">
                          <label className="col-6 col-md-7 label-form">
                            Correo electrónico
                          </label>
                          {/* <label className="col-6 col-md-5 label-alt d-flex align-items-end">
                            ¿Cómo identifico mi número de empleado?
                          </label> */}
                        </div>
                        <input
                          {...register("emailReset", {
                            required: true,
                            pattern: {
                              value:
                                /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                              message: "pattern message",
                            },
                          })}
                          type="email"
                          className="form-control input"
                          id="emailReset"
                          placeholder="usuario@mail.com"
                        />
                        {String(errors.emailReset?.type) === "pattern" && (
                          <span className="input-error">
                            Correo electrónico no válido
                          </span>
                        )}
                      </div>
                      <div className="form-group pt-4">
                        <div className="row pb-2">
                          <label className="col-6  col-md-8 label-form">
                            Confirmar correo electrónico
                          </label>
                          {/* <label className="col-8 col-md-6 label-alt pt-2">
                            ¿Olvidaste tu contraseña?
                          </label> */}
                        </div>
                        <input
                          {...register("emailRepeat", {
                            required: true,
                            pattern: {
                              value:
                                /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                              message: "pattern message",
                            },
                            validate: {
                              sameText: (value) =>
                                value === getValues().emailReset || "error",
                            },
                          })}
                          type="email"
                          className="form-control input"
                          id="emailRepeat"
                          placeholder="usuario@mail.com"
                        />
                        <div className="pt-1" style={{margin: 0}}>
                          <div >
                            {String(errors.emailRepeat?.type) === "pattern" && (
                              <span className="input-error">
                                Correo electrónico no válido
                              </span>
                            )}
                          </div>

                          {/* {errors.emailRepeat.type === "validate" && <p>Have to be the same</p>} */}
                          <div style={{marginTop: '-6px'}}>
                            {errors.emailRepeat && (
                              <span className="input-error">
                                Los correos deben de ser coincidir
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt-4 pt-1">
                        <button
                          className="btn btn-primary col-md-6 col-10 mt-2"
                          disabled={!isValid}
                          type="submit"
                          style={{ height: "3.5em", fontSize: "14px" }}
                        >
                          Enviar
                        </button>
                      </div>
                      {showLoading && (
                        <div className="pt-3 col-12 d-flex justify-content-center">
                          <CircularProgress color="secondary" size={20} />
                        </div>
                      )}
                      <div className="pt-3">
                        <Collapse in={openAlert.show}>
                          <Alert
                            severity={openAlert.type}
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setOpenAlert({type: openAlert.type,  show: false, label: openAlert.label });
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {openAlert.label}
                          </Alert>
                        </Collapse>
                      </div>
                      <div className="text-center mt-4">
                        <span className="text-tyc mt-2">
                          Revisa <u>Terminos y Condiciones.</u>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <Footer />
      </div>
    </div>
  );
};

export default PasswordRecovery;
